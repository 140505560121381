import { Component, OnInit } from '@angular/core';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'app-speech-event-bubble',
  templateUrl: './speech-event-bubble.component.html',
  styleUrls: ['./speech-event-bubble.component.scss']
})
export class SpeechEventBubbleComponent implements OnInit {

  constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

}
