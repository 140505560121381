<img class="card-img-top img-fluid w-100" src="assets/img/thea/IMG_9561.jpg" alt="Card image cap">

<ng-container *ngIf="loaded|async">

  <div class="blaue_box text-white p-3 p-lg-4 flex-grow-1">
    <div class="container content">

      <h1>Fragen und Antworten</h1>

      <ngb-accordion [closeOthers]="true" [activeIds]="activeIDs|async" (panelChange)="setPanelStates($event)">

        <ngb-panel *ngFor="let item of faqEntries|async" [id]="item.index">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
              <div  class="d-flex" [innerHTML]="item.entry.question"></div>
              <div *ngIf="panelStates.get(item.index)" class="d-flex" style="font-size: 150%; text-decoration: none"><i class="fas fa-angle-down"></i></div>
              <div *ngIf="!panelStates.get(item.index)" class="d-flex" style="font-size: 150%; text-decoration: none"><i class="fas fa-angle-right"></i></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div [innerHTML]="item.entry.answer"></div>
          </ng-template>
        </ngb-panel>

      </ngb-accordion>
    </div>
  </div>

</ng-container>
