import {Pipe, PipeTransform} from '@angular/core';
import {KeyValue} from '@angular/common';
import {Praxis, PraxisSelection} from '../services/app.service';

@Pipe({
  name: 'praxisSelection'
})
export class PraxisSelectionPipe implements PipeTransform {

  transform(value: Array<KeyValue<PraxisSelection, Praxis>>, only: PraxisSelection = null): Array<Praxis> {

    if (only != null) {
      return value.filter(x =>
        x.key === only
      ).map(x => x.value);
    }

    return value.filter(x =>
      x.key !== PraxisSelection.Undefined &&
      x.key !== PraxisSelection.Impressum
    ).map(x => x.value);
  }

}
