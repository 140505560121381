import { Component, OnInit } from '@angular/core';
import {AppService} from '../../../services/app.service';

@Component({
  selector: 'app-start',
  templateUrl: './manuelleLymphdrainage.component.html'
})
export class ManuelleLymphdrainageComponent implements OnInit {

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }

}
