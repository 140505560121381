import {Component, OnInit} from '@angular/core';
import {NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {AppService, PraxisSelection, sortByPraxis} from '../../services/app.service';
import {FaqService} from '../../services/faq.service';
import {TeamMember} from '../../models/team.member';
import {BehaviorSubject} from 'rxjs';
import {FaqEntry} from '../../models/faq-entry';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public panelStates = new Map<string, boolean>();
  public faqEntries = new BehaviorSubject<{ entry: FaqEntry, index: string }[]>([]);
  public loaded = new BehaviorSubject<boolean>(false);

  public activeIDs = new BehaviorSubject<string>(null);

  constructor(public appService: AppService, public faqService: FaqService) {
  }

  ngOnInit(): void {


    this.appService.currentPraxis.subscribe(x => {

      this.loaded.next(false);
      const entries = this.faqService.loadFaqEntries(x);
      this.panelStates = new Map<string, boolean>();

      const elementsWithIndex = entries.map(entry => {

        const index = `static-${entry.pageIndex.find(c => c.praxis === x).sortIndex}`;
        this.panelStates.set(index, false);

        return {
          entry,
          index
        };
      });

      this.activeIDs.next(elementsWithIndex[0].index);
      this.panelStates.set(this.activeIDs.value, true);

      this.faqEntries.next(elementsWithIndex);
      this.loaded.next(true);
      this.appService.scrollUp();
    });


    // this.panelStates.set('static-1', true);
    // this.panelStates.set('static-2', false);
    // this.panelStates.set('static-3', false);
    // this.panelStates.set('static-4', false);
    // this.panelStates.set('static-5', false);
    // this.panelStates.set('static-6', false);
    // this.panelStates.set('static-7', false);
    // this.panelStates.set('static-8', false);
    // this.panelStates.set('static-9', false);
    // this.panelStates.set('static-10', false);
    //
    // this.appService.scrollUp();
  }

  setPanelStates($event: NgbPanelChangeEvent): void {
    for (const [key, value] of this.panelStates.entries()) {
      this.panelStates.set(key, false);
    }
    this.panelStates.set($event.panelId, $event.nextState);

    this.activeIDs.next($event.panelId);
  }

}
