import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-covid-hint',
  templateUrl: './covid-hint.component.html',
  styleUrls: ['./covid-hint.component.scss']
})
export class CovidHintComponent implements OnInit {


  ngOnInit(): void {
  }


}
