import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-youtube-wrapper',
  templateUrl: './youtube-wrapper.component.html',
  styleUrls: ['./youtube-wrapper.component.scss']
})
export class YoutubeWrapperComponent implements OnInit {

  @Input()
  public yLink: string;

  public vidAccess = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit(): void {
  }

}
