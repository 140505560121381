<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">

    <div class="row">
      <div class="col-md-6 inline left d-flex flex-column justify-content-center">
        <img class="card-img-top img-fluid" src="assets/img/thea/m_pic_1.jpg" alt="Card image cap">
      </div>

      <div class="col-md-6 inline">

        <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
        <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
          Manuelle Therapie
        </h2>

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist Manuelle Therapie?</h4>
        <p class="text-grey mb-3">Die manuelle Therapie dient der Behandlung von Funktionsstörungen des Bewegungssystems (Gelenke, Muskeln und Nerven).<br>
          Diese Therapieform ist die wohl bekannteste. Die „manuelle“ Arbeit am Patienten steht hierbei im Vordergrund. Dies bedeutet, dass der Physiotherapeut mit seinen Händen arbeitet. Durch verschiedene Grifftechniken stellt er die Ursache von Störungen fest und kann Sie so gezielt behandeln. Für Manuelle Therapie sollten Sie beim ersten Termin rund 30 und für die Folgetermine rund 25 Minuten einplanen.
         </p>

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was kann Manuelle Therapie bewirken?</h4>
        <p class="text-grey ">Da oft die schmerzende Stelle nicht die eigentliche Ursache ist, werden Zusammenhänge des Nervensystems berücksichtigt und entsprechend behandelt. Ziele der Manuellen Therapie sind, je nach Diagnose, die Wiederherstellung von Beweglichkeit und Mobilität, Schmerzlinderung, sowie die Verbesserung von Funktionsstörungen des Bewegungssystems.
          <br><br>Bei der Manuellen Therapie kann auch das Kiefergelenk behandelt werden. Bei Fragen hierzu, können Sie sich gerne an uns wenden.</p>

      </div>
    </div>

  </div>
</div>




