<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
    <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
      Mitgliedschaft
    </h2>
    <div class="row">
      <div class="col-md-12">
        <p class="text-grey mb-3">
          In unserem vielseitig ausgestatteten Trainingsraum bieten wir Ihnen verschiedene Trainingsmöglichkeiten an.<br><br>
          Gerne können Sie bei uns eine Trainingsmitgliedschaft abschließen.<br><br>
          Diese beinhaltet eine Einweisung in unsere Trainingsgeräte und die Erstellung eines individuellen Trainingsplans, der jederzeit, den sich ändernden Bedürfnissen und Leistungsentwicklungen, angepasst werden kann. Als Mitglied dürfen Sie täglich zwischen 08:00 Uhr und 18:00 Uhr frei trainieren. Bei Fragen zu Geräten oder Übungsausführungen steht Ihnen jederzeit eine qualifizierte Fachkraft zur Verfügung.<br><br>
          Die Preise der Mitgliedschaften variieren, gerne beraten wir Sie Ihren Bedürfnissen entsprechend.
        </p>
      </div>
    </div>

  </div>
</div>





