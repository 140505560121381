import {Component, Input, OnInit} from '@angular/core';
import {Phone} from '../../services/app.service';

@Component({
  selector: 'app-whatsapp, whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {

  @Input()
  public phone: Phone;

  @Input()
  public bigSymbols = false;

  @Input()
  public showWhatsapp = false;

  @Input()
  public color: string;

  constructor() { }

  ngOnInit(): void {
  }

}
