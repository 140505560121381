<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="col-md-6 inline left">
      <img class="card-img-top img-fluid" src="assets/img/thea/taping.jpg" alt="Card image cap">
    </div>
    <div class="col-md-6 inline">
      <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
      <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
        Was ist Taping?
      </h2>
      <h4 class="font-weight-bold text-white text-uppercase mb-1">
        Meditaping
      </h4>
      <p class="text-grey mb-3">
        Beim Taping wird ein feuchtigkeitsdurchlässiges und flexibles Band auf die Haut geklebt. Das Tape kann an verschiedenen Körperstellen verwendet werden und bietet durch verschiedene Arten der Anbringung zahlreiche Möglichkeiten Beschwerden zu lindern. Das Tape hält sich mehrere Tage auf der Haut und kann somit auch beim Sport und unter der Dusche getragen werden.
        Eingesetzt wird Taping zum Beispiel bei muskulären Beschwerden, Sprunggelenksverletzungen, Rücken- und Schulterschmerzen, zur Behandlung von Narben oder zur Verbesserung des Lymphabflusses.
      </p>
      <h4 class="font-weight-bold text-white text-uppercase mb-1 mt-2">
        Was kann Taping bewirken?
      </h4>
      <p class="text-grey mb-3"> Mit dem speziellen elastischen Tape ist es möglich Schmerzen und Einschränkungen der Beweglichkeit sofort erfolgreich zu behandeln. Die Anwendung des Tapes kann man sich vereinfacht so vorstellen: Die Oberhaut wird durch das Tape angehoben, dadurch entsteht mehr Raum in der Unterhaut für die Blut- und Lymphgefäße. Ebenso werden spezielle Rezeptoren, die sich positiv auf die Grundspannung des Muskels auswirken, angeregt. Dabei werden körpereigene Heilungsprozesse unterstützt, indem das Nerven- und Herz/Kreislaufsystem des Menschen beeinflusst wird.
        <br><br>
        Auch zur Schmerzlinderung wird Taping gerne verwendet. Das klebende Tape aktiviert, über einen mechanischen Reiz, weitere spezielle Rezeptoren, welche Informationen aus dem betroffenen Gewebe „senden“. Diese sind stärker und schneller und überlagern den Schmerzreiz. Es kommt zu einer Schmerzlinderung.
      </p>
    </div>
  </div>
</div>
