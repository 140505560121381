<div
  class=" blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="row">

      <div class="col-md-12 inline d-flex flex-column justify-content-center">

        <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">

        <h4 class="font-weight-bold text-white text-uppercase mb-3">
          Was ist Bobath und PNF?
        </h4>
        <p class="text-grey mb-5">

          Beide Therapieformen werden bei Schädigungen des Nervensystems angewandt.
          Das Behandlungskonzept nach Bobath wurde 1943 von dem Ehepaar Bobath entwickelt und wird seitdem weltweit von Physiotherapeuten praktiziert. Im Fokus steht bei diesem Konzept das Wiedererlernen normaler Bewegungsabläufe.
          PNF steht wiederum für „Propriozeptive Neuromuskuläre Fazilitation“ und verfolgt einen anderen Ansatz als das Bobath Konzept. Bei dieser Therapieform soll die Zusammenarbeit von Rezeptoren, die wahrnehmen wie sich der eigene Körper bewegt, mit Muskeln und Nerven verbessert werden.
          Was beide gemeinsam haben ist, dass sie die natürlich vorhandenen Mechanismen des Nervensystems nutzen. So können sie bei Funktionsstörungen, wie Spastischen Lähmungen, Krankheitsbildern wie Parkinson oder auch nach einem Schlaganfall helfen.

        </p>
        <h4 class="font-weight-bold text-white text-uppercase mb-3">
          Was können Bobath und PNF bewirken?
        </h4>
        <p class="text-grey mb-3">

          Das Ziel der Behandlungen ist die Steigerung der Muskelkraft, die Verbesserung der Fein- und Grobmotorik, Haltung und Koordination. Zudem arbeiten Patient und Therapeut daran, Bewegungsabläufe wieder neu zu erlernen und diese zu festigen.
          Somit sollen Folgeschäden möglichst geringgehalten oder ganz verhindert werden.

        </p>


      </div>
    </div>

  </div>
</div>


