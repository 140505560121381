<div
  [class.blaue_box]="isDark"
  [class.bg-inverse]="isDark"
  [class.text-white]="isDark"
  class="p-3 p-lg-4 my-2"
>
  <div class="container shadow" style="margin-bottom: 40px;">
    <div class="row my-2">
      <div class="col-12 col-md-6 d-flex justify-content-end ">
        <div class="d-flex align-items-center">
          <ngb-carousel class="main-slider">
            <ng-template ngbSlide *ngFor="let img of member.images">
              <div class="">
                <img [src]="img.src" [alt]="img.alt">
              </div>
              <div class="carousel-caption">
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex flex-column  py-2">
        <p>
          <span class="member-title">{{member.name}}</span><br>
          {{member.graduation}}
        </p>
        <ng-container *ngIf="member.training">
          <div>
            <span>Fortbildung / Zertifikate</span>
            <ul>
              <li *ngFor="let item of member.training">{{item}}</li>
            </ul>
          </div>
        </ng-container>
        <p *ngIf="member.special">
          <strong>Spezialgebiet:</strong><br>
          {{member.special}}
        </p>
        <div *ngIf="member.description" [innerHTML]="member.description">
        </div>
      </div>
    </div>
  </div>
</div>
