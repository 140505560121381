<div id="scrollAnchor"></div>

<app-navigation></app-navigation>

<!-- ======== @Region: #content ======== -->
<div id="content">

  <router-outlet></router-outlet>

  <app-footer></app-footer>
</div>

