import {Injectable} from '@angular/core';
import {NavigationSection} from '../models/navigation-section';
import {PraxisSelection, sortByPraxis} from './app.service';


const navDB: NavigationSection[] = [
  {
    name: 'Start',
    route: 'start',
    pageIndex: [
      {
        sortIndex: 1,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 1,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 1,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 1,
        praxis: PraxisSelection.Knielingen
      },
    ],
    entries: []
  },
  {
    name: 'Kurasan',
    pageIndex: [
      {
        sortIndex: 2,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 2,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 2,
        praxis: PraxisSelection.Waldstadt
      },
    ],
    entries: [
      {
        name: 'Unser Team',
        route: 'team',
        pageIndex: [
          {
            sortIndex: 1,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Waldstadt
          },
        ],
      },
      {
        name: 'Galerie',
        route: 'galerie',
        pageIndex: [
          {
            sortIndex: 2,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 2,
            praxis: PraxisSelection.Kurasan2
          },
        ],
      },
      {
        name: 'Physios für Hausbesuche gesucht',
        route: 'stellenausschreibung',
        pageIndex: [
          {
            sortIndex: 3,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 3,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 3,
            praxis: PraxisSelection.Waldstadt
          },
        ],
      },
      // {
      //   name: 'Logopäd:in gesucht',
      //   route: 'stellenausschreibung-waldstadt',
      //   pageIndex: [
      //     {
      //       sortIndex: 3,
      //       praxis: PraxisSelection.Kurasan1
      //     },
      //     {
      //       sortIndex: 3,
      //       praxis: PraxisSelection.Kurasan2
      //     },
      //     {
      //       sortIndex: 3,
      //       praxis: PraxisSelection.Waldstadt
      //     },
      //   ],
      // },
      {
        name: 'Physiotherapeut*in gesucht',
        route: 'stellenausschreibung-physio',
        pageIndex: [
          {
            sortIndex: 4,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 4,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 4,
            praxis: PraxisSelection.Waldstadt
          },
        ],
      },
    ]
  },
  {
    name: 'Therapiespektrum ',
    pageIndex: [
      {
        sortIndex: 3,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 3,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 3,
        praxis: PraxisSelection.Knielingen
      },
    ],
    entries: [
      {
        name: 'Physiotherapie',
        route: 'physiotherapie',
        pageIndex: [
          {
            sortIndex: 1,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Waldstadt
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Knielingen
          }
        ],
      },
      {
        name: 'Manuelle Therapie',
        route: 'manuelle-therapie',
        pageIndex: [
          {
            sortIndex: 2,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 2,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 2,
            praxis: PraxisSelection.Waldstadt
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Knielingen
          }
        ],
      },
      {
        name: 'Krankengymnastik am Gerät (KGG)',
        route: 'kgg',
        pageIndex: [
          {
            sortIndex: 3,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 3,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 3,
            praxis: PraxisSelection.Waldstadt
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Knielingen
          }
        ],
      },
      // {
      //   name: 'Physikalische Therapie',
      //   route: 'physikalische-therapie',
      //   pageIndex: [
      //     {
      //       sortIndex: 4,
      //       praxis: PraxisSelection.Kurasan1
      //     },
      //     {
      //       sortIndex: 4,
      //       praxis: PraxisSelection.Kurasan2
      //     },
      //     {
      //       sortIndex: 4,
      //       praxis: PraxisSelection.Waldstadt
      //     },
      //     {
      //       sortIndex: 1,
      //       praxis: PraxisSelection.Knielingen
      //     }
      //   ],
      // },
      {
        name: 'Manuelle Lymphdrainage',
        route: 'manuelle-lymphdrainage',
        pageIndex: [
          {
            sortIndex: 5,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 5,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 5,
            praxis: PraxisSelection.Waldstadt
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Knielingen
          }
        ],
      },
      // {
      //   name: 'Selbstzahlerleistungen',
      //   route: 'selbstzahlerleistungen',
      //   pageIndex: [
      //     {
      //       sortIndex: 6,
      //       praxis: PraxisSelection.Kurasan1
      //     },
      //     {
      //       sortIndex: 6,
      //       praxis: PraxisSelection.Kurasan2
      //     }
      //   ],
      // },
      {
        name: 'Physikalische Therapie',
        route: 'physikalische-therapie',
        pageIndex: [
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Waldstadt
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Knielingen
          }
        ],
      },
      {
        name: 'Craniomandibuläre Dysfunktion (CMD)',
        route: 'cmd',
        pageIndex: [
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Waldstadt
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Knielingen
          }
        ],
      },
      // {
      //   name: 'Logopädie',
      //   route: 'logopaedie',
      //   pageIndex: [
      //     {
      //       sortIndex: 8,
      //       praxis: PraxisSelection.Waldstadt
      //     }
      //   ],
      // },
      {
        name: 'Ergotherapie',
        route: 'ergotherapie',
        pageIndex: [
          {
            sortIndex: 9,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },
    ]
  },
  {
    name: 'Physiotherapie',
    pageIndex: [
      {
        sortIndex: 3,
        praxis: PraxisSelection.Waldstadt
      }
    ],
    entries: [
      {
        name: 'Krankengymnastik',
        route: 'physiotherapie',
        pageIndex: [
          {
            sortIndex: 1,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 1,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },
      {
        name: 'Manuelle Therapie',
        route: 'manuelle-therapie',
        pageIndex: [
          {
            sortIndex: 2,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 2,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 2,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },
      {
        name: 'Krankengymnastik am Gerät (KGG)',
        route: 'kgg',
        pageIndex: [
          {
            sortIndex: 3,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 3,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 3,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },
      {
        name: 'Physikalische Therapie',
        route: 'physikalische-therapie',
        pageIndex: [
          {
            sortIndex: 4,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 4,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 4,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },
      {
        name: 'Manuelle Lymphdrainage',
        route: 'manuelle-lymphdrainage',
        pageIndex: [
          {
            sortIndex: 5,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 5,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 5,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },
      // {
      //   name: 'Selbstzahlerleistungen',
      //   route: 'selbstzahlerleistungen',
      //   pageIndex: [
      //     {
      //       sortIndex: 6,
      //       praxis: PraxisSelection.Kurasan1
      //     },
      //     {
      //       sortIndex: 6,
      //       praxis: PraxisSelection.Kurasan2
      //     }
      //   ],
      // },
      {
        name: 'Physikalische Therapie',
        route: 'physikalische-therapie',
        pageIndex: [
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },
      {
        name: 'Craniomandibuläre Dysfunktion (CMD)',
        route: 'cmd',
        pageIndex: [
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan1
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Kurasan2
          },
          {
            sortIndex: 7,
            praxis: PraxisSelection.Waldstadt
          }
        ],
      },

      {
        name: 'BGF',
        route: 'betriebliche-gesundheitsfoerderung',
        pageIndex: [
          {
            sortIndex: 8,
            praxis: PraxisSelection.Waldstadt
          },
        ],
      },
    ]
  },
  // {
  //   name: 'Logopädie',
  //   route: 'logopaedie',
  //   pageIndex: [
  //     {
  //       sortIndex: 4,
  //       praxis: PraxisSelection.Waldstadt
  //     }
  //   ],
  //   entries: []
  // },
  {
    name: 'Ergotherapie',
    route: 'ergotherapie',
    pageIndex: [
      {
        sortIndex: 5,
        praxis: PraxisSelection.Waldstadt
      }
    ],
    entries: []
  },
  {
    name: 'BGF',
    route: 'betriebliche-gesundheitsfoerderung',
    pageIndex: [
      {
        sortIndex: 4,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 4,
        praxis: PraxisSelection.Kurasan2
      },
    ],
    entries: []
  },
  {
    name: 'FAQ',
    route: 'faq',
    pageIndex: [
      {
        sortIndex: 5,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 5,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 5,
        praxis: PraxisSelection.Knielingen
      },
      {
        sortIndex: 5,
        praxis: PraxisSelection.Waldstadt
      },
    ],
    entries: []
  },
  {
    name: 'Kontakt',
    route: 'kontakt',
    pageIndex: [
      {
        sortIndex: 6,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 6,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 6,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 6,
        praxis: PraxisSelection.Knielingen
      }
    ],
    entries: []
  },
  {
    name: 'Termin',
    route: 'termin',
    pageIndex: [
      {
        sortIndex: 7,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 7,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 7,
        praxis: PraxisSelection.Knielingen
      },
    ],
    entries: []
  },
  {
    name: 'Partner',
    route: 'partner',
    pageIndex: [
      {
        sortIndex: 8,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 8,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 6,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 6,
        praxis: PraxisSelection.Knielingen
      }
    ],
    entries: []
  },
];

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() {
  }

  public loadNavEntries(selection: PraxisSelection): NavigationSection[] {
    return navDB
      .filter(x => x.pageIndex.find(c => c.praxis === selection) != null)
      .map(x => {
        return {
          name: x.name,
          route: x.route,
          pageIndex: x.pageIndex,
          entries: x.entries.filter(b => b.pageIndex.find(c => c.praxis === selection) != null)
        } as NavigationSection;
      })
      .sort((x, y) => sortByPraxis(x, y, selection));
  }
}
