import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService, PraxisSelection, defaultPraxis} from '../../services/app.service';
import {WhatWeOfferService, Wwo} from '../../services/what-we-offer.service';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy {

  defaultPraxis = defaultPraxis;

  private subs: Subscription[] = [];
  public wwoParts = new BehaviorSubject<Wwo[]>([]);

  constructor(public appService: AppService, private wwoService: WhatWeOfferService) {
  }


  getPrinzipielBG(): string {
    if (this.appService.currentPraxis.value === PraxisSelection.Kurasan1) {
      return 'bg-prinzipiel-kurasan-1';
    }
    if (this.appService.currentPraxis.value === PraxisSelection.Kurasan2) {
      return 'bg-prinzipiel-kurasan-2';
    }


    return 'bg-prinzipiel-kurasan-1';
  }


  ngOnInit(): void {
    this.appService.scrollUp();

    this.subs.push(
      this.appService.currentPraxis.subscribe(x => {
        this.wwoParts.next(this.wwoService.loadNavEntries(x));
      })
    );

  }

  ngOnDestroy(): void {
    this.subs?.forEach(x => x?.unsubscribe());
  }
}
