<div class="container">
  <div class="d-flex justify-content-center ">
    <div>
      <img class="card-img-top img-fluid" style="width: 600px;" src="assets/img/new-team/Team_Waldstadt.jpg" alt="Card image cap">
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="text-center blaue_box p-2" style="width: 500px; font-size: 1.3rem; margin-top: -50px">
      Logopäd:in gesucht!
    </div>
  </div>


  <div class="d-flex flex-column align-items-center mt-3">
    <div class="my-2 border rounded p-2">
      <a href="assets/download/Stellenanzeige Kurasan Waldstadt.pdf" target="_blank"><i class="mr-2 fas fa-file-pdf text-primary"></i>Flyer herunterladen</a>
    </div>

    <div class="d-flex flex-column" style="max-width: 800px">
      <strong>Wer wir sind</strong>
      <p>
        Wir sind eine Praxis für Physio- und Manuelle Therapie, sowie für Bewegungstherapie und <br>
        Gesundheitsmanagement. Unsere Praxis in der Waldstadt haben wir dieses Jahr im Juli neu eröffnet. <br>
        Neben der Ergotherapie bieten wir hier auch logopädische Behandlungen an. Unser junges Team <br>
        besteht aus vier Physiotherapeuten, einer Ergotherapeutin und einer Logopädin.
      </p>
      <p>
        Zur Erweiterung unseres Teams suchen wir ab Februar 2022
      </p>
      <p>
        <strong>eine:n Logopäd:in (m/w/d) </strong>
      </p>
      <p>
        in Teilzeit (Vollzeit später möglich).
      </p>
    </div>
    <div class="d-flex flex-column" style="max-width: 800px">
      <strong class="">Wir wünschen uns:</strong>
      <ul>
        <li>
          <span>Abgeschlossene Berufsausbildung </span>
        </li>
        <li>
          <span>Eine eigenständige, patientenorientierte und strukturierte Arbeitsweise</span>
        </li>
        <li>
          <span>Teamfähigkeit und soziale Kompetenz</span>
        </li>
        <li>
          <span>Offenheit für Veränderungen und Entwicklungen</span>
        </li>
        <li>
          <span>Kooperative Zusammenarbeit mit anderen Berufsgruppen</span>
        </li>
        <li>
          <span>Bereitschaft zur Durchführung von Hausbesuchen</span>
        </li>
        <li>
          <span>Idealerweise verfügen Sie bereits über Berufserfahrung </span>
        </li>
      </ul>
      <strong class="">Wir bieten Ihnen:</strong>
      <ul>
        <li>
          <span>Herzliches, humorvolles Team</span>
        </li>
        <li>
          <span>Interdisziplinäre Zusammenarbeit</span>
        </li>
        <li>
          <span>Mitgestaltungsmöglichkeiten</span>
        </li>
        <li>
          <span>Eigener Therapieraum in moderner Praxis</span>
        </li>
        <li>
          <span>Rezeptionskraft vorhanden</span>
        </li>
        <li>
          <span>Flexible Arbeitszeiten und ein familienfreundliches Arbeitsklima</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <p class="text-center primary-color font-weight-bold">
      Wenn wir Ihr Interesse geweckt haben, dann melden Sie sich gerne unter,
      <phone [phone]="defaultPraxis.phone"></phone>
      <br>
      oder per Mail an
      <email [email]="defaultPraxis.email"></email>
      .<br>
    </p>
  </div>

</div>
