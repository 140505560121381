import {Component, OnInit} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {AppService} from '../../services/app.service';
import {TeamService} from '../../services/team.service';
import {BehaviorSubject} from 'rxjs';
import {TeamMember} from '../../models/team.member';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  public loaded = new BehaviorSubject<boolean>(false);
  public members = new BehaviorSubject<TeamMember[]>([]);

  constructor(config: NgbCarouselConfig, public appService: AppService, private teamService: TeamService) {
    // customize default values of carousels used by this component tree
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = true;
  }


  ngOnInit(): void {

    this.appService.currentPraxis.subscribe(x => {
      this.members.next(this.teamService.loadTeamMember(x));
      this.loaded.next(true);
      this.appService.scrollUp();
    });

  }

}
