import {Injectable} from '@angular/core';
import {FaqEntry} from '../models/faq-entry';
import {PraxisSelection, sortByPraxis} from './app.service';


const faqDB: Array<FaqEntry> = [
  {
    question: 'Was muss ich zu meinem Termin mitbringen?',
    answer: 'Bringen Sie zu Ihrem Termin bitte Ihr Rezept mit. Dieses sollte nicht älter als 28 Tage sein. Auch OP-Berichte, Röntgen-, CT- oder MRT-Aufnahmen können gerne mitgebracht werden. Denken Sie außerdem an ausreichend Zeit, falls Sie weitere Termine ausmachen möchten.',
    pageIndex: [
      {
        sortIndex: 1,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 1,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 1,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Brauche ich ein Rezept?',
    answer: 'Nein, Sie können gerne auch ohne Rezept Termine bei uns vereinbaren. Diese sind dann allerdings privat zu bezahlen.',
    pageIndex: [
      {
        sortIndex: 2,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 2,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 2,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Muss ich etwas zu meinem Rezept dazuzahlen?',
    answer: 'Ja, gesetzlich Versicherte müssen die sogenannte „Rezeptgebühr“ zahlen. Diese bemisst sich am Wert des jeweiligen Rezeptes. Es sind immer 10€  plus 10% des Rezeptwertes. Meistens beträgt die Rezeptgebühr zwischen 20 und 30 Euro, je nachdem was Ihnen vom Arzt verordnet wurde.',
    pageIndex: [
      {
        sortIndex: 3,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 3,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 3,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Kann ich auch mit EC-Karte bezahlen?',
    answer: 'Ja, es ist bei uns auch möglich mit EC-Karte zu zahlen.',
    pageIndex: [
      {
        sortIndex: 4,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 4,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 4,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Wie lange dauert ein Termin?',
    answer: 'Das hängt davon ab, was Sie verschrieben bekommen haben. Krankengymnastik (KG) dauert 20 Minuten, Manuelle Therapie (MT) 25 Minuten, Klassische Massage Therapie (KMT) dauert 15 Minuten. Für Krankengymnastik am Gerät (KGG) müssen Sie 40 Minuten einplanen. Bei Manueller Lymphtherapie (MLT) wird zwischen MLT mit 30 Minuten, MLGR mit 45 Minuten und MLGA mit 60 Minuten unterschieden.',
    pageIndex: [
      {
        sortIndex: 5,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 5,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 5,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Was ist, wenn ich einen Termin absagen muss?',
    answer: 'Sollten Sie einen Termin nicht wahrnehmen können, sagen Sie diesen bitte mindestens 24 Stunden vorher ab. Sie können dies gerne telefonisch, per Mail oder per WhatsApp erledigen. Sollten Sie telefonisch niemanden erreichen, können Sie gerne eine Nachricht auf dem Anrufbeantworter hinterlassen.',
    pageIndex: [
      {
        sortIndex: 6,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 6,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 6,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Muss ich ein Handtuch zur Behandlung mitbringen?',
    answer: 'Nein, müssen Sie nicht. Wir haben frische Handtücher in der Praxis, die Sie während Sie bei uns in Behandlung sind, als Unterlage nutzen können. Jeder Patient bekommt ein Namensschild und kann sein Handtuch in unserem Handtuchregal unterbringen.',
    pageIndex: [
      {
        sortIndex: 7,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 7,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 7,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Wann erreiche ich Sie am besten?',
    answer: 'Am besten erreichen Sie uns telefonisch zwischen 8.30 Uhr und 12.30 Uhr. Während der übrigen Zeiten kann es vorkommen, dass wir Ihren Anruf nicht persönlich entgegennehmen können. Sprechen Sie uns in diesem Fall gerne auf den Anrufbeantworter, wir rufen Sie baldmöglich zurück.',
    pageIndex: [
      {
        sortIndex: 8,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 8,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 8,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Wo kann ich parken?',
    answer: 'Es sind 6 Parkplätze in der Tiefgarage bei der <a href="/karlstrasse49a/kontakt">Praxis an der Karlstraße 49a </a> verfügbar. Bitte zum Rolltor vorfahren und bei "Kurasan" klingeln.',
    pageIndex: [
      {
        sortIndex: 9,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 9,
        praxis: PraxisSelection.Kurasan2
      },
      // {
      //   sortIndex: 9,
      //   praxis: PraxisSelection.Waldstadt
      // },
    ]
  },
  {
    question: 'Was muss ich zu meinem Termin mitbringen?',
    answer: 'Bringen Sie zu Ihrem Termin bitte ihr Rezept mit, welches nicht älter als 28 Tage sein sollte. Falls Sie über Arztberichte verfügen, können Sie diese gerne mitbringen. Denken Sie außerdem an ausreichend Zeit, falls Sie weitere Termine ausmachen möchten.',
    pageIndex: [
      {
        sortIndex: 10,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Brauche ich ein Rezept?',
    answer: 'Ja, die logopädische Behandlung ist nicht ohne Rezept möglich.',
    pageIndex: [
      {
        sortIndex: 11,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Muss ich etwas zu meinem Rezept dazuzahlen?',
    answer: 'Ja, gesetzlich Versicherte müssen die sogenannten „Rezeptgebühr“ zahlen. Diese bemisst sich am Wert des jeweiligen Rezeptes. Es sind immer 10% des Rezeptwertes, was meist zwischen 60 und 80 Euro.',
    pageIndex: [
      {
        sortIndex: 12,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Kann ich auch mit EC-Karte zahlen?',
    answer: 'Ja, es ist bei uns auch möglich mit EC-Karte zu bezahlen.',
    pageIndex: [
      {
        sortIndex: 13,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Wie lange dauert ein Termin?',
    answer: 'Die Therapien haben eine Behandlungszeit von 30 bis 60 Minuten.',
    pageIndex: [
      {
        sortIndex: 14,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Was ist, wenn ich einen Termin absagen muss?',
    answer: 'Sollte Sie einen Termin nicht wahrnehmen können, sagen Sie diesen bitte mindestens 24 Stunden vorher ab. Sie können dies gerne telefonisch, per Mail oder per WhatsApp erledigen. Sollten Sie telefonisch niemanden erreichen, können Sie gerne eine Nachricht auf dem Anrufbeantworter hinterlassen.',
    pageIndex: [
      {
        sortIndex: 15,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Wann erreiche ich sie am besten?',
    answer: 'Am besten erreichen Sie uns telefonisch zwischen 8.30 Uhr und 12.30 Uhr. Während der übrigen Zeiten kann es vorkommen, dass wir den Anruf nicht persönlich entgegennehmen können. Sprechen Sie uns in diesem Fall gerne auf den Anrufbeantworter, wir rufen Sie baldmöglich zurück.',
    pageIndex: [
      {
        sortIndex: 16,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Wie läuft ein Termin ab?',
    answer: 'In der ersten Stunde der logopädischen Therapie erfolgt die Diagnostik und das Anamnesegespräch. Bei Kindern findet das Anamnesegespräch selbstverständlich mit den Erziehungsberechtigten statt. In den darauffolgenden Stunden ist es für die Behandlungszeit sinnvoll, dass das Kind, wenn möglich allein in die Therapiestunde kommt, da die Konzentration so oftmals besser ist. Am Ende jeder Behandlung erfolgt ein Elterngespräch, um einen Stundenrückblick zu verschaffen, die Hausaufgaben zu besprechen und offene Fragen zu klären.',
    pageIndex: [
      {
        sortIndex: 17,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    question: 'Was ist außerhalb der Therapien wichtig?',
    answer: 'Außerhalb der Therapiezeiten ist das häusliche Üben unerlässlich. Daher ist die Elternberatung von großer Wichtigkeit, damit das häusliche Üben korrekt erfolgt.',
    pageIndex: [
      {
        sortIndex: 18,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
];

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor() { }

  public loadFaqEntries(selection: PraxisSelection): FaqEntry[] {
    return faqDB.filter(x => x.pageIndex.find(c => c.praxis === selection) != null).sort((x, y) => sortByPraxis(x, y, selection));
  }

}
