<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">

    <div class="row">


      <div class="col-12 inline">

        <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
        <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5" style="white-space: normal; word-break: break-all">
          Craniomandibuläre Dysfunktion (CMD)
        </h2>
      </div>

      <div class="col-12 col-md-6 inline d-flex flex-column justify-content-center">
        <img class="card-img-top img-fluid" src="assets/img/Kiefer.png" alt="Card image cap">
      </div>

      <div class="col-12 col-md-6 inline">


        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist Craniomandibuläre Dysfunktion (CMD)?</h4>
        <div class="text-grey mb-3">

          cranium = Schädel<br>
          mandibula = Unterkiefer<br>
          <br><br>
          Beschreibt eine Vielzahl von Problemen rund um den Kiefer, das Kiefergelenk und die Kiefermuskulatur. Arbeiten Kopf und Kiefer nicht richtig zusammen, kann es zu einer Fehl- oder auch Dysfunktion kommen.
          <br><br>

        </div>

      </div>

      <div class="col-12 inline">

        <div class="pt-2">

          Mit der Zeit können diese Dysfunktionen zu einer Reihe unangenehmer oder schmerzhafter Symptome führen, deren Ursache häufig übersehen wird.
          Kieferproblematiken müssen sich nämlich nicht immer in Form von Kieferschmerzen äußern, sondern können unter anderem folgende Symptome mit sich führen: <br>

          <ul>
            <li>
              <span>Kieferschmerzen / -knacken / -blockaden</span>
            </li>
            <li>
              <span>Knirschen</span>
            </li>
            <li>
              <span>Kopfschmerzen</span>
            </li>
            <li>
              <span>Schmerzen beim Kauen</span>
            </li>
            <li>
              <span>Rückenschmerzen</span>
            </li>
            <li>
              <span>Tinnitus</span>
            </li>
            <li>
              <span>Schwindel</span>
            </li>
            <li>
              <span>Ohrenschmerzen</span>
            </li>
            <li>
              <span>Nacken-/Schulterverspannungen</span>
            </li>
            <li>
              <span>Bewegungseinschränkungen</span>
            </li>
          </ul>

        </div>

        <h4 class="mt-5 font-weight-bold text-white text-uppercase mb-1"> Was kann eine Behandlung einer Craniomandibuläre Dysfunktion bewirken?</h4>
        <p class="text-grey ">

          Unsere Therapeuten können dir mit einer CMD-Behandlung vor allem dann helfen, wenn die Ursache deiner Beschwerden eine muskuläre Verspannung und/oder Blockade ist.
          <br><br>
          Durch manuelles Einwirken des Therapeuten auf das Kiefergelenk wird die Muskulatur gelockert. Dadurch werden Spannungen und Blockaden gelöst. Außerdem bekommen unsere Patienten unter Anleitung des Therapeuten Übungen und Techniken für zu Hause gezeigt, um eine langfristige Verbesserung der Beschwerden erreichen zu können.

        </p>

      </div>
    </div>

  </div>
</div>




