import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-facebook, facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss']
})
export class FacebookComponent implements OnInit {

  @Input()
  public facebook: string;

  @Input()
  public bigSymbols = false;

  constructor() { }

  ngOnInit(): void {
  }

}
