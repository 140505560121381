import { Component, OnInit } from '@angular/core';
import {AppService} from '../../../services/app.service';

@Component({
  selector: 'app-bhv-kooperation',
  templateUrl: './bhv-kooperation.component.html',
  styleUrls: ['./bhv-kooperation.component.scss']
})
export class BhvKooperationComponent implements OnInit {

  constructor(public appService: AppService) { }

   ngOnInit(): void {
    this.appService.scrollUp();
  }

}
