import {Component, Input, OnInit} from '@angular/core';
import {AddressLine} from '../../services/app.service';

@Component({
  selector: 'app-address-line, address-line',
  templateUrl: './address-line.component.html',
  styleUrls: ['./address-line.component.scss']
})
export class AddressLineComponent implements OnInit {


  @Input()
  public address: AddressLine;

  constructor() { }

  ngOnInit(): void {
  }

}
