<ng-container *ngIf="loaded|async">
  <div id="header" *ngIf="appService.praxisSettings|async as praxis">
    <!--Branding & Navigation Region-->

    <div data-toggle="sticky">

      <!--Header & Branding region-->
      <div class="header">
        <!-- all direct children of the .header-inner element will be vertically aligned with each other you can override all the behaviours using the flexbox utilities (flexbox.htm) All elements with .header-brand & .header-block-flex wrappers will automatically be aligned inline & vertically using flexbox, this can be overridden using the flexbox utilities (flexbox.htm) Use .header-block to stack elements within on small screen & "float" on larger screens use .order-first or/and .order-last classes to make an element show first or last within .header-inner or .headr-block elements -->
        <div class="container">

          <div class="header-inner d-flex justify-content-center">

            <!--branding/logo -->
            <div class="header-brand border-0" style="cursor: pointer" routerLink="/start" (click)="resetPraxisSelection()" [ngClass]="{'m-0': praxis.type === PraxisSelection.Undefined}">
              <a class="header-brand-text">
                <img src="assets/img/logo_small.png" alt="Kurasan logo">
              </a>

              <div class="header-divider"></div>
              <div class="header-slogan d-flex flex-column">
                <span>{{praxis.title}}</span>
                <span>{{praxis.address?.street}} {{praxis.address?.houseNo}}</span>

                <small *ngIf="praxis.type === PraxisSelection.Undefined" class="">
                  Herzlich Willkommen
                </small>

              </div>
            </div>

            <a *ngIf="praxis.type !== PraxisSelection.Undefined" href="#top" class="btn btn-link btn-icon header-btn float-right d-sm-none" data-toggle="collapse" data-target=".navbar-main" data-direction="right">
              <i class="fas fa-bars"></i>
            </a>

          </div>


          <div class="navbar navbar-expand p-0" *ngIf="praxis.type !== PraxisSelection.Undefined && praxis.type !== PraxisSelection.Impressum">

            <!--everything within this div is collapsed on mobile-->
            <div class="navbar-main collapse">
              <!--main navigation-->
              <ul class="nav navbar-nav float-lg-right dropdown-effect-fade">

                <ng-container *ngFor="let item of entries|async">

                  <ng-container *ngIf="item.entries != null && item.entries.length > 0 else sectionLink">

                    <li class="nav-item dropdown" routerLinkActive="active">
                      <a class="nav-link dropdown-toggle" href="" data-toggle="dropdown" data-hover="dropdown">
                        {{item.name}}
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" *ngFor="let subEntry of item.entries" [routerLink]="praxis.urlPrefix + '/' + subEntry.route">{{subEntry.name}}</a>
                      </div>
                    </li>

                  </ng-container>
                  <ng-template #sectionLink>

                    <li class="nav-item" routerLinkActive="active">
                      <a class="nav-link" [routerLink]="praxis.urlPrefix + '/' + item.route">{{item.name}}</a>
                    </li>

                  </ng-template>

                </ng-container>


              </ul>
            </div>

          </div>

          <!--
                    <div class="navbar navbar-expand p-0" *ngIf="praxis.type !== PraxisSelection.Undefined && praxis.type !== PraxisSelection.Impressum">


                      &lt;!&ndash;everything within this div is collapsed on mobile&ndash;&gt;
                      <div class="navbar-main collapse">
                        &lt;!&ndash;main navigation&ndash;&gt;
                        <ul class="nav navbar-nav float-lg-right dropdown-effect-fade">

                          <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="praxis.urlPrefix + '/start'">Start</a>
                          </li>


                          <li class="nav-item dropdown" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" href="" data-toggle="dropdown" data-hover="dropdown">
                              Kurasan
                            </a>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/team'">Unser Team</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/galerie'">Galerie</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/stellenausschreibung'">Offene Stellen</a>
                            </div>
                          </li>


                          <li class="nav-item dropdown" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" href="" data-toggle="dropdown" data-hover="dropdown">
                              Therapiespektrum
                            </a>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/physiotherapie'">Physiotherapie</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/manuelleTherapie'">Manuelle Therapie</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/kgg'">Krankengymnastik am Gerät (KGG)</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/physikalischeTherapie'">Physikalische Therapie</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/manuelleLymphdrainage'">Manuelle Lymphdrainage</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/taping'">Taping</a>
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/selbstzahlerleistungen'">Selbstzahlerleistungen</a>
                              &lt;!&ndash;                  <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/bhvKooperation'">BHV Kooperation</a>&ndash;&gt;
                              <a class="dropdown-item" [routerLink]="praxis.urlPrefix + '/cmd'">Craniomandibuläre Dysfunktion (CMD)</a>
                            </div>
                          </li>

                          <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="praxis.urlPrefix + '/betriebliche-gesundheitsfoerderung'">BGF</a>
                          </li>


                          <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="praxis.urlPrefix + '/faq'">Faq</a>
                          </li>


                          <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="praxis.urlPrefix + '/kontakt'">Kontakt</a>
                          </li>

                          <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="praxis.urlPrefix + '/termin'">Termin</a>
                          </li>

                          <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="praxis.urlPrefix + '/partner'">Partner</a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    -->
        </div>
      </div>
    </div>
  </div>

</ng-container>
