<div
  class=" blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
    <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
      Physikalische Therapie
    </h2>

    <div class="row">

      <div class="col-12">

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist physikalische Therapie?</h4>

        <p class="text-grey">Unter dem Begriff physikalische Therapie fasst man Therapieverfahren zusammen, die auf physikalischen Methoden beruhen (z.B. Wärme, Kälte, Licht oder elektrische Reize). Dazu gehören unter anderem Elektrotherapie, Ultraschall, Kältetherapie und Wärme.<br><br></p>

      </div>

      <div class="col-md-6 inline p-2">
        <img class="card-img-top img-fluid rounded" src="assets/img/thea/physiotherapie_elektrotherapie.jpg" alt="Card image cap">
      </div>
      <div class="col-md-6 inline left d-flex flex-column justify-content-center">

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist Elektrotherapie?</h4>
        <p class="text-grey mb-3">Als Elektrotherapie bezeichnet man Behandlungsverfahren, bei denen elektrischer Strom eingesetzt wird. Schmerzlinderung, Muskelstimulierung, Spannungssenkung im Muskel und Durchblutungsförderung sind positive Effekte dieser Maßnahmen. Die Verfahren der Elektrotherapie haben sich vor allem bei akuten und chronischen Schmerzzuständen wie Neuralgien, Muskelverspannungen oder -zerrungen bewährt.
          Oft wird auch eine Kombination aus Elektrotherapie und Ultraschall eingesetzt. Beide Komponenten wirken gleichzeitig, auf das Gewebe ein. Dadurch wird ihre Wirkung um ein Vielfaches gesteigert.
        </p>

      </div>
      <div class="col-md-6 inline p-2">
        <img class="card-img-top img-fluid rounded" src="assets/img/thea/physiotherapie_ultraschall.jpg" alt="Card image cap">
      </div>
      <div class="col-md-6 inline left d-flex flex-column justify-content-center">

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist Ultraschall?</h4>
        <p class="text-grey mb-3">
          Bei einer Ultraschalltherapie wird mittels Ultraschall Wärme, beziehungsweise Gewebebewegung erzeugt, um die schmerzende Körperstelle lokal zu behandeln. Durch den Ultraschall kommt es im Gewebe zu einer mechanischen und thermischen Wirkung. Der Schalldruck ermöglicht starke Kompressionen und Expansionen, was der Wirkung einer kräftigen Massage oder Bindegewebsmassage entspricht. Die thermische Wirkung entsteht durch die Schallresorption körpereigenen Gewebes. Diese Wärmebildung führt zu einer Stoffwechselsteigerung. Der Ultraschall kann auch dazu genutzt werden Salben in die Haut einzubringen.
        </p>

      </div>

      <div class="col-md-6 inline p-2">
        <img class="card-img-top img-fluid rounded" src="assets/img/thea/physiotherapie_fango.jpg" alt="Card image cap">
      </div>
      <div class="col-md-6 inline left d-flex flex-column justify-content-center">

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist Wärmetherapie?</h4>
        <p class="text-grey mb-3">
          Die wohltuende Wirkung von Wärme hat jeder schon einmal gespürt. Wärme wird daher auch zur Schmerztherapie und zur allgemeinen Durchblutungsförderung eingesetzt. Auch als Vorbereitung einer Massage sind Wärmebehandlungen, mit ihrer muskelentspannenden Wirkung, empfehlenswert. Durch die Wärmetherapie wird der Stoffwechsel sowie der die Spannung des Muskels gesenkt.
        </p>

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist Kältetherapie?</h4>
        <p class="text-grey mb-3">
          Die Kältebehandlung wirkt entzündungshemmend, schmerzlindernd und beeinflusst den Stoffwechsel zum Beispiel bei Prellungen und rheumatischen Erkrankungen, aber auch nach Operationen. Mit der Kältetherapie erreicht man eine lokale Unterkühlung des Gewebes zu therapeutischen Zwecken.
        </p>
      </div>

    </div>

  </div>
</div>

