export const sessionDebug = false;
export const localDebug = false;

export function removeSessionItem(key: string) {
  try {
    if (sessionDebug) { // noinspection ExceptionCaughtLocallyJS
      throw new Error('session storage');
    }
    sessionStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }
}

export function clearSessionStorage() {
  try {
    if (sessionDebug) { // noinspection ExceptionCaughtLocallyJS
      throw new Error('session storage');
    }
    sessionStorage.clear();
  } catch (e) {
    console.error(e);
  }
}

export function getSessionItem<T>(item: string, fallback?: T): T {

  try {
    if (sessionDebug) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('session storage');
    }
    const result = JSON.parse(sessionStorage.getItem(item)) as T;
    if (result == null) {
      return fallback;
    }
    return result;
  } catch (e) {
    console.error('disabled session storage', e, 'fallback to', fallback);
    return fallback;
  }

}

export function setSessionItem(key: string, item: any): void {

  try {
    if (sessionDebug) { // noinspection ExceptionCaughtLocallyJS
      throw new Error('session storage');
    }
    sessionStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.error('disabled session storage', e);
    return null;
  }
}

export function clearLocalstorage() {
  try {
    if (localDebug) { // noinspection ExceptionCaughtLocallyJS
      throw new Error('session storage');
    }
    localStorage.clear();
  } catch (e) {
    console.error(e);
  }
}

export function setLocalItem(key: string, item: any): void {

  try {
    if (localDebug) { // noinspection ExceptionCaughtLocallyJS
      throw new Error('local storage');
    }
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.error('disabled local storage', e);
    return null;
  }

}


export function getLocalItem<T>(item: string, fallback?: T): T {

  try {
    if (localDebug) { // noinspection ExceptionCaughtLocallyJS
      throw new Error('local storage');
    }

    const result = JSON.parse(localStorage.getItem(item)) as T;

    if (result == null) {
      return fallback;
    }
    return result;
  } catch (e) {
    console.error('disabled local storage', e, 'fallback to', fallback);
    return fallback;
  }

}
