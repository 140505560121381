import { Component, OnInit } from '@angular/core';
import {AppService, defaultPraxis, waldStadt} from '../../services/app.service';

@Component({
  selector: 'app-stellenauschreibung-waldstadt',
  templateUrl: './stellenauschreibung-waldstadt.component.html',
  styleUrls: ['./stellenauschreibung-waldstadt.component.scss']
})
export class StellenauschreibungWaldstadtComponent implements OnInit {

  defaultPraxis = waldStadt;

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }

}
