import {Component, OnInit} from '@angular/core';
import {AppService, PraxisSelection} from '../../services/app.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.currentPraxis.next(PraxisSelection.Undefined);
    this.appService.scrollUp();
  }

}
