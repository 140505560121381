<footer id="footer" class="p-0" *ngIf="(appService.praxisSettings|async) as praxis">

  <!--  <hr class="my-0 hr-blank op-2"/>-->
  <div class="bg-kurasan-gray text-sm py-1">
    <div class="container">

      <div class="d-flex flex-column flex-wrap justify-content-between align-items-center">
        <div class="mt-2 mt-lg-0 contactIcons py-2">
          <ul class="list-inline footer-links mb-0 d-flex">
            <li class="list-inline-item" routerLinkActive="active">
              <email [email]="praxis.email" [showEmail]="false" [bigSymbols]="true"></email>
            </li>
            <li class="list-inline-item" routerLinkActive="active">
              <phone [phone]="praxis.phone" [showPhoneNumber]="false" [bigSymbols]="true"></phone>
            </li>
            <li class="list-inline-item" routerLinkActive="active">
              <whatsapp [phone]="praxis.whatsapp" [bigSymbols]="true"></whatsapp>
            </li>
            <li class="list-inline-item" routerLinkActive="active">
              <facebook [facebook]="praxis.facebook" [bigSymbols]="true"></facebook>
            </li>
            <li class="list-inline-item" routerLinkActive="active">
              <app-instagram [instagram]="praxis.instagram" [bigSymbols]="true"></app-instagram>
            </li>
          </ul>
        </div>
        <div class="mt-2 mt-lg-0">
          <!--          <small>-->
          <!--            Copyright {{currentYear}} &copy; Kurasan-Karlsruhe, Kriegsstraße 140 / Karlstraße 49a, 76133 Karlsruhe-->
          <!--          </small>-->
          <ul class="list-inline footer-links float-md-right mb-0 text-center">
            <li class="list-inline-item">
              <span style="color:#FFFFFF">Copyright {{currentYear}} &copy;</span>
            </li>
            <li class="list-inline-item">
              <span style="color:#FFFFFF">•</span>
            </li>
            <ng-container  *ngFor="let item of praxisMap|keyvalue|praxisSelection:(showOnly|async)">
              <li class="list-inline-item" routerLinkActive="active">
                <a [routerLink]="item.urlPrefix + '/kontakt'">{{item.title}}, {{item.address?.street}} {{item.address?.houseNo}}</a>
              </li>
              <li class="list-inline-item">
                <span style="color:#FFFFFF">•</span>
              </li>
            </ng-container>
            <li class="list-inline-item" routerLinkActive="active">
              <a routerLink="/impressum">Impressum/Datenschutz</a>
            </li>
          </ul>
        </div>
      </div>


    </div>
    <div id="backToTopBtn" (click)="backToTop()"
         class="btn btn-icon btn-inverse pos-fixed pos-b pos-r mr-3 mb-3 scroll-state-hidden"
         title="Zurück zum Anfang" data-scroll="scroll-state"><i class="fas fa-chevron-up"></i></div>
  </div>
</footer>
