<div class="container">
  <div class="d-flex justify-content-center">
    <div class="rounded-circle bg-white pos-absolute d-flex align-items-center justify-content-center" style="width: 125px; height: 125px; top:20px">
      <img class="card-img-top img-fluid" style="width: 100px" src="assets/img/logo_full.png" alt="Card image cap">
    </div>
    <img class="card-img-top img-fluid" style="width: 600px; height: 399px" src="assets/img/bew/DF0_6759.jpg" alt="Card image cap">
  </div>
  <div class="d-flex justify-content-center">
    <div class="text-center blaue_box p-2" style="width: 500px; font-size: 1.3rem; margin-top: -50px">
      Engagierte Physiotherapeut:innen für <br> <strong>Hausbesuche</strong> im Karlsruher Stadtgebiet <br> gesucht!
    </div>
  </div>

  <div class="d-flex flex-column align-items-center justify-content-center mt-3">

    <div class="my-2 border rounded p-2">
    <a href="assets/download/Stellenanzeige Kurasan Hausbesuche.pdf" target="_blank"><i class="mr-2 fas fa-file-pdf text-primary"></i>Flyer herunterladen</a>
    </div>

    <div class="d-flex flex-column">
      <strong class="text-center">Ihre Vorteile</strong>
      <ul>
        <li>
          <span>Gute Wiedereinstiegsmöglichkeiten</span>
        </li>
        <li>
          <span>Freie Zeiteinteilung</span>
        </li>
        <li>
          <span>Selbständiges Arbeiten</span>
        </li>
        <li>
          <span>Sehr gute Bezahlung</span>
        </li>
        <li>
          <span>Freie Entfaltungsmöglichkeiten</span>
        </li>
      </ul>
      <strong class="text-center">Ihre Aufgaben</strong>
      <ul>
        <li>
          <span>Durchführung von Hausbesuchen</span>
        </li>
        <li>
          <span>Selbständige Terminvergabe und -koordination</span>
        </li>
        <li>
          <span>Zur Ausübung der Tätigkeit bekommen Sie alle <br> Hilfsmittel von uns zur Verfügung gestellt</span>
        </li>
        <li>
          <span>Fahrtkosten werden erstattet</span>
        </li>
      </ul>
      <strong class="text-center">Was Sie mitbringen sollten</strong>
      <ul>
        <li>
          <span>Abgeschlossene Ausbildung zum Physiotherapeuten</span>
        </li>
        <li>
          <span>Fähigkeit zum selbständigen Arbeiten</span>
        </li>
        <li>
          <span>Bereitschaft zur Mobilität im Raum Karlsruhe</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <p class="text-center primary-color font-weight-bold">
      Wenn wir Ihr Interesse wecken konnten, oder Sie jemanden kennen,<br>
      melden Sie sich gern per Telefon unter <phone [phone]="defaultPraxis.phone"></phone><br>
      oder per Mail an <email [email]="defaultPraxis.email"></email>.<br>
    </p>
  </div>

</div>
