import {Injectable, EventEmitter} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

export enum PraxisSelection {
  Undefined = 0,
  Kurasan1,
  Kurasan2,
  Ettlingen,
  Waldstadt,
  Impressum,
  Knielingen,

}

export class AddressLine {
  public street?: string;
  public houseNo?: string;
  public zip?: string;
  public city?: string;
}

export class Praxis {

  public urlPrefix: string;
  public email?: string;
  public phone?: Phone;
  public fax?: Phone;
  public imgUrl?: string;
  public title?: string;
  public instagram?: string;
  public facebook?: string;
  public whatsapp?: Phone;

  public type: PraxisSelection;
  public address?: AddressLine;

  public teamImgUrl?: string;

  public openingTimes?: OpeningTimes;
  public metaTags: string;
  public domain?: string;

  public youtubeStart?: string;
}


export class OpeningTimes {

  public monday?: string;
  public tuesday?: string;
  public wednesday?: string;
  public thursday?: string;
  public friday?: string;
  public saturday?: string;
  public sunday?: string;

}

export class Phone {
  public formatted: string;
  public international: string;
}

export const defaultPraxis: Praxis = {
  urlPrefix: '',
  email: 'info@kurasan-karlsruhe.de',
  phone: {formatted: '+49 (0) 721 603212 1', international: '+497216032121'},
  fax: {formatted: '+49 (0) 721 603212 2', international: '+497216032122'},
  whatsapp: {formatted: '+49 (0) 163 7155151', international: '+491637155151'},
  instagram: 'kurasankarlsruhe',
  facebook: 'Kurasan-Karlsruhe-Physiotherapie-1799932760223748',
  type: PraxisSelection.Undefined,
  title: 'Kurasan',
  metaTags: 'physiotherapie,kurasan,benedikt hettich,krankengymnastik'
};

export const kura1: Praxis = {
  urlPrefix: '/kriegsstrasse140',
  email: 'info@kurasan-karlsruhe.de',
  phone: {formatted: '+49 (0) 721 603212 1', international: '+497216032121'},
  fax: {formatted: '+49 (0) 721 603212 2', international: '+497216032122'},
  whatsapp: {formatted: '+49 (0) 163 7155151', international: '+491637155151'},
  address: {
    city: 'Karlsruhe',
    zip: '76133',
    houseNo: '140',
    street: 'Kriegsstraße',
  },
  imgUrl: 'assets/img/praxis-selection/kurasan_1.jpg',
  title: 'Kurasan-Karlsruhe',
  instagram: 'kurasankarlsruhe',
  facebook: 'Kurasan-Karlsruhe-Physiotherapie-1799932760223748',
  type: PraxisSelection.Kurasan1,
  teamImgUrl: 'assets/img/praxis-selection/kurasan_1.jpg',
  openingTimes: {
    monday: '07:30 - 19:00',
    tuesday: '07:30 - 19:00',
    wednesday: '07:30 - 19:00',
    thursday: '07:30 - 19:00',
    friday: '07:30 - 18:00',
  },
  metaTags: 'physiotherapie,kurasan,benedikt hettich,krankengymnastik,kurasan karlsruhe,kurasan-karlsruhe,karlsruhe kurasan,kgg,krankengymnastik am gerät,kgg karlsruhe,kgg physio karlsruhe,krankengymnastik am gerät karlsruhe,sportphysiotherapie,sportphysio, physio karlstor karlsruhe',
  youtubeStart: 'https://www.youtube-nocookie.com/embed/vyA9j2egKIo'
} as Praxis;

export const waldStadt: Praxis = {
  urlPrefix: '/waldstadt',
  email: 'info@kurasan-waldstadt.de',
  phone: {formatted: '+49 (0) 721 6032125', international: '+4907216032125'},
  whatsapp: {formatted: '+49 (0) 179 5132003', international: '+491795132003'},
  address: {
    city: 'Karlsruhe',
    zip: '76139',
    houseNo: '16',
    street: 'Beuthenerstr.',
  },
  imgUrl: 'assets/img/praxis-selection/waldstadt.jpg',
  title: 'Kurasan-Waldstadt',
  instagram: 'kurasankarlsruhe',
  facebook: 'Kurasan-Karlsruhe-Physiotherapie-1799932760223748',
  type: PraxisSelection.Waldstadt,
  teamImgUrl: 'assets/img/praxis-selection/waldstadt.jpg',
  openingTimes: {
    monday: '07:30 - 19:00',
    tuesday: '07:30 - 19:00',
    wednesday: '07:30 - 19:00',
    thursday: '07:30 - 19:00',
    friday: '07:30 - 16:00',
    saturday: '10:00 - 12:00'
  },
  metaTags: 'physiotherapie,kurasan,benedikt hettich,krankengymnastik,kurasan waldstadt,kurasan-waldstadt, ergotherapie waldstadt,ergotherapie,waldstadt kurasan, vojta, sportphysiotherapie, sportphysio, kinderphysio, kinderphysiotherapie, kinderphysiotherapeut, kgg, krankengymnastik am gerät, kgg karlsruhe, kgg physio karlsruhe, krankengymnastik am gerät karlsruhe,kinder physiotherapie, kinder physiotherapie karlsruhe,kinder physiotherapie waldstadt,kinderphysio waldstadt,physiotherapie für kinder waldstadt, physiotherapie für kinder karlsruhe, physiotherapie für kinder, ergo,kind ergotherapie,kind ergo, kinderergo, kreativ kind, kinderergo waldstadt, ergo kind waldstadt,kinder ergotherapie waldstadt,ergo hand, hand unfall ergo,ergotherapie hand, ergotherapie hand waldstadt,ergotherapie hand karlsruhe,ergo hand karlsruhe,handtherapie ergo, handterapie karlsruhe,handtherapie waldstadt',
  domain: 'https://kurasan-waldstadt.de'
};

export const praxis = new Map<PraxisSelection, Praxis>([
  [
    PraxisSelection.Undefined,
    defaultPraxis
  ],
  [
    PraxisSelection.Impressum,
    {
      urlPrefix: '',
      email: 'info@kurasan-karlsruhe.de',
      phone: {formatted: '+49 (0) 721 603212 1', international: '+497216032121'},
      fax: {formatted: '+49 (0) 721 603212 2', international: '+497216032122'},
      whatsapp: {formatted: '+49 (0) 163 7155151', international: '+491637155151'},
      instagram: 'kurasankarlsruhe',
      facebook: 'Kurasan-Karlsruhe-Physiotherapie-1799932760223748',
      type: PraxisSelection.Impressum,
      title: 'Kurasan',
      metaTags: 'physiotherapie,kurasan,benedikt hettich,krankengymnastik,kurasan-karlsruhe,karlsruhe kurasan'
    } as Praxis
  ],
  [
    PraxisSelection.Kurasan1,
    kura1
  ],
  [
    PraxisSelection.Kurasan2,
    {
      urlPrefix: '/karlstrasse49a',
      email: 'info2.0@kurasan-karlsruhe.de',
      phone: {formatted: '+49 (0) 721 603212 3', international: '+497216032123'},
      fax: {formatted: '+49 (0) 721 603212 2', international: '+497216032122'},
      whatsapp: {formatted: '+49 (0) 179 2960220', international: '+491792960220'},
      address: {
        city: 'Karlsruhe',
        zip: '76133',
        houseNo: '49a',
        street: 'Karlstr.',
      },
      imgUrl: 'assets/img/praxis-selection/kurasan_2.jpg',
      title: 'Kurasan-Karlsruhe 2.0',
      instagram: 'kurasankarlsruhe',
      facebook: 'Kurasan-Karlsruhe-Physiotherapie-1799932760223748',
      type: PraxisSelection.Kurasan2,
      teamImgUrl: 'assets/img/praxis-selection/kurasan_2.jpg',
      openingTimes: {
        monday: '07:30 - 19:00',
        tuesday: '07:30 - 19:00',
        wednesday: '07:30 - 19:00',
        thursday: '07:30 - 19:00',
        friday: '07:30 - 18:00',
      },
      metaTags: 'physiotherapie,kurasan,benedikt hettich,krankengymnastik,kurasan karlsruhe,kurasan-karlsruhe,karlsruhe kurasan,sportphysiotherapie,sportphysio,physio karlstor karlsruhe',
      youtubeStart: 'https://www.youtube-nocookie.com/embed/ABGK4RYyTSQ'
    } as Praxis
  ],
  [
    PraxisSelection.Waldstadt,
    waldStadt
  ],
  [
    PraxisSelection.Knielingen,
    {
      urlPrefix: '/knielingen',
      email: 'info@kurasan-knielingen.de',
      phone: {formatted: '+49 (0) 721 603212 9', international: '+497216032129'},
      fax: {formatted: '+49 (0) 721 603213 0', international: '+497216032130'},
      whatsapp: {formatted: '+49 (0) 179 51 32 842', international: '+491795132842'},
      address: {
        city: 'Knielingen',
        zip: '76187',
        houseNo: '79',
        street: 'Saarlandstr.',
      },
      imgUrl: 'assets/img/praxis-selection/knielingen.jpg',
      title: 'Kurasan-Knielingen',
      instagram: 'kurasankarlsruhe',
      facebook: 'Kurasan-Karlsruhe-Physiotherapie-1799932760223748',
      type: PraxisSelection.Knielingen,
      teamImgUrl: 'assets/img/new-team/team_gesamt.png',
      openingTimes: {
        monday: '07:30 - 18:30',
        tuesday: '07:30 - 19:00',
        wednesday: '07:00 - 18:30',
        thursday: '07:30 - 16:30',
        friday: '07:00 - 16:00',
      },
      metaTags: 'physiotherapie,kurasan,benedikt hettich,krankengymnastik,kurasan knielingen,kurasan-knielingen,knielingen kurasan, vojta, sportphysiotherapie, sportphysio, kinderphysio, kinderphysiotherapie, kinderphysiotherapeut, kgg, krankengymnastik am gerät, kgg karlsruhe, kgg physio karlsruhe, krankengymnastik am gerät karlsruhe,kinder physiotherapie, kinder physiotherapie karlsruhe,kinder physiotherapie knielingen,kinderphysio knielingen,physiotherapie für kinder knielingen, physiotherapie für kinder karlsruhe, physiotherapie für kinder, ergo,kind ergotherapie,kreativ kind,bobath',
    } as Praxis
  ],
]);

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public PraxisSelection = PraxisSelection;
  public currentPraxis = new BehaviorSubject<PraxisSelection>(PraxisSelection.Undefined);
  public praxisSettings = new BehaviorSubject<Praxis>(defaultPraxis);

  public generalMetaTags = 'Praxis Waldstadt,Massage Waldstadt,Rücken Waldstadt,Krankengymnastik am Gerät Waldstadt,Manuelle Therapie Waldstadt,Krankengymnastik Waldstadt,MT Waldstadt,Physiotherapeut Waldstadt,KG Waldstadt,KGG Waldstadt,Ergotherapie Waldstadt,Physiotherapie Waldstadt,Physio Waldstadt,physio,manuelle therapie,physiotherapeut,physiotherapeutin,physiotherapeutische,krankengymnasten,physiotherapie in der nähe,physio in der nähe,physiotherapeut in der nähe,physikalische therapie,schlingentisch,praxis für physiotherapie,physiotherapie praxis,physiopraxis,craniomandibuläre dysfunktion physiotherapie,fango massage,physiotherapie in meiner nähe,physio in meiner nähe,ultraschalltherapie,physio krankengymnastik,physiotherapie krankengymnastik,cmd,kiefergelenk,fango,elektrotherapie,elektrotherapie physio,massage physio,massage physiotherapie,massage physiotherapeut,schnelle termine physioterapie,schnelle termine physio,gute physiotherapie,gute physio,gute physio in nähe,gute physiotherapie in nähe,guter physiotherapeut,guter physiotehrapeut in nähe,traktion,traktion physio,traktion physiotherapie,kompetente physiotherapie,kompetenter physiotherapeut,Kindertherapie,Kinderphysio,Waldstadt Kinder Physiotherapie,Waldstadt Kind Physio,Physiotherapie Kinder,Physiotherapie Kind,Vojta Kinder,Vojta Kind,Vojta Physiotherapie Kinder,Vojta Physiotherapie Kind,Skoliose,Skoliose Kind,Schroth,Schroth Kind,Schroth,hysiotherapie,Ergotherapie,Ergotherapie Kind,Ergo Kind,Ergotherapie Kinder,Waldstadt Ergotherapie,Waldstadt Ergo';

  constructor(private router: Router) {
    this.currentPraxis.subscribe((x: PraxisSelection) => {
      this.praxisSettings.next(praxis.get(x));
    });
  }

  public SelectByUrl(url: string): void {

    if (url.includes('kriegsstrasse140')) {
      this.currentPraxis.next(PraxisSelection.Kurasan1);
    }
    if (url.includes('karlstrasse49a')) {
      this.currentPraxis.next(PraxisSelection.Kurasan2);
    }
    if (url.includes('knielingen')) {
      this.currentPraxis.next(PraxisSelection.Knielingen);
    }
    if (url.includes('ettlingen')) {
      this.router.navigateByUrl('/');
    }
    if (url.includes('waldstadt')) {
      this.currentPraxis.next(PraxisSelection.Waldstadt);
    }
    if (url.includes('impressum')) {
      this.currentPraxis.next(PraxisSelection.Impressum);
    }
  }

  scrollUp(): void {
    try {
      const htmlAnchor = document.getElementById('scroll-anchor');
      htmlAnchor?.scrollIntoView({behavior: 'smooth'});
    } catch (e) {
      console.error(e);
    }
  }

}


export function sortByPraxis(x, y, selection: PraxisSelection): number {

  const a = x.pageIndex.find(c => c.praxis === selection)?.sortIndex ?? 999;
  const b = y.pageIndex.find(c => c.praxis === selection)?.sortIndex ?? 999;
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  // names must be equal
  return 0;
}
