<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix" *ngIf="(appService.praxisSettings|async) as praxis">
  <div class="container content">
    <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
    <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
      Impressum
    </h2>

    <div class="row">
      <div class="col-12 col-lg-6">
        <h3>Kurasan Karlsruhe
          <small>Praxis für Physiotherapie und Manuelle Therapie</small>
        </h3>
        <p>
          Kurasan Karlsruhe ist eine von allen gängigen Krankenkassen zugelassene Praxis für Physiotherapie,
          Manuelle Therapie sowie Gesundheitskursen und weitere Therapien.
        </p>
        <p>
          Der Sitz der Praxis befindet sich in Karlsruhe, Baden-Württemberg:
        </p>
        <p>
          {{praxis.street}} {{praxis.houseNo}}<br/>
          {{praxis.zip}} {{praxis.city}}<br/>
          <phone [phone]="praxis.phone"></phone>
          <email [email]="praxis.email"></email>
          <fax [fax]="praxis.fax"></fax>
        </p><br/><br/>

        <h3>Mitarbeiter</h3>
        <p>
          Benedikt Hettich, staatlich anerkannter Physiotherapeut und Manueller Therapeut.
        </p>
        <ul>
          <li>
            die Berufsbezeichnung staatlich anerkannter Physiotherapeut
            wurde von der SRH Fachschule Karlsruhe am 20.09.2013 verliehen
          </li>
          <li>
            die Berufsbezeichnung staatlich anerkannter Manueller Therapeut
            wurde von der SRH Fachschule Karlsruhe verliehen
          </li>
        </ul>

      </div>

      <div class="col-12 col-lg-6">
        <h3>
          Inhaltlich verantwortlich gem. §5 Telemediengesetz (TMG):
        </h3>
        <p>
          Benedikt Hettich<br/>
          Physiotherapeut<br/>
          Sophienstr. 47<br/>
          76133 Karlsruhe<br/>
        </p>
      </div>


    </div>


    <div class="row">
      <div class="col-12">
        <h3>Datenschutz
          <small>Informationen gemäß Artikel 13 und 14 Datenschutz-Grundverordnung über die Verarbeitung von Daten in
            der Physiotherapiepraxis Kurasan Karlsruhe
          </small>
        </h3>
        <p>Hiermit informieren wir Sie über die Verarbeitung Ihrer Daten in unserer Praxis.</p>
        <ol>
          <li><strong> Verantwortlicher für die Datenverarbeitung</strong></li>
        </ol>
        <p>Verantwortlich für die Datenverarbeitung ist:<br/> <br/> Praxisname: Kurasan Karlsruhe, Inhaber Benedikt
          Hettich <br/> Anschrift: Kriegsstr. 140, 76133 Karlsruhe<br/> Kontaktdaten: Tel: 0721-603 21 21</p>
        <ol start="2">
          <li><strong> Zweck der Datenverarbeitung</strong></li>
        </ol>
        <p>Damit wir Sie behandeln können, müssen wir eine Patientenakte anlegen und damit personenbezogene Daten
          (Stammdaten und Gesundheitsdaten) von Ihnen verarbeiten. Das schreibt nicht nur das Gesetz vor, sondern es ist
          für die Behandlungsqualität unabdingbar. Zu den vom Gesetz besonders geschützten Gesundheitsdaten gehören
          beispielsweise von uns und anderen Ärzten erhobene Befunde, Anamnesen, Diagnosen und Therapievorschläge.</p>
        <p>Eine Übersicht der zugrundeliegenden Rechtsgrundlagen kann dem Anhang entnommen werden.</p>
        <p>Wir übermitteln Ihre personenbezogenen Daten nur dann an Dritte, wenn dies gesetzlich erlaubt ist oder Sie
          eingewilligt haben. Soweit Sie eingewilligt haben, können wir Ihre Daten auch zu bestimmten anderen als den
          Behandlungszwecken verwenden, wie z. B. Praxismailings, Informationen, Terminerinnerung, die Sie
          betreffen.</p>
        <ol start="3">
          <li><strong> Empfänger Ihrer Daten</strong></li>
        </ol>
        <p>Im Rahmen der Behandlung kann es sein, dass wir mit anderen Ärzten, Psychotherapeuten und sonstigen
          Leistungserbringern zusammenarbeiten, an die wir auch Daten von Ihnen übermitteln müssen. Darunter fallen
          beispielsweise Behandlungsberichte an Ihren zuständigen Arzt. Darüber hinaus bestehen gesetzliche Vorgaben zur
          Zusammenarbeit mit Ihrer Krankenkasse. Im Einzelfall übermitteln wir die Daten – mit Ihrem Einverständnis – an
          weitere berechtigte Empfänger.</p>
        <ol start="4">
          <li><strong> Datenverarbeitung auf dieser Internetseite</strong></li>
        </ol>
        <p>Die Praxis Kurasan Karlsruhe erhebt und speichert automatisch in ihren Serverlogfiles Informationen, die Ihr
          Browser an uns übermittelt. Dies sind:</p>
        <ul>
          <li>Browsertyp/ -version</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Referrer-URL (die zuvor besuchte Seite)</li>
          <li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
          <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen
          Datenquellen wird nicht vorgenommen, die Daten werden zudem nach einer statistischen Auswertung gelöscht.</p>
        <ol start="5">
          <li><strong> Cookies</strong></li>
        </ol>
        <p>Die Internetseiten verwenden an mehreren Stellen so genannte Cookies. Sie dienen dazu, unser Angebot
          nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner
          abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind sogenannte
          „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Cookies richten auf Ihrem Rechner
          keinen Schaden an und enthalten keine Viren.</p>
        <ol start="6">
          <li><strong> Datenschutzbestimmungen zu Einsatz und Verwendung von Google Analytics (mit
            Anonymisierungsfunktion)</strong></li>
        </ol>
        <p>Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite die Komponente Google Analytics (mit
          Anonymisierungsfunktion) integriert. Google Analytics ist ein Web-Analyse-Dienst. Web-Analyse ist die
          Erhebung, Sammlung und Auswertung von Daten über das Verhalten von Besuchern von Internetseiten. Ein
          Web-Analyse-Dienst erfasst unter anderem Daten darüber, von welcher Internetseite eine betroffene Person auf
          eine Internetseite gekommen ist (sogenannte Referrer), auf welche Unterseiten der Internetseite zugegriffen
          oder wie oft und für welche Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse wird überwiegend
          zur Optimierung einer Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.</p>
        <p>Betreibergesellschaft der Google-Analytics-Komponente ist die Google Inc., 1600 Amphitheatre Pkwy, Mountain
          View, CA 94043-1351, USA.</p>
        <p>Der für die Verarbeitung Verantwortliche verwendet für die Web-Analyse über Google Analytics den Zusatz
          "_gat._anonymizeIp". Mittels dieses Zusatzes wird die IP-Adresse des Internetanschlusses der betroffenen
          Person von Google gekürzt und anonymisiert, wenn der Zugriff auf unsere Internetseiten aus einem Mitgliedstaat
          der Europäischen Union oder aus einem anderen Vertragsstaat des Abkommens über den Europäischen
          Wirtschaftsraum erfolgt.</p>
        <p>Der Zweck der Google-Analytics-Komponente ist die Analyse der Besucherströme auf unserer Internetseite.
          Google nutzt die gewonnenen Daten und Informationen unter anderem dazu, die Nutzung unserer Internetseite
          auszuwerten, um für uns Online-Reports, welche die Aktivitäten auf unseren Internetseiten aufzeigen,
          zusammenzustellen, und um weitere mit der Nutzung unserer Internetseite in Verbindung stehende
          Dienstleistungen zu erbringen.</p>
        <p>Google Analytics setzt ein Cookie auf dem informationstechnologischen System der betroffenen Person. Was
          Cookies sind, wurde oben bereits erläutert. Mit Setzung des Cookies wird Google eine Analyse der Benutzung
          unserer Internetseite ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch
          den für die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Google-Analytics-Komponente
          integriert wurde, wird der Internetbrowser auf dem informationstechnologischen System der betroffenen Person
          automatisch durch die jeweilige Google-Analytics-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an
          Google zu übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google Kenntnis über personenbezogene
          Daten, wie der IP-Adresse der betroffenen Person, die Google unter anderem dazu dienen, die Herkunft der
          Besucher und Klicks nachzuvollziehen und in der Folge Provisionsabrechnungen zu ermöglichen.</p>
        <p>Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der Ort, von
          welchem ein Zugriff ausging und die Häufigkeit der Besuche unserer Internetseite durch die betroffene Person,
          gespeichert. Bei jedem Besuch unserer Internetseiten werden diese personenbezogenen Daten, einschließlich der
          IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an Google in den Vereinigten Staaten
          von Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den Vereinigten Staaten von
          Amerika gespeichert. Google gibt diese über das technische Verfahren erhobenen personenbezogenen Daten unter
          Umständen an Dritte weiter.</p>
        <p>Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben bereits dargestellt,
          jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der
          Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten Internetbrowsers würde auch
          verhindern, dass Google ein Cookie auf dem informationstechnologischen System der betroffenen Person setzt.
          Zudem kann ein von Google Analytics bereits gesetzter Cookie jederzeit über den Internetbrowser oder andere
          Softwareprogramme gelöscht werden.</p>
        <p>Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch Google Analytics
          erzeugten, auf eine Nutzung dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser Daten durch
          Google zu widersprechen und eine solche zu verhindern. Hierzu muss die betroffene Person ein Browser-Add-On
          unter dem Link https://tools.google.com/dlpage/gaoptout herunterladen und installieren. Dieses Browser-Add-On
          teilt Google Analytics über JavaScript mit, dass keine Daten und Informationen zu den Besuchen von
          Internetseiten an Google Analytics übermittelt werden dürfen. Die Installation des Browser-Add-Ons wird von
          Google als Widerspruch gewertet. Wird das informationstechnologische System der betroffenen Person zu einem
          späteren Zeitpunkt gelöscht, formatiert oder neu installiert, muss durch die betroffene Person eine erneute
          Installation des Browser-Add-Ons erfolgen, um Google Analytics zu deaktivieren. Sofern das Browser-Add-On
          durch die betroffene Person oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist, deinstalliert
          oder deaktiviert wird, besteht die Möglichkeit der Neuinstallation oder der erneuten Aktivierung des
          Browser-Add-Ons.</p>
        <p>Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
          https://www.google.de/intl/de/policies/privacy/ und unter http://www.google.com/analytics/terms/de.html
          abgerufen werden. Google Analytics wird unter diesem Link https://www.google.com/intl/de_de/analytics/ genauer
          erläutert.</p>

        <ol start="7">
          <li><strong> Ihre Rechte</strong></li>
        </ol>
        <p>Die gesetzlichen Regelungen räumen Ihnen einige Rechte ein. So steht Ihnen das Recht zu,</p>
        <ul>
          <li>Auskunft über die Sie betreffenden personenbezogenen Daten zu erhalten,</li>
          <li>unrichtige Daten berichtigen zu lassen,</li>
          <li>unter bestimmten Voraussetzungen die Löschung oder Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen, <br/> Hinweis: Ihre Patientenakte wird von uns nur solange aufbewahrt, wie es für die
            Behandlung erforderlich ist und es den gesetzlichen Vorschriften entspricht.
          </li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen,</li>
          <li>Ihre Daten an andere von Ihnen bestimmte Stellen übertragen zu lassen. Dazu ist eine gesonderte
            Einwilligung erforderlich.
          </li>
        </ul>
        <ol start="8">
          <li><strong> Aufbewahrungsfristen (Löschkonzept) </strong></li>
        </ol>
        <p>Das Löschkonzept der Praxis Kurasan Karlsruhe orientiert sich am Patientenrechtegesetz § 630 f BGB, welches
          vorschreibt, dass Ihre Patientenakte für die Dauer von zehn Jahren nach Abschluss der Behandlung aufzubewahren
          ist.</p>
        <p>Die Löschfristen ergeben sich wie folgt:</p>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td width="302">
              <p><strong>Bezeichnung der Aufzeichnungen </strong></p>
            </td>
            <td width="302">
              <p><strong>Aufbewahrungsfrist</strong></p>
            </td>
          </tr>
          <tr>
            <td width="302">
              <p>Patientenakte mit Anmeldebogen, Behandlungsvertrag, Verordnungskopien, Aufklärungsbogen, Befundbögen,
                Behandlungsplan, Verlaufsdokumentation und Arztbericht.</p>
            </td>
            <td width="302">
              <p>Mindestens 10 Jahre</p>
            </td>
          </tr>
          <tr>
            <td width="302">
              <p>Rechnungskopien</p>
            </td>
            <td width="302">
              <p>10 Jahre</p>
            </td>
          </tr>
          <tr>
            <td width="302">
              <p>Produktkarte (Medizinprodukte und alle Elektrogeräte)</p>
            </td>
            <td width="302">
              <p>10 Jahre</p>
            </td>
          </tr>
          <tr>
            <td width="302">
              <p>Prüfkontrolle bezogen auf Medizinprodukte und Elektrogeräte</p>
            </td>
            <td width="302">
              <p>10 Jahre</p>
            </td>
          </tr>
          <tr>
            <td width="302">
              <p>Rechnungen</p>
            </td>
            <td width="302">
              <p>10 Jahre</p>
            </td>
          </tr>
          </tbody>
        </table>
        <p>Soweit Sie in die Verarbeitung Ihrer Daten eingewilligt haben, haben Sie das Recht, die Einwilligung für die
          zukünftige Verarbeitung zu widerrufen.</p>
        <p>Wir weisen darauf hin, dass damit gegebenenfalls eine weitere Behandlung nicht mehr möglich ist.</p>
        <p>Sie haben das Recht, sich an die zuständige Aufsichtsbehörde für den Datenschutz zu wenden, wenn Sie der
          Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt. Die Anschrift der
          für uns zuständigen Aufsichtsbehörde lautet:</p>
        <p>Landesbeauftragter für Datenschutz und Informationsfreiheit Baden-Württemberg, Königstraße 10 a, 70173
          Stuttgart</p>
        <p>Stand: 24.05.2018</p>

      </div>

      <div class="col-12">
        <h3>Downloads</h3>
        <ul>
          <li><a href="assets/download/Datenschutzkonzept der Praxis.pdf">Datenschutzkonzept der Praxis</a></li>
          <li><a href="assets/download/Patienteninfoblatt">Patienteninfoblatt</a></li>
        </ul>
      </div>

    </div>

  </div>
</div>


