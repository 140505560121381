import {Injectable} from '@angular/core';
import {TeamMember} from '../models/team.member';
import {PraxisSelection, sortByPraxis} from './app.service';


const teamDb: TeamMember[] = [
  {
    images: [
      {
        src: 'assets/img/new-team/kristin/kristin_1.jpg',
        alt: 'Kristin Martin'
      },
      {
        src: 'assets/img/new-team/kristin/kristin_freizeit.jpg',
        alt: 'Kristin Martin'
      }
    ],
    name: 'Kristin Martin',
    graduation: 'MSc. Management (2020)',
    special: 'Management, Verwaltung',
    description: '<p>\n' +
      '          <strong>Verwaltung</strong><br>\n' +
      '          Kristin ist als Geschäftsführerin der beiden Praxen tätig. Sie übernimmt die Organisation und Koordination der Teams und leitet die Umsetzung verschiedener Projekte zur Geschäftsentwicklung. Dabei lautet das Ziel stets, den Praxisalltag zu verbessern und die Patientenzufriedenheit zu steigern.<br>\n' +
      '          Schon seit sie denken kann, spielt Kristin Handball und hat diese Leidenschaft viele Jahre als Jugendtrainerin weitergegeben. Für Kristin spielt der Verein eine große Rolle in ihrem Leben und sie schätzt besonders die Gemeinschaft, die durch den Teamsport entsteht.\n' +
      '        </p>\n' +
      '        <p>\n' +
      '          Hobbies: Handball, Welt entdecken<br>\n' +
      '        </p>',
    pageIndex: [
      {
        sortIndex: 2,
        praxis: PraxisSelection.Kurasan2
      }
    ]
  },

  {
    images: [
      {
        src: 'assets/img/new-team/bene/bene_1.jpg',
        alt: 'Benedikt Hettich'
      },
      {
        src: 'assets/img/new-team/bene/bene_handball.jpg',
        alt: 'Benedikt Hettich'
      },
      {
        src: 'assets/img/new-team/bene/bene_patient.png',
        alt: 'Benedikt Hettich'
      },
    ],
    name: 'Benedikt Hettich',
    graduation: 'Physiotherapeut (2013)',
    special: 'CMD/Kieferbehandlung, Sportphysiotherapie, Osteopathie, Knieverletzungen und Taping',
    description:
      '      <p>\n' +
      '        <strong>Chef und Praxisinhaber</strong><br>\n' +
      '        Im Jahr 2017 hat Benedikt die Praxis Kurasan Karlsruhe eröffnet und sich damit seinen Traum erfüllt. Er bringt viele Jahre Berufserfahrung mit und verfolgt stets einen ganzheitlichen Therapieansatz.\n' +
      '        Neben seiner Leidenschaft für sein Hobby Handball, welches er als Spieler und Trainer verfolgt, nimmt er gerne auch seine Gitarre in die Hand und unterhält alle mit selbst geschriebenen Songs.\n' +
      '      </p>\n' +
      '      <p>\n' +
      '        Hobbies: Handball, Musik <br>\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 1,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 1,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 1,
        praxis: PraxisSelection.Waldstadt
      }
    ]
  },
  {
    images: [
      {
        src: 'assets/img/new-team/sophie/D147C7F8-7B9B-43C6-BE7C-918CB1AA2F28.JPG',
        alt: 'Sophie Lutz'
      },
      {
        src: 'assets/img/new-team/sophie/DF0_4160.jpg',
        alt: 'Sophie Lutz'
      },
      {
        src: 'assets/img/new-team/sophie/sophie_1.jpg',
        alt: 'Sophie Lutz'
      },
    ],
    name: 'Sophie Lutz',
    graduation: 'Physiotherapeutin (2019), B.Sc Physiotherapie (2020)',
    special: 'CMD/Kieferbehandlung, Trainingstherapie, LWS und Sprunggelenk',
    description: '<p>\n' +
      '          <strong>Therapeutin </strong><br>\n' +
      '          Die begeisterte Handballerin ist seit Oktober 2019 bei uns und unterstützt neben\n' +
      '          ihren therapeutischen Tätigkeiten Sportler bei der Verletzungsprophylaxe.\n' +
      '          In unseren Trainingsräumen betreut sie beispielsweise Sportmannschaften beim Athletiktraining.\n' +
      '          Bei Interesse gerne melden!<br>\n' +
      '          Wenn Sophie einmal keinen Sport macht, verbringt sie am liebsten Zeit mit ihren Freunden und ihrer Familie – und die freuen sich immer über ihre selbst gebackenen Brownies!\n' +
      '        </p>\n' +
      '        <p>\n' +
      '          Hobbies: Handball, Reisen<br>\n' +
      '        </p>',
    pageIndex: [
      {
        sortIndex: 30,
        praxis: PraxisSelection.Kurasan1
      },
    ]
  },
  {
    images: [
      {
        src: 'assets/img/new-team/maren/IMG_8493.jpg',
        alt: 'Maren Ros'
      },
      {
        src: 'assets/img/new-team/maren/maren_4.jpg',
        alt: 'Maren Ros'
      },
      {
        src: 'assets/img/new-team/maren/maren_tennis.jpeg',
        alt: 'Maren Ros'
      },
    ],
    name: 'Maren Ros',
    graduation: 'Physiotherapeutin (2019)',
    special: 'CMD/Kieferbehandlung, Lymphdrainage, Knie- und Sprunggelenksverletzungen',
    description: '<p>\n' +
      '          <strong>Therapeutin </strong><br>\n' +
      '          Seit Dezember 2019 gehört Maren fest zu unserem Team der Praxis Kurasan 2.0. Für Maren war der Sport schon immer ein großer Teil ihres Lebens.<br>\n' +
      '          Schon früh startete sie erfolgreich mit Leichtathletik bei der LG-Region Karlsruhe. Mittlerweile spielt sie leidenschaftlich Tennis.<br>\n' +
      '          Die Erfahrungen, die sie im Sport sammelt, baut sie auch gerne in ihre Therapie ein. Nach der Arbeit trifft sich Maren gerne zu gemeinsamen Kochabenden mit Freunden.\n' +
      '        </p>\n' +
      '              <p>\n' +
      '                Hobbies: Tennis, Kochen und Klavier<br>\n' +
      '              </p>',
    pageIndex: [
      {
        sortIndex: 4,
        praxis: PraxisSelection.Kurasan2
      },
    ]
  },
  {
    images: [
      {
        src: 'assets/img/new-team/marion/DF0_3497.jpg',
        alt: 'Marion Brand'
      },
      {
        src: 'assets/img/new-team/marion/DF0_3558.jpg',
        alt: 'Marion Brand'
      },
    ],
    name: 'Marion Brand',
    graduation: 'Sport- und Gymnastiklehrerin mit Schwerpunkt Bewegungs- und Sporttherapie (1988), Krankengymnastin (1990)',
    special: 'Mobile KG, Manuelle Lymphdrainage, Rückenschule',
    description: '<p>\n' +
      '        <strong>Hausbesuche</strong><br>\n' +
      '        Einen Großteil der Hausbesuche übernimmt Marion für uns.<br>\n' +
      '        Sie verfügt über viel Erfahrung und wird von ihren Kollegen und Patienten vor allem\n' +
      '        für ihre empathische Art geschätzt.<br>\n' +
      '        Bei Wind und Wetter fährt sie mit dem Fahrrad für die Therapie zu euch nach Hause.\n' +
      '      </p>\n' +
      '      <p>\n' +
      '        Hobbies: Alles, was mit Bewegung in der Natur zu tun hat, wie Wandern, Langlaufen, Radeln, Laufen, Skifahren\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 70,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 8,
        praxis: PraxisSelection.Kurasan2
      },
    ]
  },
  {
    images: [
      {
        src: 'assets/img/new-team/timo/timo_1.jpg',
        alt: 'Timo Stahl'
      },
      {
        src: 'assets/img/new-team/timo/timo_klettern.jpg',
        alt: 'Timo Stahl'
      },
    ],
    name: 'Timo Stahl',
    graduation: 'Augenoptikermeister',
    special: 'Fotografie, Rezeption',
    description: '<p>\n' +
      '          <strong>Verwaltung</strong><br>\n' +
      '          Timo ist unser Ansprechpartner, wenn es um die räumliche Gestaltung der Praxis und die fotografische Umsetzung von Projekten, wie beispielsweise unsere Website und Flyer, geht. Man findet ihn aber auch an der Anmeldung oder beim Erledigen von allgemeinen Verwaltungsaufgaben.\n' +
      '          <br><br>Die Fotos, die unsere Praxiswände schmücken, hat alle Timo gemacht. Viele Monate ist er um die Welt gereist und hat ganz besondere Momente mit seiner Kamera festgehalten. Nach seiner Zeit in Singapur, Indonesien, Thailand oder Australien ging es Anfang 2020 wieder zurück nach Karlsruhe und damit auch hier her zu uns.\n' +
      '        </p>\n' +
      '        <p>\n' +
      '          Hobbies: Handball, Fotografie\n' +
      '        </p>',
    pageIndex: [
      {
        sortIndex: 50,
        praxis: PraxisSelection.Kurasan2
      },
    ]
  },
  {
    images: [
      {
        src: 'assets/img/new-team/leo/leo_3.jpg',
        alt: 'Leonard Paha'
      },
    ],
    name: 'Leonard Paha',
    graduation: 'Dualer Student BWL - Gesundheitsmanagement',
    special: 'Praxismanagement, Rezeption, Koordination der Hausbesuche',
    description: '<p>\n' +
      '        <strong>Verwaltung </strong><br>\n' +
      '        Unser Mitarbeiter Leo absolviert bei uns sein duales Studium der Betriebswirtschaftslehre\n' +
      '        mit dem Schwerpunkt Gesundheitsmanagement.<br>\n' +
      '        Neben den zu erledigenden Verwaltungstätigkeiten koordiniert\n' +
      '        er unter anderem auch die Hausbesuche und kümmert sich um unseren Instagram Kanal\n' +
      '        <a href="https://www.instagram.com/kurasankarlsruhe" target="_blank"><span class="fab fa-1x5x fa-instagram"></span></a>\n' +
      '        . Auch Leo spielt schon seit Jahren Handball im Verein und trainiert mehrmals die Woche.\n' +
      '      </p>\n' +
      '      <p>\n' +
      '        Hobbies: Handball, Wandern, Ski fahren<br>\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 6,
        praxis: PraxisSelection.Kurasan2
      },
    ]
  },
  {
    images: [
      {
        src: 'assets/img/new-team/julia/DF0_3626.jpg',
        alt: 'Julia Martin'
      },
      {
        src: 'assets/img/new-team/ws/Julia 2_LIEBLINGSBILD.jpg',
        alt: 'Julia Martin'
      },
      {
        src: 'assets/img/new-team/julia/screenshot_20200318_201234.jpg',
        alt: 'Julia Martin'
      },
    ],
    name: 'Julia Martin',
    graduation: 'Sport- und Gymnastiklehrerin (2016), Physiotherapeutin (2018), Studentin Bachelor in Präventions-, Therapie- und Rehabilitationswissenschaften',
    special: 'Rezeptabrechnung, Rezeption',
    description: '<p>\n' +
      '          <strong>Verwaltung</strong><br>\n' +
      '          Julia ist von der Therapieseite in die Verwaltung gewechselt. Sie sorgt vor allem dafür, dass alle Rezepte richtig geprüft und abgerechnet werden. Darüber hinaus hat unser Sonnenschein im Team auf jede Frage eine passende Antwort.' +
      '          <br><br>Schon von Kindesbeinen an spielt Julia Handball und engagiert sich in ihrem Heimatverein. Bei Gelegenheit tauscht sie aber auch den Handball in ein Mikrofon, denn dank ihrer tollen Stimme hat sie schon einige Konzerte gegeben.\n' +
      '        </p>\n' +
      '        <p>\n' +
      '          Hobbies: Wandern, Reisen, Handball, Singen\n' +
      '        </p>',
    pageIndex: [
      {
        sortIndex: 7,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 300,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    images: [
      {
        src: 'assets/img/new-team/jasmin/Jasmin.jpg',
        alt: 'Jasmin Jakob'
      }
    ],
    name: 'Jasmin Jakob',
    graduation: 'Physiotherapeutin (2013), Sportphysiotherapeutin (2021)',
    special: 'Hüfte, Knie, Sprunggelenk, Schulter',
    description: 'Jasmin und Praxisinhaber Benedikt kennen sich schon seit ihrem gemeinsamen Abschluss im Jahr 2013. Seitdem hat Jasmin beruflich viel Erfahrung sammeln und verschiedene Fortbildungen abschließen können. Somit bringt sie viel Wissen und Können mit in unser Waldstadt-Team und übernimmt dort die fachliche Leitung. Sport spielt nicht nur in Jasmins Beruf, sondern auch in ihrer Freizeit eine große Rolle. Neben dem Handball- und Fußballtraining tanzt sie Garde und trainiert dort auch den Nachwuchs.' +
      '      <p><br>' +
      '        Hobbies: Handball, Fussball, Gardetanz<br>\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 15,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },

  {
    images: [
      {
        src: 'assets/img/new-team/tina/Tina.jpg',
        alt: 'Tina Maier'
      }
    ],
    name: 'Tina Maier',
    graduation: 'Ergotherapeutin (2001)',
    special: 'Body Brain Activity bei ADHS, ADS, Konzentrationsproblemen, Legasthenie, Entwicklungsverzögerungen, Kinder mit Autismus-Spektrum-Störungen, Entspannung, fit in der Schwangerschaft, fit mit Baby,',
    description: 'Als erste Ergotherapeutin im Kurasan Team bringt Tina viel Erfahrung auf einem neuen Therapiegebiet mit. Im Mittelpunkt ihrer Arbeit stehen dabei immer die Menschen. Mit ihnen hat Tina das gemeinsame Ziel, in allen Lebensbereichen wieder die größtmögliche Selbstständigkeit zu erreichen. Dabei holt sie ihre Patienten mit ihrer kreativen und humorvollen Art dort ab, wo sie stehen und begleitet sie auf deren Weg. Spezialisiert ist Tina, unter anderem durch ihre Fortbildungen, vor allem auf Kinder und arbeitet somit auch im Kurasan-Kids Team mit.' +
      '      <p><br>' +
      '        Hobbies: Quatsch mit dem eigenen Kind machen, Afro-Salsa tanzen,Wandern mit Freunden, in der Erde wühlen im eigenen Garten<br>\n' +
      '      </p>' +
      '<p>Ergotherapeutin 2001 <br>' +
      'Taekima Kids Trainerin 2015 <br>' +
      'Body Brain Activity Trainerin 2021<br>' +
      'Übungsleiterin Eltern & Kind Turnen</p>',
    pageIndex: [
      {
        sortIndex: 40,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },
  {
    images: [
      {
        src: 'assets/img/new-team/ws/Tamara_1.jpg',
        alt: 'Tamara'
      }
    ],
    name: 'Tamara',
    graduation: 'Physiotherapeutin (1995), Vojta-Kinder Ausbildung (2002',
    special: 'Body Brain Activity bei ADHS, ADS, Konzentrationsproblemen, Legasthenie, Entwicklungsverzögerungen, Kinder mit Autismus-Spektrum-Störungen, Entspannung, fit in der Schwangerschaft, fit mit Baby,',
    description: 'Tamara ist ebenfalls seit 2021 Teil des Kurasan-Teams. Durch ihren Abschluss als Physiotherapeutin im Jahr 1995 können wir von ihrem großen Erfahrungsschatz nur profitieren. Im Jahr 2002 legte sie zusätzlich noch eine Vojta Kinder Ausbildung ab. Somit kann sie auch unsere kleinsten Patient*innen professionell und doch kindgerecht betreuen.' +
      '      <p><br>' +
      '        Hobbies: Reisen, Golf<br>\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 30,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },

  // {
  //   images: [
  //     {
  //       src: 'assets/img/new-team/hannah/hannah.jpg',
  //       alt: 'Hannah Bofinger'
  //     },
  //     {
  //       src: 'assets/img/new-team/hannah/3572CBA0-C99E-4E20-BAB9-7349CBD74E2A.jpeg',
  //       alt: 'Hannah Bofinger'
  //     }
  //   ],
  //   name: 'Hannah Bofinger',
  //   graduation: 'Logopädin',
  //   pageIndex: [
  //     {
  //       sortIndex: 3,
  //       praxis: PraxisSelection.Waldstadt
  //     },
  //   ],
  //   description: 'Hannah hat in diesem Jahr ihre Ausbildung zur Logopädin beendet. Sie eröffnet bei uns den logopädischen Bereich in der neuen Praxis. Sie liebt die interdisziplinäre Arbeit mit Patient*innen und freut sich auf neue Herausforderungen!  \n' +
  //     '\n' +
  //     'Ihre Freizeit verbringt Hannah am liebsten in der Natur beim Ski fahren. '
  // },


  {
    images: [
      {
        src: 'assets/img/new-team/timo_waldstadt/Timo.jpg',
        alt: 'Timo Bäuerlein'
      }
    ],
    name: 'Timo Bäuerlein ',
    graduation: 'Bachelor Sportwissenschaft (2017)',
    description: 'Timo ist seit Oktober 2021 zuständig für die Verwaltung unserer Praxis in der Waldstadt. Er kennt die Praxis schon von Beginn an, da seine Frau Lea von Sekunde Eins ein Teil des Verwaltungsteams war. Timo hat mit seinem Abschluss als Sportwissenschaftler die perfekten Voraussetzungen und hat beruflich schon zahlreiche Sportveranstaltungen organisiert. Er vereint somit sportliches Know-How mit seinem Organisationstalent. Seine Freizeit verbringt er am liebsten mit seiner kleinen Familie und steht sonst, wie viele der Kurasan-Mitarbeiter, auf dem Handballfeld.' +
      '      <p><br>' +
      '        Hobbies:  Familienzeit, Handball, Reisen, Essen und Kochen<br>\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 50,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },

  {
    images: [
      {
        src: 'assets/img/new-team/ws/Tim.jpg',
        alt: 'Tim'
      }
    ],
    name: 'Tim',
    graduation: 'Physiotherapeut (2021)',
    description: 'Tim ist seit November 2021 Teil des Kurasan-Waldstadt Teams. Seine motivierte und vom Beruf begeisterte Art steckt immer wieder das ganze Team an. Unseren Patient*innen begegnet er immer mit einem Lächeln im Gesicht. Während seiner Ausbildung an der SGKA hatte Tim bereits die Möglichkeit eine Jugendmannschaft des Karlsruher SC physiotherapeutisch zu betreuen. Diese Erfahrungen in der Sportphysiotherapie setzt Tim nun im beruflichen Alltag mit seinen Patient*innen um.' +
      '      <p><br>' +
      '        Hobbies:  Volleyball spielen, schwimmen, Rad fahren <br>\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 18,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },

  {
    images: [
      {
        src: 'assets/img/new-team/ws/laura_1.jpg',
        alt: 'Laura'
      }
    ],
    name: 'Laura',
    graduation: 'Physiotherapeutin (2022)',
    description: 'Laura ist seit 2022 Teil des Kurasan-Waldstadt Teams. Sie war Jahrgangsbeste bei Ihrem Abschluss an der SRH-Fachschule für Physiotherapie.' +
      '      <p><br>' +
      '        Hobbies:  Tischtennis spielen<br>\n' +
      '      </p>',
    pageIndex: [
      {
        sortIndex: 19,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },


];


@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor() {
  }

  public loadTeamMember(selection: PraxisSelection): TeamMember[] {
    return teamDb.filter(x => x.pageIndex.find(c => c.praxis === selection) != null).sort((x, y) => sortByPraxis(x, y, selection));
  }

}
