
<div class="container">
  <div class="row">
    <div class="col-12">
      <h2>Galerie</h2>
    </div>
  </div><div class="row mb-3">
    <div class="col-12">
      <ng-container *ngIf="(appService.currentPraxis|async) === appService.PraxisSelection.Kurasan1">
        <gallery
          galleryName="kurasan_1"
          [flexBorderSize]="10"
          [flexImageSize]="20">
        </gallery>
      </ng-container>
      <ng-container *ngIf="(appService.currentPraxis|async) === appService.PraxisSelection.Kurasan2">
        <gallery
          galleryName="kurasan_2"
          [flexBorderSize]="10"
          [flexImageSize]="20">
        </gallery>
      </ng-container>

    </div>
  </div>

</div>
