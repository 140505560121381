import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {ImpressumComponent} from './impressum/impressum.component';
import {StartComponent} from './start/start.component';
import {GalerieComponent} from './galerie/galerie.component';
import {KGGComponent} from './therapiespektrum/kgg/kgg.component';
import {ManuelleLymphdrainageComponent} from './therapiespektrum/manuelleLymphdrainage/manuelleLymphdrainage.component';
import {ManuelleTherapieComponent} from './therapiespektrum/manuelleTherapie/manuelleTherapie.component';
import {PhysikalischeTherapieComponent} from './therapiespektrum/physikalischeTherapie/physikalischeTherapie.component';
import {PhysiotherapieComponent} from './therapiespektrum/physiotherapie/physiotherapie.component';
import {TapingComponent} from './therapiespektrum/taping/taping.component';
import {GesundheitskurseComponent} from './trainingUndKurse/gesundheitskurse/gesundheitskurse.component';
import {MitgliedschaftComponent} from './trainingUndKurse/mitgliedschaft/mitgliedschaft.component';
import {Angular2ImageGalleryModule} from 'angular2-image-gallery';
import {BhvKooperationComponent} from './therapiespektrum/bhv-kooperation/bhv-kooperation.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {LandingComponent} from '../components/landing/landing.component';
import {TeamsComponent} from './teams/teams.component';
import {NgbCarouselModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FaqComponent} from './faq/faq.component';
import {BetrieblicheGesundheitComponent} from './therapiespektrum/betriebliche-gesundheit/betriebliche-gesundheit.component';
import {StellenausschreibungComponent} from './stellenausschreibung/stellenausschreibung.component';
import {SpeechEventBubbleComponent} from '../components/speech-event-bubble/speech-event-bubble.component';
import {TerminTheorgComponent} from './termin-theorg/termin-theorg.component';
import {PhoneComponent} from '../components/phone/phone.component';
import {EmailComponent} from '../components/email/email.component';
import {WhatsappComponent} from '../components/whatsapp/whatsapp.component';
import {FacebookComponent} from '../components/facebook/facebook.component';
import {InstagramComponent} from '../components/instagram/instagram.component';
import {FaxComponent} from '../components/fax/fax.component';
import {AddressLineComponent} from '../components/address-line/address-line.component';
import {CmDysfunktionComponent} from './therapiespektrum/cm-dysfunktion/cm-dysfunktion.component';
import {PartnerComponent} from './partner/partner.component';
import {TeamMemberComponent} from './teams/team-member/team-member.component';
import {BobathComponent} from './therapiespektrum/bobath/bobath.component';
import {LogopaedieComponent} from './logopaedie/logopaedie.component';
import {ErgotherapieComponent} from './therapiespektrum/ergotherapie/ergotherapie.component';
import {SafePipe} from '../components/safe.pipe';
import {YoutubeWrapperComponent} from '../components/youtube-wrapper/youtube-wrapper.component';
import { StellenauschreibungWaldstadtComponent } from './stellenauschreibung-waldstadt/stellenauschreibung-waldstadt.component';
import { CovidHintComponent } from './covid-hint/covid-hint.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import { StellenauschreibungPhysioTheraComponent } from './stellenauschreibung-physio-thera/stellenauschreibung-physio-thera.component';

const innerRoutes: Routes = [
  {
    path: 'physiotherapie',
    component: PhysiotherapieComponent,
    data: {title: 'Physiotherapie'}
  },
  {
    path: 'manuelle-therapie',
    component: ManuelleTherapieComponent,
    data: {title: 'Manuelle Therapie'}
  },
  {
    path: 'manuelle-lymphdrainage',
    component: ManuelleLymphdrainageComponent,
    data: {title: 'Manuelle Lymphdrainage'}
  },
  {
    path: 'taping',
    component: TapingComponent,
    data: {title: 'Taping'}
  },
  {
    path: 'bobath',
    component: BobathComponent,
    data: {title: 'Bobath'}
  },
  {
    path: 'bhvKooperation',
    component: BhvKooperationComponent,
    data: {title: 'BHV Kooperation'}
  },
  {
    path: 'galerie',
    component: GalerieComponent,
    data: {title: 'Galerie'}
  },
  {
    path: 'kontakt',
    component: KontaktComponent,
    data: {title: 'Kontakt'}
  },
  {
    path: 'kgg',
    component: KGGComponent,
    data: {title: 'KGG'}
  },
  {
    path: 'ergotherapie',
    component: ErgotherapieComponent,
    data: {title: 'Ergotherapie'}
  },
  {
    path: 'team',
    component: TeamsComponent,
    data: {title: 'Unser Team'}
  },
  {
    path: 'physikalische-therapie',
    component: PhysikalischeTherapieComponent,
    data: {title: 'Physikalische Therapie'}
  },
  {
    path: 'gesundheitskurse',
    component: GesundheitskurseComponent,
    data: {title: 'Gesundheitskurse'}
  },
  {
    path: 'mitgliedschaft',
    component: MitgliedschaftComponent,
    data: {title: 'Mitgliedschaft'}
  },
  {
    path: 'start',
    component: StartComponent,
    data: {title: 'Start'}
  },
  {
    path: 'termin',
    component: TerminTheorgComponent,
    data: {title: 'Termin'}
  },
  {
    path: 'partner',
    component: PartnerComponent,
    data: {title: 'Partner'}
  },
  {
    path: 'stellenausschreibung',
    component: StellenausschreibungComponent,
    data: {title: 'Physiotherapeuten (m/w/d) für Hausbesuche'}
  },
  {
    path: 'stellenausschreibung-waldstadt',
    component: StellenauschreibungWaldstadtComponent,
    data: {title: 'Logopäd:in gesucht'}
  },
  {
    path: 'stellenausschreibung-physio',
    component: StellenauschreibungPhysioTheraComponent,
    data: {title: 'Physiotherapeut*in gesucht'}
  },
  {
    path: 'betriebliche-gesundheitsfoerderung',
    component: BetrieblicheGesundheitComponent,
    data: {title: 'Betriebliche Gesundheitsförderung'}
  },
  {
    path: 'cmd',
    component: CmDysfunktionComponent,
    data: {title: 'Craniomandibuläre Dysfunktion (CMD)'}
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {title: 'Fragen und Antworten'}
  },
  {
    path: 'logopaedie',
    redirectTo: 'start'
  },
];

const routes: Routes = [
  {
    path: 'start',
    component: LandingComponent,
    data: {title: 'Start'}
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
    data: {title: 'Impressum'}
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {title: 'Fragen und Antworten'}
  },
  {
    path: 'karlstrasse49a',
    children: innerRoutes,
  },
  {
    path: 'kriegsstrasse140',
    children: innerRoutes,
  },
  {
    path: 'ettlingen',
    children: innerRoutes,
  },
  {
    path: 'waldstadt',
    children: innerRoutes,
  },
  {
    path: 'knielingen',
    children: innerRoutes,
  },
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    CommonModule,
    Angular2ImageGalleryModule,
    RouterModule.forChild(routes),
    NgbModule,
    NgbCarouselModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    RouterModule,
    PhoneComponent,
    EmailComponent,
    WhatsappComponent,
    FacebookComponent,
    InstagramComponent,
    FaxComponent,
    AddressLineComponent
  ],
  declarations: [
    ImpressumComponent,
    StartComponent,
    GalerieComponent,
    KGGComponent,
    ManuelleTherapieComponent,
    ManuelleLymphdrainageComponent,
    PhysikalischeTherapieComponent,
    PhysiotherapieComponent,
    TapingComponent,
    GesundheitskurseComponent,
    MitgliedschaftComponent,
    KontaktComponent,
    BhvKooperationComponent,
    TeamsComponent,
    FaqComponent,
    BetrieblicheGesundheitComponent,
    StellenausschreibungComponent,
    SpeechEventBubbleComponent,
    TerminTheorgComponent,
    PhoneComponent,
    EmailComponent,
    WhatsappComponent,
    FacebookComponent,
    InstagramComponent,
    FaxComponent,
    AddressLineComponent,
    CmDysfunktionComponent,
    PartnerComponent,
    TeamMemberComponent,
    BobathComponent,
    LogopaedieComponent,
    ErgotherapieComponent,
    SafePipe,
    YoutubeWrapperComponent,
    StellenauschreibungWaldstadtComponent,
    CovidHintComponent,
    StellenauschreibungPhysioTheraComponent
  ]
})
export class ViewsModule {
}
