import {Component, Input, OnInit} from '@angular/core';
import {TeamMember} from '../../../models/team.member';
import {PraxisSelection} from '../../../services/app.service';

@Component({
  selector: 'app-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent implements OnInit {

  @Input()
  public isDark = false;

  @Input()
  public member: TeamMember = {
    images: [
      {
        src: 'assets/img/new-team/kristin/kristin_1.jpg',
        alt: 'Kristin Martin'
      },
      {
        src: 'assets/img/new-team/kristin/kristin_freizeit.jpg',
        alt: 'Kristin Martin'
      }
    ],
    name: 'Kristin Martin',
    graduation: 'MSc. Management (2020)',
    special: 'Management, Verwaltung',
    description: '<p>\n' +
      '          <strong>Verwaltung</strong><br>\n' +
      '          Kristin ist als Geschäftsführerin der beiden Praxen tätig. Sie übernimmt die Organisation und Koordination der Teams und leitet die Umsetzung verschiedener Projekte zur Geschäftsentwicklung. Dabei lautet das Ziel stets, den Praxisalltag zu verbessern und die Patientenzufriedenheit zu steigern.<br>\n' +
      '          Schon seit sie denken kann, spielt Kristin Handball und hat diese Leidenschaft viele Jahre als Jugendtrainerin weitergegeben. Für Kristin spielt der Verein eine große Rolle in ihrem Leben und sie schätzt besonders die Gemeinschaft, die durch den Teamsport entsteht.\n' +
      '        </p>\n' +
      '        <p>\n' +
      '          Hobbies: Handball, Welt entdecken<br>\n' +
      '        </p>',
    pageIndex: [
      {
        sortIndex: 2,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 2,
        praxis: PraxisSelection.Kurasan2
      }
    ]
  } as TeamMember;

  constructor() {
  }

  ngOnInit(): void {
  }

}


