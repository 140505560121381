<ng-container *ngIf="appService.praxisSettings|async as praxis">


  <div class="p-3 p-lg-4 clearfix mb-5">
    <div class="container content">
      <hr class="hr-lg mt-0 mb-2 w-10 mx-auto hr-primary">
      <h2 class="display-4 text-slab text-uppercase mt-0 primary-color text-center"
          style="white-space: normal; word-break: break-word">
        Partner
      </h2>
    </div>
  </div>


  <div class="container content mb-5">

    <div class="row no-gutters">

      <div class="col-12 d-flex justify-content-center pos-relative">
        <p>
          <strong class="text-blue-dark">Wir arbeiten zusammen mit:</strong>
        </p>
      </div>
    </div>

    <div class="row no-gutters mt-4">
      <!--BNI-->
      <div class="col-12 col-md-4 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">BNI Südwest</strong>
        <a href="https://bni-suedwest.de/de/index" target="_blank" class="text-center">
          <img src="assets/partner/BNI_logo_Red_RGB-620x238.png" alt="Logo BNI Südwest"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>

      <!--IFK-->
      <div class="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">IFK e.V.</strong>
        <a href="https://ifk.de" target="_blank" class="text-center">
          <img src="assets/partner/ifkLogo.png" alt="Logo IFK e.V." style="max-width: 100%; max-height: 145px">
        </a>
      </div>

      <!--Consiliari-->
      <div class="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">Consiliari GmbH</strong>
        <a href="https://www.consiliari.de" target="_blank" class="text-center">
          <img src="assets/partner/Consiliari_Logo_1x1.png" alt="Logo Consiliari GmbH"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>
    </div>

    <div class="row no-gutters mt-5">
      <!--BHV-->
      <div class="col-12 col-md-4 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">Badischer Handball-Verband</strong>
        <a href="https://www.badischer-hv.de/bhv" target="_blank" class="text-center">
          <img src="assets/partner/BHV_Logo.jpg" alt="Logo Badischer Handball-Verband"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>
<!--
      &lt;!&ndash;Ergo KA&ndash;&gt;
      <div class="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">Ergotherapie Karlsruhe</strong>
        <a href="https://www.ergotherapie-karlsruhe.info" target="_blank" class="text-center">
          <img src="assets/partner/logo_ergo_ka_schindler.png" alt="Logo Ergotherapie Karlsruhe"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>-->

      <!--Lymphnetz KA-->
      <div class="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column justify-content-center pos-relative" *ngIf="false">

        <strong class="text-center mb-2">Lymphnetz Karlsruhe</strong>
        <a href="https://lymphnetz-karlsruhe.de" target="_blank" class="text-center">
          <img src="assets/partner/Logo_Lymphnetz_Karlsruhe.png" alt="Logo Lymphnetz Karlsruhe"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>


      <!--Kurpfalzbären-->
      <div class="col-12 col-md-4 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">Kurpfalzbären</strong>
        <a href="https://kurpfalzbaeren.de/index.php/de/" target="_blank" class="text-center">
          <img src="assets/partner/kurpfalzbaren.png" alt="Logo Kurpfalzbären"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>

      <!--Wortspiel KA-->
      <div class="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">SRH Fachschulen</strong>
        <a href="https://www.die-fachschulen.de" target="_blank" class="text-center">
          <img src="assets/partner/srh-karlsruhe.jpg" alt="SRH Fachschulen Karlsruhe Karlsruhe"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>

    </div>

    <div class="row no-gutters mt-5">

<!--
      &lt;!&ndash;Wortspiel KA&ndash;&gt;
      <div class="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">Logopädiepraxis <br> Wortspiel Karlsruhe</strong>
        <a href="https://wortspiel-karlsruhe.de" target="_blank" class="text-center">
          <img src="assets/partner/logo_wortspiel.jpg" alt="Logopädiepraxis Wortspiel Karlsruhe"
               style="max-width: 100%; max-height: 145px">
        </a>
      </div>
-->



      <!--Kurpfalzbären-->
      <div class="col-12 col-md-4 d-flex flex-column justify-content-center pos-relative">

        <strong class="text-center mb-2">Gesundheits Ticket</strong>
        <a href="https://www.gesundheitsticket.de" title="GesundheitsTicket" target="_blank"><img src="https://www.gesundheitsticket.de/downloads/downloads/medium/logo-gesundheitsticket.jpg" alt="GesundheitsTicket"/></a>
      </div>

    </div>
  </div>

  <div class="blaue_box bg-inverse text-white p-3 p-lg-4  divider-inverse blau_box clearfix pb-5">
    <div class="container content">
      <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
      <div class="d-flex flex-wrap align-items-center mb-5">
        <img class="card-img-top img-fluid w-10 m-1" src="assets/partner/kurpfalzbaren.png" alt="Card image cap">
        <h2 class="display-4 text-slab text-white text-uppercase mt-0 w-85"
            style="white-space: normal; word-break: break-word">
          Die Kurpfalzbären und Kurasan -<br>
          Ein starkes Team
        </h2>
      </div>
    </div>
  </div>

  <div class="pt-5 blau_box blaue_box bg-inverse ">
    <div class="container content ">

      <div class="row pb-5">
        <div class="col-12">
          <p>Seit Frühjahr 2021 sind wir offizieller Fitness- & Gesundheitspartner der Kurpfalz Bären. Die Verbindung zu
            den Kurpfalz Bären besteht seit Jahren durch Benedikt Hettich, der selbst Trainer der Nachwuchsabteilung in
            Ketsch war. Von seinen Schützlingen haben es auch einige in die Bundesliga geschafft! Durch diese
            Kooperation haben die Spielerinnen der Bären aus dem Raum Karlsruhe die Möglichkeit, sich bei uns behandeln
            zu lassen ohne dafür extra in die Enderle Gemeinde fahren zu müssen. Auch unsere Trainingsräume stehen den
            Spielerinnen für Rehazwecke zur Verfügung und unsere Therapeut*innen stehen gerne mit fachlicher Beratung
            zur Stelle. </p>
          <p>
            Die enge Zusammenarbeit zwischen Benedikt Hettich und Maximilian Tesche wird so für eine optimale, flexible
            Behandlung sorgen.
          </p>
        </div>
        <div class="col-12 position-relative">

          <app-youtube-wrapper yLink="https://www.youtube-nocookie.com/embed/TxDj6wSDECY">
            <p>Wir würden Ihnen hier gern unsere Zusammenarbeit mit den Kurpfalzbären mit einem Video untermalen. Dieses Video ist allerdings ein externer Inhalt auf youtube. Sie entscheiden, ob Sie dieses Video auch sehen wollen.</p>
          </app-youtube-wrapper>

        </div>
      </div>


    </div>
  </div>

  <div class="p-3 p-lg-4  divider-inverse clearfix mb-5">
    <div class="container content">
      <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
      <h2 class="display-4 text-slab text-uppercase mt-0 primary-color">
        Wir unterstützen Karlsruher Künstler
      </h2>
    </div>
  </div>

  <div class="container content mb-5">
    <div class="row mb-5">

      <div class="col-12">
        <p>
          Schon vor der Pandemie haben wir Künstler aus der Region unterstützt. Dies ist jetzt, wo viele Kunstschaffende
          durch die Corona-Pandemie hart getroffen wurden, umso wichtiger. Deshalb hängen wir in unseren Räumen Bilder
          von Karlsruher Künstlern aus, um ihnen mehr Reichweite zu bieten.
        </p>
      </div>
    </div>
  </div>

  <div class="bg-inverse blaue_box text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
    <div class="container content">
      <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
      <div class="d-flex flex-wrap align-items-center mb-5">
        <img class="card-img-top img-fluid w-10 m-1" src="assets/img/thea/BHV_Logo_4c.jpg" alt="Card image cap">
        <h2 class="pl-3 display-4 text-slab text-white text-uppercase mt-0 mb-0">
          Kooperation
        </h2>
      </div>
      <div class="row mb-5">
        <p class="text-grey">
          Als Partner des Badischen Handball-Verbandes bieten wir allen Handballer*innen der BHV-Vereine bei physiotherapeutischem Behandlungsbedarf eine bevorzugte Terminvergabe an.<br>
          Auch ein Großteil unserer Mitarbeiter ist aktiv in Handballvereinen des BHV tätig. Durch die Affinität zum Handballsport haben wir uns gemeinsam mit dem Badischen Handball-Verband das Ziel gesetzt, allen Handballern aus dem Gebiet des BHV eine zeitnahe physiotherapeutische Behandlung zu ermöglichen und so zu einer schnellen Genesung zu verhelfen.
        </p>
      </div>
      <div class="row">

        <div class="col-md-6 inline left">

          <img class="card-img-top img-fluid" src="assets/img/bhv_bear.jpg" alt="Card image cap">

        </div>

        <div class="col-md-6 inline">
          <div class="text-grey mb-3">
            <h4 class="font-weight-bold text-white text-uppercase mb-1">
              Vorgehen zur Terminvergabe bei schweren Verletzungen und Beschwerden für Handballer aus BHV-Vereinen:
            </h4>
            <p class="text-grey mb-3">
              Betroffene Spieler*innen melden sich bei der BHV-Geschäftsstelle per Mail an geschaeftsstelle@badischer-hv.de mit dem Betreff „Kurasan Karlsruhe Terminvergabe“ unter Angabe von:</p>
            <ul>
              <li>Vorname, Name</li>
              <li>Kontaktdaten (Telefon, Mail, Adresse)</li>
              <li>Art der Versicherung (privat, gesetzlich, BG)</li>
              <li>Diagnose</li>
              <li>Physiotherapeutische Verordnung (Anzahl der Behandlungen, Art der Behandlung, wöchentliche Frequenz)</li>
            </ul>
          </div>
          <p class="text-grey">Informationen über die Kooperation mit dem BHV erhalten Sie unter <a target="_blank" href="https://www.badischer-hv.de/verband/allgemeine-infos/bhv-partner/kurasan-karlsruhe-praxis">https://www.badischer-hv.de/verband/allgemeine-infos/bhv-partner/kurasan-karlsruhe-praxis</a>.</p>
        </div>
      </div>
    </div>
  </div>

</ng-container>
