<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="row">

      <div class="col-md-6 inline left d-flex justify-content-end">
        <img class="card-img-top img-fluid w-85 h-90" src="assets/img/thea/training_1.jpg" alt="Card image cap">
      </div>

      <div class="col-md-6 inline">

        <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
        <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
          KGG
        </h2>

        <h4 class="font-weight-bold text-white text-uppercase mb-1">
          Was ist Krankengymnastik am Gerät?
        </h4>
        <p class="text-grey mb-3">
          Krankengymnastik am Gerät, kurz KGG, oder Medizinische Trainingstherapie, kurz MTT, ist eine Therapiemethode bei der der Physiotherapeut mit den Patienten an verschiedenen Geräten trainiert. In der Praxis Kurasan steht den Patienten ein großzügiger Trainingsraum mit unterschiedlichen Geräten, wie unter anderem einem Ergometer, einem Laufband, einem Seilzug und verschiedenen Kraftstationen zur Verfügung.
          <br>Diese Art der Krankengymnastik dauert rund 40 Minuten und somit deutlich länger als die ohne Gerät.
        </p>

        <h4 class="font-weight-bold text-white text-uppercase mb-1">
          Was kann die Krankengymnastik am Gerät bewirken?
        </h4>
        <p class="text-grey mb-3">
          Die Krankengymnastik am Gerät wird vor allem bei muskulären Schwächen verschrieben, welche eine häufige Ursache für Schmerzen sind. Auch eine sogenannte Muskeldysbalance, also ein Ungleichgewicht der Muskulatur, können wir hier sehr gut behandeln. Oft wird die Krankengymnastik am Gerät auch nach Sportverletzungen und der langen Schonung eines Gelenkes notwendig, um die verkürzte und verkleinerte Muskulatur wieder in ihren ursprünglichen Zustand zu bringen.<br><br>
          Im Kurasan Karlsruhe versuchen wir, mit einer Kombination aus funktionellen Übungen und der Unterstützung von medizinisch zugelassenen Geräten, gegen Ihre Problematik vorzugehen.<br><br>
          Dabei bringen wir viel Erfahrung aus Fortbildungen und der Sportwissenschaft mit ein.
        </p>

      </div>
    </div>
  </div>
</div>





