<ng-container *ngIf="!(vidAccess|async) else vid">
  <div style="max-width: 560px;" class="position-relative flex-grow-1 h-100">
    <div style="background-color: #045a67" class="rounded p-3">
      <ng-content></ng-content>
      <p style="font-size: small">Ich bin hiermit einverstanden, das mir externe Inhalte angezeigt werden. Dadurch können personenbezogene Daten an Drittplattformen übermittelt werden. <a routerLink="/impressum"> Mehr dazu finden sie in unseren Datenschutzbestimmungen.</a></p>
      <div>
        <button type="button" class="btn btn-info" (click)="vidAccess.next(true)">
          <div class="d-flex align-items-center">
            <i class="fab fa-1x5x fa-youtube mr-2"></i>
            <span>Ja, bitte das Video von youtube anzeigen.</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #vid>
  <div style="max-width: 560px; height:315px" class="position-relative flex-grow-1">
    <iframe
      style="position: absolute; left:0; top:0; right:0; bottom:0; width: 100%"
      height="315"
      [src]="(yLink + '?autoplay=1') | safe:'resourceUrl'"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</ng-template>
