import {BrowserModule} from '@angular/platform-browser';
import {FactoryProvider, InjectionToken, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavigationComponent} from './navigation/navigation.component';
import {FooterComponent} from './footer/footer.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ViewsModule} from './views/views.module';
import {NgProgressModule} from 'ngx-progressbar';
import {PraxisSelectionComponent} from './components/praxis-selection/praxis-selection.component';
import {LandingComponent} from './components/landing/landing.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PraxisSelectionPipe} from './components/praxis-selection.pipe';
import { SafePipe } from './components/safe.pipe';
import { YoutubeWrapperComponent } from './components/youtube-wrapper/youtube-wrapper.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const WINDOW = new InjectionToken<Window>('window');

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

export const WINDOW_PROVIDERS = [
  windowProvider
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    PraxisSelectionComponent,
    LandingComponent,
    PraxisSelectionPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ViewsModule,
    NgProgressModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  providers: [
    WINDOW_PROVIDERS
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}


declare global {
  interface String {
    es5includes: (substring: string) => boolean;
  }
}

String.prototype.es5includes = function(this: string, substring: string) {
  const s = this;
  if (s.includes(substring) || s.indexOf(substring) !== -1) {
    return true;
  }
  return false;
};
