<img [src]="(appService.praxisSettings|async).teamImgUrl" style="width: 100%" alt="gesamtes Team">

<ng-container *ngIf="loaded|async">

  <app-team-member
    *ngFor="let member of (members|async); let i = index"
    [isDark]="(i%2) === 1"
    [member]="member"
  ></app-team-member>

</ng-container>
