import { Component, OnInit } from '@angular/core';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html'
})
export class ImpressumComponent implements OnInit {

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }

}
