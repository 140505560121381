<div class="pos-relative w-100 h-100" *ngIf="appService.praxisSettings|async as praxis">
  <img class="card-img-top img-fluid pos-absolute" src="../../../assets/img/speech_bubble_2.png" alt="Card image cap" style="bottom: 0px; right: 0px; left: 0; top: 0">
  <div class="d-flex flex-column  pos-absolute justify-content-center align-items-center" style="bottom: 30px; right: 15px; left: 15px; top: 0; color: #006878">
    Für Patienten mit akuten Beschwerden haben wir auch kurzfristig Termine frei
<!--    <br>-->
<!--    <div>-->
<!--      <whatsapp [phone]="praxis.whatsapp" [showWhatsapp]="false" color="primary-color"></whatsapp>-->
<!--      <phone [phone]="praxis.phone" [showPhoneNumber]="false" color="primary-color"></phone>-->
<!--    </div>-->

<!--    -->
<!--    <ng-container *ngIf="(appService.currentPraxis|async) === appService.PraxisSelection.Kurasan1">-->
<!--      <br>-->
<!--      <div>-->
<!--        <a href="https://wa.me/+491637155151"><span style="color: #006878" class="fa fa-fw fa-whatsapp"></span></a>-->
<!--        <a href="tel:+491637155151" style="color: #006878"><span class="fa fa-fw fa-phone"  style="color: #006878"></span> 0721-603 212 1</a>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="(appService.currentPraxis|async) === appService.PraxisSelection.Kurasan2">-->
<!--      <br>-->
<!--      <div>-->
<!--        <a href="https://wa.me/+491792960220"><span style="color: #006878" class="fa fa-fw fa-whatsapp"></span></a>-->
<!--        <a href="tel:+497216032123" style="color: #006878"><span class="fa fa-fw fa-phone"  style="color: #006878"></span> 0721-603 212 3</a>-->
<!--      </div>-->
<!--    </ng-container>-->
  </div>
</div>
