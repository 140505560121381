<div class="container">
  <div class="d-flex justify-content-center ">
    <div>
      <img class="card-img-top img-fluid" style="width: 600px;" src="assets/img/praxis-selection/kurasan_1.jpg" alt="Card image cap">
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="text-center blaue_box p-2" style="width: 500px; font-size: 1.3rem; margin-top: -50px">
      Physiotherapeut*in gesucht
    </div>
  </div>


  <div class="d-flex flex-column align-items-center mt-3">
    <div class="my-2 border rounded p-2">
      <a href="assets/download/Stellenanzeige Kurasan Physiotherapeut_in.png" target="_blank"><i class="mr-2 fas fa-file-pdf text-primary"></i>Flyer herunterladen</a>
    </div>

    <div class="d-flex flex-column" style="max-width: 800px">
      <div class="d-flex flex-column" >
        <strong class="">Wir bieten:</strong>
        <ul>
          <li>
            <span>Flexible Arbeitszeiten</span>
          </li>
          <li>
            <span>Voll- und Teilzeit möglich</span>
          </li>
          <li>
            <span>Sehr gutes Arbeitsklima</span>
          </li>
          <li>
            <span>Unterstützung bei Fortbildungen</span>
          </li>
          <li>
            <span>Sehr gute Bezahlung</span>
          </li>
          <li>
            <span>Freie Entfaltungsmöglichkeiten</span>
          </li>
        </ul>
      </div>
      <div class="d-flex flex-column" >
        <strong class="">Das wünschen wir uns:</strong>
        <ul>
          <li>
            <span>Abgeschlossene physiotherapeutische Ausbildung</span>
          </li>
          <li>
            <span>Professionelles und freundliches Auftreten</span>
          </li>
          <li>
            <span>Teamfähigkeit und soziale Kompetenz</span>
          </li>
          <li>
            <span>Kooperative Zusammenarbeit mit anderen Berufsgruppen</span>
          </li>
          <li>
            <span>Idealerweise ist bereits Berufserfahrung vorhanden</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <p class="text-center primary-color font-weight-bold">
      Wenn wir Ihr Interesse geweckt haben, dann melden Sie sich gerne unter,
      <phone [phone]="defaultPraxis.phone"></phone>
      <br>
      oder per Mail an
      <email [email]="defaultPraxis.email"></email>
      .<br>
    </p>
  </div>

</div>
