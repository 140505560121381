<div class="bg-inverse blaue_box text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
    <div class="d-flex flex-wrap align-items-center mb-5">
      <img class="card-img-top img-fluid w-10 m-1" src="assets/img/thea/BHV_Logo_4c.jpg" alt="Card image cap">
      <h2 class="pl-3 display-4 text-slab text-white text-uppercase mt-0 mb-0">
        Kooperation
      </h2>
    </div>
    <div class="row mb-5">
      <p class="text-grey">
        Als Partner des Badischen Handball-Verbandes bieten wir allen Handballer*innen der BHV-Vereine bei physiotherapeutischem Behandlungsbedarf eine bevorzugte Terminvergabe an.<br>
        Auch ein Großteil unserer Mitarbeiter ist aktiv in Handballvereinen des BHV tätig. Durch die Affinität zum Handballsport haben wir uns gemeinsam mit dem Badischen Handball-Verband das Ziel gesetzt, allen Handballern aus dem Gebiet des BHV eine zeitnahe physiotherapeutische Behandlung zu ermöglichen und so zu einer schnellen Genesung zu verhelfen.
      </p>
    </div>
    <div class="row">

      <div class="col-md-6 inline left">

        <img class="card-img-top img-fluid" src="assets/img/bhv_bear.jpg" alt="Card image cap">

      </div>

      <div class="col-md-6 inline">
        <div class="text-grey mb-3">
          <h4 class="font-weight-bold text-white text-uppercase mb-1">
            Vorgehen zur Terminvergabe bei schweren Verletzungen und Beschwerden für Handballer aus BHV-Vereinen:
          </h4>
          <p class="text-grey mb-3">
            Betroffene Spieler*innen melden sich bei der BHV-Geschäftsstelle per Mail an geschaeftsstelle@badischer-hv.de mit dem Betreff „Kurasan Karlsruhe Terminvergabe“ unter Angabe von:</p>
          <ul>
            <li>Vorname, Name</li>
            <li>Kontaktdaten (Telefon, Mail, Adresse)</li>
            <li>Art der Versicherung (privat, gesetzlich, BG)</li>
            <li>Diagnose</li>
            <li>Physiotherapeutische Verordnung (Anzahl der Behandlungen, Art der Behandlung, wöchentliche Frequenz)</li>
          </ul>
        </div>
        <p class="text-grey">Informationen über die Kooperation mit dem BHV erhalten Sie unter <a target="_blank" href="https://www.badischer-hv.de/verband/allgemeine-infos/bhv-partner/kurasan-karlsruhe-praxis">https://www.badischer-hv.de/verband/allgemeine-infos/bhv-partner/kurasan-karlsruhe-praxis</a>.</p>
      </div>
    </div>
  </div>
</div>











