import {Component, Inject, OnInit} from '@angular/core';


import * as moment from 'moment';
import {AppService, praxis, PraxisSelection} from '../services/app.service';
import {WINDOW} from '../app.module';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public praxisMap = praxis;
  public currentYear: number;

  public showOnly = new BehaviorSubject<PraxisSelection>(null);

  constructor(public appService: AppService, @Inject(WINDOW) private window: Window) {
    this.currentYear = moment().year();
  }

  public backToTop(): void {
    window.scrollTo(0, 0);
  }

  async ngOnInit(): Promise<void> {

    if (this.window.location.hostname.es5includes('kurasan-waldstadt')) {
      this.showOnly.next(PraxisSelection.Waldstadt);
    }

  }

}
