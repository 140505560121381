import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppService, PraxisSelection} from '../../services/app.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-termin-theorg',
  templateUrl: './termin-theorg.component.html',
  styleUrls: ['./termin-theorg.component.scss']
})
export class TerminTheorgComponent implements OnInit, AfterViewInit {

  public PraxisSelection = PraxisSelection;

  public loaded = new BehaviorSubject<boolean>(false)

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }

  ngAfterViewInit(): void {

    this.loaded.next(true);


    setTimeout(x => this.loaded.next(false), 50)
    setTimeout(x => this.loaded.next(true), 200)

  }

}
