<h2 mat-dialog-title>Corona-Update!</h2>
<mat-dialog-content class="mat-typography">
  <p>Seit heute, <strong>20.12.2021</strong>, gilt auch für unsere Praxen die 3-G-Regelung. Das bedeutet für uns und euch folgendes:</p>
  <h3 class="mt-2">Geimpfte und Genese Patient:innen</h3>
  <p>Bitte zeigt uns bei eurem nächsten Termin unaufgefordert die notwendigen Zertifikate vor. Wir kontrollieren diese mit der Covpass-App und tragen euren Status für die Zukunft in unsere Systeme ein. Ihr müsst euren Nachweis also bei den folgenden Terminen nicht mehr vorzeigen.</p>
  <h3 class="mt-2">Getestete Patient:innen</h3>
  <p>Ihr müsst bei jeder Behandlung einen negativen Schnelltest vorlegen. Dieser muss offiziell bestätigt und nicht älter als 24-Stunden sein.</p>
  <div class="mt-4">
    <p>Bei Fragen könnt ihr gerne in einer unserer Praxen anrufen 😊<br>
      Wir freuen uns auf euch!</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
