<div
  class=" blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="row">
      <div class="col-md-6 inline left d-flex justify-content-end">
        <img class="card-img-top img-fluid w-85 h-90" src="assets/img/thea/lymph.jpg" alt="Card image cap">
      </div>

      <div class="col-md-6 inline d-flex flex-column justify-content-center">

        <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
        <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
          Manuelle<br> Lymphdrainage
        </h2>

        <h4 class="font-weight-bold text-white text-uppercase mb-1">
          Was ist Manuelle Lymphdrainage?
        </h4>
        <p class="text-grey mb-3">Die Manuelle Lymphdrainage ist eine sanfte Methode, um den Rückfluß der Gewebeflüssigkeit anzuregen. Damit gemeint sind unter anderem Wasser, Fette, tote Zellen, Hyalonsäure und Eiweiße. Diese Therapieform wird oft nach Operationen oder Verletzungen angewendet. Behandelt werden zum Beispiel Ödeme, also Ansammlungen von Flüssigkeiten im Körper. Die Lymphdrainage hilft aber auch nach Lymphknotenentfernungen oder Tumorbehandlungen.
          <br>Je nach Verordnung des Arztes kann die Behandlung zwischen einer halben und einer ganzen Stunde dauern.
        </p>


        <h4 class="font-weight-bold text-white text-uppercase my-2">
          Was kann Lymphdrainage bewirken?
        </h4>
        <p class="text-grey mb-3">
          Durch gezielte Griffe kann der Physiotherapeut den Fluss der angestauten Flüssigkeit anregen und somit die Schwellung reduzieren und den Körper entlasten.
        </p>

      </div>
    </div>

  </div>
</div>






