import { Component, OnInit } from '@angular/core';
import {AppService, kura1} from '../../services/app.service';

@Component({
  selector: 'app-stellenauschreibung-physio-thera',
  templateUrl: './stellenauschreibung-physio-thera.component.html',
  styleUrls: ['./stellenauschreibung-physio-thera.component.scss']
})
export class StellenauschreibungPhysioTheraComponent implements OnInit {

  defaultPraxis = kura1;

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }
}
