<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="row">
      <div class="col-md-6 inline left">
        <img class="card-img-top img-fluid" src="assets/img/thea/physio.jpg" alt="Card image cap">
      </div>

      <div class="col-md-6 inline">
        <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
        <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
          Physiotherapie
        </h2>
        <h4 class="font-weight-bold text-white text-uppercase mb-1">
          Was ist Physiotherapie?
        </h4>
        <p class="text-grey mb-3"> Die Physiotherapie zielt darauf ab, die natürliche Funktion Ihres Körpers wiederherzustellen, zu verbessern und zu erhalten.<br><br/>
          Dabei wird während des ersten Termins festgestellt, wo Ihre Beschwerden und körperlichen Einschränkungen liegen. Anhand dieses Befundes wird dann ein Behandlungsplan für die Therapie erstellt.<br>
          Für Physiotherapie in Form von Krankengymnastik sollten Sie beim ersten Termin rund 30 Minuten und für die Folgetermine rund 25 Minuten einplanen.
        </p>
        <h4 class="font-weight-bold text-white text-uppercase mb-1">
          Was kann Physiotherapie bewirken?
        </h4>
        <p class="text-grey mb-3">
          Ziel der physiotherapeutischen Behandlung ist es in jedem Fall dem Patienten einen möglichst schmerzfreien, stabilen und mobilen Alltag zu ermöglichen.<br><br>
          In der klassischen Physiotherapie wird an der Verbesserung der Beweglichkeit und der Steigerung der Muskelkraft und Ausdauer gearbeitet.<br><br>
          Die Physiotherapeuten des Kurasan Karlsruhe verfügen über viele Techniken, die größere passive Beweglichkeit der Gelenke möglich machen. Diese ist zum Beispiel nach einem Trauma, wie einem Knochenbruch, oft stark eingeschränkt.<br><br>
          Auch bestimmte Organfunktionen oder die Atemmechanik können durch Physiotherapie verbessert werden.
        </p>
      </div>
    </div>

  </div>
</div>



