<ng-container *ngIf="appService.praxisSettings|async as praxis">


  <div class="p-3 p-lg-4 clearfix mb-5">
    <div class="container content">
      <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
      <h2 class="display-4 text-slab text-uppercase mt-0 primary-color" style="white-space: normal; word-break: break-word">
        Betriebliche<br>
        Gesundheitsförderung
      </h2>
    </div>
  </div>


  <div class="container content mb-5">

    <div class="row no-gutters">

      <div class="col-12 col-md-4 d-flex justify-content-center pos-relative">

        <img class="mx-auto d-block" src="assets/img/thea/bgf_2.png" alt="Card image cap">

      </div>
      <div class="col-12 col-md-8 pl-md-4">

        <div class="row">

          <div class="col-12">
            <p>
              <strong class="text-blue-dark">Gesunde Mitarbeiter</strong> stellen eine essenzielle Voraussetzung für den Erfolg
              eines Unternehmens dar. Folglich ist es für den Arbeitgeber ratsam, die
              Gesundheit der Mitarbeiter durch zielgerichtete präventive und
              betriebliche Maßnahmen zu fördern.
            </p>
          </div>

          <div class="col-12">
            <p>
              <strong class="text-blue-dark">Präventive Maßnahmen</strong> bedeuten, dass die Maßnahmen vor dem Eintritt
              der Gesundheitsbeeinträchtigung der Mitarbeiter eingesetzt werden,
              um drohende Beeinträchtigungen zu verhindern.
            </p>
          </div>

          <div class="col-12">
            <p>
              <strong class="text-blue-dark">Betriebliche Prävention</strong> richtet sich dabei gezielt auf die Verhinderung und
              Reduzierung von Berufskrankheiten, Arbeitsausfällen und
              sonstigen Beschwerden, die im Zusammenhang mit der verrichteten
              Arbeit auftreten.
            </p>
          </div>


          <div class="col-12">
            <p>
              Die <strong class="text-blue-dark">betriebliche Gesundheitsförderung</strong> unterstützt Mitarbeiter bei einem
              gesunden Lebensstil. Dabei umfasst sie nicht nur die Vorbeugung von
              Erkrankungen, sondern verbessert auch das Wohlbefinden, senkt den
              Stress der Mitarbeiter und steigert deren Konzentration. Dadurch steigt
              die allgemeine Arbeitsleistung der Mitarbeiter und die Zahl der AU-Tage
              sinkt, was sich positiv auf den Erfolg des Unternehmensauswirkt.
            </p>
            <p class="border-blue border rounded p-2 text-sm ml-6">
              Laut der AOK Baden-Württemberg nach WldO, 2015 sind 21,1%
              der AU-Tage durch Krankheiten des Muskel-Skelett-Systems und
              des Bindegewebes verursacht.
            </p>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="blaue_box bg-inverse text-white p-3 p-lg-4  divider-inverse blau_box clearfix pb-5">
    <div class="container content">
      <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
      <h2 class="display-4 text-slab text-white text-uppercase mt-0" style="white-space: normal; word-break: break-word">
        Vorteile der betrieblichen<br>
        Gesundheitsförderung
      </h2>
    </div>
  </div>

  <div class="pt-5 blau_box blaue_box bg-inverse ">
    <div class="container content ">

      <div class="row pb-5">
        <div class="col-12">
          <table class="table table-responsive bg-white">
            <thead class="bg-grey">
            <tr>
              <th scope="col" class=""></th>
              <th scope="col" class=""><strong>Vorteile Arbeitgeber</strong></th>
              <th scope="col" class="d-none d-sm-table-cell"></th>
              <th scope="col" class="d-none d-sm-table-cell"><strong>Vorteile Arbeitnehmer</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>Sicherung der Leistungsfähigkeit aller Mitarbeiter</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Verbesserung des Gesundheitszustandes und Senkung gesundheitlicher Risiken</td>
            </tr>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>Erhöhung der Motivation durch Stärkung der Identifikation mit dem Unternehmen</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Reduzierung der Arztbesuche</td>
            </tr>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>Kostensenkung durch weniger Krankheits- und Produktionsausfälle</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Verbesserung der gesundheitlichen Bedingungen im Unternehmen</td>
            </tr>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>Steigerung der Produktivität und Qualität</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Verringerung von Belastungen</td>
            </tr>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>Imageaufwertung des Unternehmens</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Verbesserung der Lebensqualität</td>
            </tr>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>Stärkung der Wettbewerbsfähigkeit</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Erhaltung/Zunahme der eigenen Leistungsfähigkeit</td>
            </tr>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>Steuerlicher Freibetrag bis zu 600€ pro Kalenderjahr und Mitarbeiter</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Erhöhung der Arbeitszufriedenheit und Verbesserung des Betriebsklimas</td>
            </tr>
            <tr>
              <th scope="row"><i class="fas fa-check text-primary"></i></th>
              <td>von Krankenkassen unterstützt</td>
              <td class=" d-none d-sm-table-cell"><i class="fas fa-check text-primary"></i></td>
              <td class=" d-none d-sm-table-cell">Mitgestaltung des Arbeitsplatzes und des Arbeitsablaufs</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>
  </div>

  <div class="container  blau_box blaue_box content pb-5  d-table-cell d-sm-none">
    <div class="row mb-5">
      <div class="col-12">
        <table class="table table-responsive bg-white">
          <thead class="bg-grey">
          <tr>
            <th scope="col" class=""></th>
            <th scope="col" class=""><strong>Vorteile Arbeitnehmer</strong></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Verbesserung des
              Gesundheitszustandes und
              Senkung gesundheitlicher Risiken
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Reduzierung der Arztbesuche</td>
          </tr>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Verbesserung der
              gesundheitlichen Bedingungen
              im Unternehmen
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Verringerung von Belastungen
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Verbesserung der Lebensqualität</td>
          </tr>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Erhaltung/Zunahme der eigenen Leistungsfähigkeit</td>
          </tr>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Erhöhung der Arbeitszufriedenheit und Verbesserung des Betriebsklimas</td>
          </tr>
          <tr>
            <th scope="row"><i class="fas fa-check text-primary"></i></th>
            <td>Mitgestaltung des Arbeitsplatzes und des Arbeitsablaufs</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="blaue_box">
    <div class="container content pt-2">
      <div class="d-flex justify-content-end">
        <a href="https://www.bundesgesundheitsministerium.de/themen/praevention/betriebliche-gesundheitsfoerderung/vorteile.html" target="_blank" class="text-right" style="word-break: break-all; white-space: normal">
          <small>
            https://www.bundesgesundheitsministerium.de/themen/praevention/betriebliche-gesundheitsfoerderung/vorteile.html
          </small>
          <br>
          <small>Abrufdatum: 26.10.2020</small>
        </a>
      </div>
    </div>
  </div>

  <div class="p-3 p-lg-4  divider-inverse clearfix mb-5">
    <div class="container content">
      <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
      <h2 class="display-4 text-slab text-uppercase mt-0 primary-color">
        Was wir bieten
      </h2>
    </div>
  </div>

  <div class="container content mb-5">
    <div class="row mb-5">

      <div class="col-12">
        <p>
          Jedes Unternehmen ist einzigartig. Deshalb entwickeln wir auch für
          jedes Unternehmen ein individuell auf die Bedürfnisse angepasstes
          Konzept, um den Ansprüchen eines jeden gerecht zu werden.
        </p>
        <p>
          Folgende Module sind dabei wählbar:
        </p>
      </div>

      <div class="col-12 d-flex">
        <div class=" d-none d-md-flex justify-content-end mr-2">
          <img style="width: 200px; height: 445px" class="card-img-top img-fluid" src="assets/img/ListStyle.JPG" alt="Card image cap">
        </div>
        <div class="d-flex flex-column flex-grow-1 justify-content-center">

          <ul>
            <li class="py-4 clickable">
              <h3 class="text-blue-dark font-weight-bold inline" placement="bottom" popoverClass="widePopOver" [ngbPopover]="workAnalyseBody" [popoverTitle]="workAnalyseTitle"><span class=" el-hovers">Arbeitsplatzanalyse</span></h3>
            </li>
            <li class="py-4 clickable">
              <h3 class="text-blue-dark font-weight-bold inline" placement="bottom" popoverClass="widePopOver" [ngbPopover]="healthAnalyseBody" [popoverTitle]="healthAnalyseTitle"><span class=" el-hovers">Gesundheitskurse</span></h3>
            </li>
            <li class="py-4 clickable">
              <h3 class="text-blue-dark font-weight-bold inline" placement="bottom" popoverClass="widePopOver" [ngbPopover]="presentationBody" [popoverTitle]="presentationTitle"><span class=" el-hovers">Schulungen/Vorträge</span></h3>
            </li>
            <li class="py-4 clickable">
              <h3 class="text-blue-dark font-weight-bold inline" placement="bottom" popoverClass="widePopOver" [ngbPopover]="mobileBody" [popoverTitle]="mobileTitle"><span class=" el-hovers">Mobile Behandlungen – aktive Pause</span></h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12 col-md-6">
        <h3 class="text-blue-dark font-weight-bold" style="text-decoration: underline">
          <ng-container *ngTemplateOutlet="workAnalyseTitle"></ng-container>
        </h3>
        <ng-container *ngTemplateOutlet="workAnalyseBody"></ng-container>
      </div>

      <div class="col-12 col-md-6">
        <h3 class="text-blue-dark font-weight-bold" style="text-decoration: underline">
          <ng-container *ngTemplateOutlet="healthAnalyseTitle"></ng-container>
        </h3>
        <ng-container *ngTemplateOutlet="healthAnalyseBody"></ng-container>
      </div>

      <div class="col-12 col-md-6">
        <h3 class="text-blue-dark font-weight-bold" style="text-decoration: underline">
          <ng-container *ngTemplateOutlet="presentationTitle"></ng-container>
        </h3>
        <ng-container *ngTemplateOutlet="presentationBody"></ng-container>
      </div>

      <div class="col-12 col-md-6">
        <h3 class="text-blue-dark font-weight-bold" style="text-decoration: underline">
          <ng-container *ngTemplateOutlet="mobileTitle"></ng-container>
        </h3>
        <ng-container *ngTemplateOutlet="mobileBody"></ng-container>
      </div>
    </div>


    <div class="d-flex justify-content-center">
      <p style="max-width: 600px; text-align: center; font-style: italic">
        Wenn Sie Fragen haben oder sich mit uns über Ihr individuelles<br>
        Gesundheitsprogramm austauschen möchten, dann wenden Sie<br>
        sich gerne an uns unter
        <phone [phone]="praxis.phone"></phone>
        oder per Mail an<br>
        <email [email]="praxis.email"></email>
        .
    </div>

  </div>


  <ng-template #mobileTitle>
    Mobile Behandlungen – aktive Pause
  </ng-template>
  <ng-template #mobileBody>
    <p>
      Mobile Behandlungen, wie z.B. die aktive Pause, finden bei Ihnen in
      Ihren Räumlichkeiten statt. Die Maßnahmen dienen bspw. der
      Entspannung, Aktivierung oder Mobilisation. Sie können entweder am
      Anfang oder Ende eines Arbeitstages oder wie der Name schon sagt in
      den Pausen stattfinden.<br>
      Je nach Bedürfnis geben unsere Therapeuten den Mitarbeitern Übungen
      an die Hand, die sie direkt am Arbeitsplatz in
      lockerer Arbeitskleidung durchführen können. Das fördert die
      Konzentration und Aufmerksamkeit der Mitarbeiter und steigert zudem
      deren Leistungsfähigkeit. Der genaue Ablauf wird immer auf Ihre
      speziellen Bedürfnisse angepasst.
    </p>
  </ng-template>

  <ng-template #presentationTitle>
    Schulungen/Vorträge
  </ng-template>
  <ng-template #presentationBody>
    <p>
      Je nach Bedarf bieten wir verschiedene Vorträge zu Themen wie
      Gesundheit am Arbeitsplatz, Stressbewältigung oder mehr Bewegung im
      Alltag an.<br>
      Gerne gehen wir auch auf Ihre speziellen Anliegen ein. Sprechen Sie uns
      einfach darauf an!
    </p>
  </ng-template>


  <ng-template #healthAnalyseTitle>
    Gesundheitskurse
  </ng-template>
  <ng-template #healthAnalyseBody>
    <strong>Rückenfit – 10 Termine, Kurasan Karlsruhe, Kriegsstr. 140</strong>
    <p>
      Es ist kein Geheimnis, dass Rückenbeschwerden die Volkskrankheit Nr.
      1 in Deutschland ist. Die Folgen sind meist langwierig. Daher ist es
      sinnvoll, die Risiken für Arbeitgeber und -nehmer durch gezielte
      Förderung so früh wie möglich zu reduzieren, um Schmerzen zu
      vermeiden und Arbeits- und Produktionsausfälle zu verhindern.
    </p>
    <p>
      Beim Rückenfit-Kurs geht es um präventive Gymnastik für den ganzen
      Körper. Durch spezielle Übungen z.B. für den Rumpf sollen muskuläre
      Dysbalancen ausgeglichen und ein harmonisches Zusammenspiel
      hergestellt werden. Die Übungen sind in den normalen Tagesablauf
      integrierbar, sodass sie für jeden auch z.B. zu Hause möglichst einfach
      umsetzbar sind. Durch wenig Aufwand kann man hier oft schon viel
      erreichen.
    </p>
  </ng-template>

  <ng-template #workAnalyseTitle>
    Arbeitsplatzanalyse
  </ng-template>
  <ng-template #workAnalyseBody>
    <p>Besteht aus folgenden Teilmodulen:</p>
    <ul>
      <li>
        <strong>Ergonomie am Arbeitsplatz</strong>
      </li>
      <li>
        <strong>Analyse der Tätigkeiten und Arbeitsmittel</strong>
      </li>
      <li>
        <strong>Analyse der Arbeitsumgebung</strong>
      </li>
      <li>
        <strong>Analyse individueller Verhaltensweisen</strong>
      </li>
    </ul>
    <p>
      Bei der Arbeitsplatzanalyse wird das Arbeitsumfeld genau unter die
      Lupe genommen. Besonders eingegangen wird dabei z.B. auf die
      Gestaltung des Arbeitsplatzes, aber auch auf die Beleuchtung der
      Räume sowie die Geräuschkulisse und das Raumklima. Nach der Analyse
      werden den Mitarbeitern Empfehlungen mitgegeben und der
      Arbeitsplatz auf Wunsch gleich optimiert.
    </p>
  </ng-template>

</ng-container>
