<div class="container d-flex" *ngIf="loaded|async">

  <ng-container *ngIf="(appService.currentPraxis|async) === PraxisSelection.Kurasan1">

    <iframe style="flex-grow: 1" src="assets/theorg/krieg/otrs.php" height="800"
            width="520" frameborder="0" name="otrframe">
    </iframe>

  </ng-container>

  <ng-container *ngIf="(appService.currentPraxis|async) === PraxisSelection.Kurasan2">

    <iframe style="flex-grow: 1" src="assets/theorg/karl/otrs.php" height="800"
            width="520" frameborder="0" name="otrframe">
    </iframe>

  </ng-container>


  <ng-container *ngIf="(appService.currentPraxis|async) === PraxisSelection.Knielingen">

    <iframe style="flex-grow: 1" src="assets/theorg/knielingen/otrs.php" height="800"
            width="520" frameborder="0" name="otrframe">
    </iframe>

  </ng-container>

</div>

