import {AfterContentChecked, AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {Meta, Title} from '@angular/platform-browser';
import {filter, map, mergeMap} from 'rxjs/operators';
import {AppService, PraxisSelection} from './services/app.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {getLocalItem, getSessionItem, setLocalItem} from './helper/session.helper';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CovidHintComponent} from './views/covid-hint/covid-hint.component';
import {compileInjectable} from '@angular/compiler';

declare const $: any; // make jQuery available

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked, OnDestroy {

  private subs: Subscription[] = [];
  public PraxisSelection = PraxisSelection;
  public showTrainMsg = new BehaviorSubject<boolean>(getLocalItem('train-msg', true));
  public showFfp2Msg = new BehaviorSubject<boolean>(getLocalItem('ffp2-msg', true));

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public appService: AppService,
    private titleService: Title,
    private metaService: Meta,
    private dialog: MatDialog
  ) {
  }

  ngOnDestroy(): void {
    this.subs?.forEach(x => x?.unsubscribe());
  }

  updateShowTrain(): void {
    setLocalItem('train-msg', false);
    this.showTrainMsg.next(false);
  }

  updateFfp2Msg(): void {
    setLocalItem('ffp2-msg', false);
    this.showFfp2Msg.next(false);
  }

  async ngOnInit(): Promise<void> {

    // const covidHint = getLocalItem('covid-hint', false);
    // console.log('test', covidHint);
    //
    // if (!covidHint) {
    //   await this.openDialog();
    //   setLocalItem('covid-hint', true);
    // }

    this.subs.push(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route: any) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route: any) => route.outlet === 'primary'),
          mergeMap((route: any) => route.data)
        )
        .subscribe((event: any) => {
          this.updateTitle(event.data?.title, this.appService.praxisSettings.value.title);
          this.appService.SelectByUrl(this.router.url);
        })
    );

    // Close the bootstrap menu if we make a page-change
    this.subs.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event) => {
        $('div.navbar-main').collapse('hide');
      })
    );

    this.subs.push(
      this.appService.praxisSettings.subscribe(x => {
        this.updateTitle(this.activatedRoute.snapshot.data?.title, x.title);
        this.updateMetaTags(x.metaTags);
      })
    );

    this.appService.scrollUp();
  }

  private updateTitle(siteTitle: string, praxisName: string): void {
    if (!siteTitle) {
      this.titleService.setTitle(praxisName);
    } else {
      this.titleService.setTitle(siteTitle + ' | ' + praxisName); // TODO not working
    }
  }

  private updateMetaTags(metaTags: string): void {
    const allTags = this.appService.generalMetaTags + metaTags;
    this.metaService.updateTag({name: 'keywords', content: allTags});
  }

  ngAfterContentChecked(): void {
  }

  async openDialog(): Promise<void> {
    const dialogRef = this.dialog.open(CovidHintComponent);
    await dialogRef.afterClosed().toPromise();
  }
}

