import {Component, OnInit} from '@angular/core';
import {AppService, PraxisSelection} from '../services/app.service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {NavigationSection} from '../models/navigation-section';
import {NavigationService} from '../services/navigation.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public loaded = new BehaviorSubject<boolean>(false);
  public entries = new BehaviorSubject<NavigationSection[]>([]);

  public PraxisSelection = PraxisSelection;

  constructor(public appService: AppService, public router: Router, private navService: NavigationService) {
  }


  public resetPraxisSelection(): void {
    this.appService.currentPraxis.next(PraxisSelection.Undefined);
  }

  ngOnInit(): void {


    this.appService.currentPraxis.subscribe(x => {
      this.entries.next(this.navService.loadNavEntries(x));
      this.loaded.next(true);
      this.appService.scrollUp();
    });


  }
}
