import {Component, Inject, OnInit} from '@angular/core';
import {AppService, praxis, PraxisSelection} from '../../services/app.service';
import {WINDOW} from '../../app.module';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-praxis-selection',
  templateUrl: './praxis-selection.component.html',
  styleUrls: ['./praxis-selection.component.scss']
})
export class PraxisSelectionComponent implements OnInit {

  public praxisMap = praxis;

  public PraxisSelection = PraxisSelection;


  constructor(public appService: AppService, @Inject(WINDOW) private window: Window, private router: Router) {
  }

  async ngOnInit(): Promise<void> {

    if (this.window.location.hostname.es5includes('localhost')) {
      praxis.forEach(x => x.domain = null);
    }

    if (this.window.location.hostname.es5includes('kurasan-waldstadt')) {
      await this.router.navigate(['waldstadt', 'start']);
    }
    if (this.window.location.hostname.es5includes('kurasan-knielingen')) {
      await this.router.navigate(['knielingen', 'start']);
    }
  }
}
