import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {FaqEntry} from '../../models/faq-entry';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'app-logopaedie',
  templateUrl: './logopaedie.component.html',
  styleUrls: ['./logopaedie.component.scss']
})
export class LogopaedieComponent implements OnInit {

  public adultPanelStates = new Map<string, boolean>();
  public adultActiveIDs = new BehaviorSubject<string>(null);
  public adultEntries = new BehaviorSubject<{ entry: { sortIndex: number, question: string, answer: string }, index: string }[]>([]);
  public childPanelStates = new Map<string, boolean>();
  public childActiveIDs = new BehaviorSubject<string>(null);
  public childEntries = new BehaviorSubject<{ entry: { sortIndex?: number, question: string, answer: string }, index: string }[]>([]);
  public loaded = new BehaviorSubject<boolean>(false);

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {

    this.loaded.next(false);

    const entries: { sortIndex: number, question: string, answer: string }[] = [
      {
        question: 'Aphasie',
        answer: 'Unter einer Aphasie versteht man eine erworbene Sprachstörung nach abgeschlossener Sprachentwicklung, welche sich durch ein neurologisches Ereignis (z.B. Schlaganfall) entwickelt.\n' +
          'Dies kann die Lautstruktur (Phonologie), den Wortschatz (Lexikon), die Bedeutung (Semantik) oder den Satzbau (Syntax) betreffen. Hierbei können die rezeptiven (Sprachverständnis) oder expressiven (Sprachproduktion) Fähigkeiten betroffen sein.\n' +
          'Man unterscheidet diese zwischen Globaler Aphasie (schwerste Beeinträchtigung), Wernicke Aphasie, Broca Aphasie und Amnestische Aphasie.\n' +
          '\n' +
          'Dieses Störungsbild kann je nach Schweregrad und Form der Aphasie große Beeinträchtigungen im sozialen, familiären oder beruflichen Leben haben. Die Patienten zeigen hierbei meist eine stark ausgeprägten Leidensdruck.\n' +
          '\n' +
          'Ziel  ist es, so schnell wie möglich nach dem Ereignis die logopädische Therapie zu beginnen, um den Weg zurück in den Alltag zu erleichtern oder zu ermöglichen.',
        sortIndex: 1
      },
      {
        question: 'Dyslexie / Dysgraphie',
        answer: 'Unter einer Dyslexie versteht man eine Störung der Lesefähigkeit. Eine Dysgraphie hingegen ist die Störung der Schreibfähigkeit.\n' +
          '\n' +
          'Beide Störungsbilder können gemeinsam oder getrennt voneinander auftreten.\n' +
          '\n' +
          'Im Erwachsenenalter prägt sich dieses Störungsbild meist aufgrund einer Aphasie aus.',
        sortIndex: 2,
      }
      ,
      {
        question: 'Sprechapraxie',
        answer: 'Eine Sprechapraxie ist eine Störung im Ablauf und der Planung der Suchbewegungen, welche im Bereich der Artikulation, des Sprechrythmus oder des Sprechverhalten deutlich wird. Das heißt die Patienten kennen das Wort, welches sie sprechen wollen, können dies jedoch nicht oder nicht verständlich artikulieren.\n' +
          '\n' +
          'Dies kann sich auf Laut-, Silben- und Wortebene abspielen.',
        sortIndex: 3,
      }
      ,
      {
        question: 'Redeflusstörungen',
        answer: 'Die Redeflusstörungen werden, wie im Kindesalter unter Stottern und Poltern unterschieden.\n' +
          '\n' +
          '- Stottern: Stottern ist durch Wiederholungen, Blockierungen und Verlängerungen von Lauten gekennzeichnet. Stotternde zeigen oftmals Begleitsymptomatiken, diese sind z.B. Suchbewegungen (z.B. mit den Armen) oder vegetative Symptome.\n' +
          '\n' +
          '- Poltern: Hierbei sprechen die Patienten unrhythmisch schnell oder nicht der Norm entsprechend.',
        sortIndex: 3,
      }
      ,
      {
        question: 'Audiogene Sprechstörungen:',
        answer: 'Unter einer audiogenen Sprechstörung versteht man eine Sprechstörung, welche aufgrund einer Hörstörung resultiert. Je nach Schweregrad und Ausprägung dieser Hörstörung, können Laute der Sprache in den vom Hörverlust betroffenen Frequenz nicht oder nur noch ungenau wahrgenommen werden.\n' +
          '\n' +
          'Hierbei kann es zu einer schlecht verständlichen bis unverständlichen Spontansprache der Betroffenen kommen.',
        sortIndex: 3,
      }
      ,
      {
        question: 'Stimmstörungen',
        answer: 'Stimmstörungen werden in funktionelle Stimmstörungen oder organisch bedingte Stimmstörungen unterschieden.\n' +
          '\n' +
          '- Eine funktionelle Stimmstörung (Dysphagie) ist gekennzeichnet durch eine Veränderung des Stimmklang und einer eingeschränkten stimmlichen Belastbarkeit, ohne dass eine organische Ursache vorliegt. Diese kann verschiedenen Ursachen haben, wie z.B. ein Fehlgebrauch der Stimme, Sprechberufe (Lehrer, Erzieher, …), psychische Probleme oder Stress haben. Der Stimmklang ist rauh, heiser, behaucht oder gepresst und hat aufgrund seiner oftmals eingeschränkten stimmlichen Belastbarkeit eine hohe Einschränkung im Alltag.\n' +
          '\n' +
          '- Eine organisch bedingte Stimmstörung hat einen organischen Befund als Ursache. Hierbei ist eine strukturelle Veränderung im Kehlkopfbereich nachweisbar. Ursache hierfür sind beispielsweise Lähmungen der Stimmlippen (Recurrensparese), Traumata im Kehlkopfbereich (z.B. Tumor) oder Wucherungen im Kehlkopf (z.B. aufgrund stimmlicher Überlastung).',
        sortIndex: 3,
      }
      ,
      {
        question: 'Stimmtherapie bei Transsexualität:',
        answer: 'Bei der transsexuellen Entwicklung von Mann zu Frau treten oft Schwierigkeiten auf, die Sprechstimmlage an die einer femininen Stimme anzupassen. Bei der Frau zu Mann Entwicklung benötigt es selten logopädische Behandlung, da die Stimme aufgrund der Hormontherapie meist automatisch zu einem maskulinen Stimmklang absinkt.\n' +
          '\n' +
          'Hierbei handelt es sich bei der logopädischen Therapie nicht allein darum den Stimmklang einer Frau zu erreichen, sondern ergänzend hierzu die Wortwahl, die Satzbildung sowie Mimik und Gestik anzupassen.',
        sortIndex: 3,
      }
      ,
      {
        question: 'Funktionelle Schluckstörungen:',
        answer: 'Eine funktionelle Schluckstörung (Myofunktionelle Therapie) im Erwachsenenalter kann durch mehrere Symptome ausgeprägt sein. Diese sind beispielsweise ein inkompletter Mundschluss, eine auffällige Lippen- oder Zungenstruktur, eine unphysiologische Zungenruhelage oder eine Mundatmung. Hierbei zeigen die Patienten Schwierigkeiten beim Schlucken von Speichel, Nahrung oder Getränke.',
        sortIndex: 3,
      }
      ,
      {
        question: 'Organisch bedingte Schluckstörungen:',
        answer: 'Organisch bedingte Schluckstörungen äußern sich beispielsweise durch Verschlucken oder Husten. Diese treten meist aufgrund neurologischer oder tumorbedingter Erkrankungen, wie ein Schlaganfall, Morbus Parkinson, Multiple Sklerose (MS), Amyotrophe Lateralsklerose (ALS) oder eines Schädel-Hirn-Traumata auf.',
        sortIndex: 3,
      }

    ];

    for (let i = 0; i < entries.length; i++) {
      entries[i].sortIndex = i;
    }

    this.adultPanelStates = new Map<string, boolean>();

    const elementsWithIndex = entries.map(entry => {

      const index = `static-${entry.sortIndex}`;
      this.adultPanelStates.set(index, false);

      return {
        entry,
        index
      };
    });


    const entrieschild: { sortIndex?: number, question: string, answer: string }[] = [

      {
        question: 'Sprachentwicklungsstörung',
        answer: 'Eine Sprachentwicklungsstörung (SES) ist eine nicht altersentsprechende Entwicklung der sprachlichen Fähigkeiten. Man kann diese unter phonetisch-phonologische, morpho-syntaktische (dysgrammatische), lexikalisch-semantische Störungen, sowie auf pragmatisch-kommunikativer Ebene unterscheiden. Jedoch sind oftmals mehrere dieser Ebenen betroffen.',
      },
      {
        question: 'Sprachentwicklungsverzögerung',
        answer: 'Eine Sprachentwicklungsverzögerung ist eine rein zeitliche Abweichung der Sprachentwicklung. Diese beträgt mehr als 6 Monaten Rückstand bis zum 36.Lebensmonat.',
      },
      {
        question: 'Störungen des Lauterwerbs',
        answer: 'Unter Störungen beim Erwerb der Laute wird die Störung der Anzahl der Laute und die Regel ihrer Kombinationen zu Wörtern (Lautsystem genannt) verstanden.\n' +
          '\n' +
          ' z.B. Giraffe -> Diraffe',
      },
      {
        question: 'Störungen der Grammatik',
        answer: 'Bei Patienten mit Störungen der Grammatik sind Wörter oder Sätze betroffen.\n' +
          '\n' +
          'z.B. Ich habe geklettert -> Ich habe klettert',
      },
      {
        question: 'Störungen beim Erwerb des Wortschatzes',
        answer: 'Störungen beim Erwerb des Wortschatzes können den Wortschatzumfang oder die Merkmale der einzelnen Wörter betreffen.\n' +
          '\n' +
          'z.B. Nomen werden oftmals mit „Dings“ ersetzt; Adjektive werden oftmals mit „machen“ oder „so“ ersetzt',
      },

      {
        question: 'Störungen beim Textverständnis',
        answer: 'Unter Störungen beim Textverständnis versteht man Schwierigkeiten Erlebtes korrekt nachzuerzählen.',
      },
      {
        question: 'Störungen der Schriftsprache, Lese-Rechtschreib-Schwäche (LRS)',
        answer: 'Eine Lese-Rechtschreib-Schwäche (Legasthenie), welche auch als „LRS“ abgekürzt wird, ist eine Teilleistungsstörung. Dies bereitet dem Kind große Schwierigkeiten in der Schule und im weiteren Leben. Wenn dies unbehandelt bleibt, kann es bis ins Erwachsenenalter anhalten.',
      },
      {
        question: 'Artikulationsstörung',
        answer: 'Bei einer Artikulationsstörung kann ein Laut nicht oder nicht korrekt artikuliert werden. Die Bekannteste Form ist hierbei der sogenannte „Sigmatismus“, worunter man umgangssprachlich „das Lispeln“ versteht.',
      },
      {
        question: 'Redeflussstörungen',
        answer: 'Redeflussstörungen sind Sprechstörungen (das Sprechen betreffend) und können in Stottern und Poltern unterteilt werden.\n' +
          '\n' +
          '- Stottern: Stottern ist durch Wiederholungen, Blockierungen und Verlängerungen von Lauten gekennzeichnet. Stotternde zeigen oftmals Begleitsymptomatiken, diese sind z.B. Suchbewegungen (z.B. mit den Armen) oder vegetative Symptome.\n' +
          '\n' +
          '- Poltern: Hierbei sprechen die Patienten unrhythmisch schnell oder nicht der Norm entsprechend.',
      },
      {
        question: 'Stimmstörungen (Dysphonien)',
        answer: 'Stimmstörungen werden in funktionelle Stimmstörungen oder organisch bedingte Stimmstörungen unterschieden.\n' +
          '\n' +
          '- Funktionelle Stimmstörungen haben keine organische Ursache und äußern sich in einem rauen, gepressten, heiseren oder behauchten Stimmklang.\n' +
          '-> werden diese nicht behandelt, können sie sich zu organisch bedingten Dysphonien entwickeln.\n' +
          '\n' +
          '- Organisch bedingte Stimmstörungen haben ihren Ursprung im Kindesalter oft durch beispielsweise Schreiknötchen oder angeborene Fehlbildungen. Diese äußern sich z.B. in einer Sprechanstrengung, einer Veränderung des Stimmklanges oder einer eingeschränkten stimmlichen Belastbarkeit.',
      },
      {
        question: 'Funktionelle orofaziale Störungen',
        answer: 'Funktionelle orofaziale Störungen oder auch „Myofunktionelle Störungen“ genannt, sind Muskelspannungen und Bewegungsmuster, welche die innere oder äußere Mundmuskulatur betreffen. Hierbei sind die Kräfte der Zunge und der restlichen Mundmuskulatur betroffen und äußern sich beispielsweise in einer offenen Mundhaltung, einer verwaschenen Aussprache, einer unphysiologischen Zungenruhelage oder einer Mundatmung.'
      },
      {
        question: 'Organisch bedingte Schluckstörungen',
        answer: 'Unter organisch bedingten Schluckstörungen oder auch Dysphagien, versteht man Schwierigkeiten beim Transport des Speichels, der Nahrung oder der Getränke von der Mundhöhle in den Magen. Dies kann bereits bei Säuglingen auftreten und kann zu großen Problemen führen.\n' +
          '\n' +
          'Daher ist hierbei eine zeitnahe logopädische Behandlung von großer Notwendigkeit.'
      },
      {
        question: 'Auditive Verarbeitungs- und Wahrnehmungsstörung',
        answer: 'Bei einer auditiven Verarbeitungs- und Wahrnehmungsstörung (kurz AVWS) ist das Hörvermögen uneingeschränkt. Die Patienten zeigen Schwierigkeiten bei der korrekten Verarbeitung der akustischen Signale ins Gehirn.\n' +
          '\n' +
          'Aufgrund dieses Störungsbildes können sich eine Sprachentwicklungsverzögerung, eine reduzierte Merkspanne, Lern- und Konzentrationsschwierigkeiten oder eine Überempfindlichkeit auf Geräusche entwickeln.'
      },

    ];

    this.adultActiveIDs.next(elementsWithIndex[0].index);
    this.adultPanelStates.set(this.adultActiveIDs.value, true);
    this.adultEntries.next(elementsWithIndex);


    for (let i = 0; i < entrieschild.length; i++) {
      entrieschild[i].sortIndex = i;
    }

    this.childPanelStates = new Map<string, boolean>();

    const elementschildWithIndex = entrieschild.map(entry => {

      const index = `static-${entry.sortIndex}`;
      this.childPanelStates.set(index, false);

      return {
        entry,
        index
      };
    });

    this.childActiveIDs.next(elementschildWithIndex[0].index);
    this.childPanelStates.set(this.childActiveIDs.value, true);
    this.childEntries.next(elementschildWithIndex);

    this.loaded.next(true);

  }

  setAdultPanelStates($event: NgbPanelChangeEvent): void {
    for (const [key, value] of this.adultPanelStates.entries()) {
      this.adultPanelStates.set(key, false);
    }
    this.adultPanelStates.set($event.panelId, $event.nextState);

    this.adultActiveIDs.next($event.panelId);
  }

  setChildPanelStates($event: NgbPanelChangeEvent): void {
    for (const [key, value] of this.childPanelStates.entries()) {
      this.childPanelStates.set(key, false);
    }
    this.childPanelStates.set($event.panelId, $event.nextState);

    this.childActiveIDs.next($event.panelId);
  }
}
