import { Component, OnInit } from '@angular/core';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'app-start',
  templateUrl: './galerie.component.html',
  styleUrls: ['./galerie.component.scss']
})
export class GalerieComponent implements  OnInit{

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }



}
