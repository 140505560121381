import { Component, OnInit } from '@angular/core';
import {AppService, defaultPraxis} from '../../services/app.service';

@Component({
  selector: 'app-stellenausschreibung',
  templateUrl: './stellenausschreibung.component.html',
  styleUrls: ['./stellenausschreibung.component.scss']
})
export class StellenausschreibungComponent implements OnInit {

  defaultPraxis = defaultPraxis;

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }

}
