<div class=" blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
    <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
      Logopädie
    </h2>

    <div class="row">

      <div class="col-12 col-md-6 py-4">
        <img class="card-img-top img-fluid" src="assets/img/thea/logopaedie.jpg" alt="Logopädie Beispielbild">
      </div>
      <div class="col-12 col-md-6 py-4">

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was ist Logopädie?</h4>

        <p class="text-grey">
          Logopädie oder auch Sprach-, Sprech- und Stimmtherapie ist ein Fachgebiet, welches sich mit den Funktionen und Störungen des Sprech- und Sprachapparates befasst.
          Die logopädischen Behandlungsbereiche sind die Stimme, das Sprechen, die Sprache und das Schlucken. Zusätzlich der Diagnostik und Therapie von Störungen der genannten Bereiche, stellen die Prävention, Früherkennung, Frühförderung und Rehabilitation weitere Bereiche da.
        </p>
        <p class="text-grey">
          Die Behandlungsdauer in der logopädischen Praxis variiert hauptsächlich von 45 bis 60 Minuten.
        </p>

      </div>

      <div class="col-12 py-4">

        <h4 class="font-weight-bold text-white text-uppercase mb-1">Was bewirkt Logopädie?</h4>

        <p class="text-grey">
          Die Kommunikation ist angewiesen auf die Sprache, Sprechen und die Stimme.
          Ziel der Logopädie ist, die Kommunikation und dadurch die Lebensqualität der Betroffenen, zu erleichtern und zu ermöglichen.
        </p>
        <p class="text-grey">
          Die Logopädie bewirkt Verbesserungen in jedem Alter. Diese Leistungen werden, in folgenden Punkten in Sprach,- Sprech-, Stimm- und Schluckstörungen, untergliedert. Ergänzend hierzu gibt es komplexe Störungen, wie z.B. Autismus, Dysarthrien, Hörstörungen oder Neuro-degenerative Erkrankungen.
        </p>

      </div>


      <div class="col-12 py-5">

        <h4 class="font-weight-bold text-white text-uppercase mb-2">Leistungen</h4>

        <h2>Logopädie Erwachsene:</h2>
        <p>
          Die logopädische Behandlung hat im Regelfall eine Behandlungsdauer von 45-60 Minuten und erfolgt 1-2 x wöchentlich. Das häusliche Üben ist von großer Wichtigkeit.
        </p>
        <div>
          <span>Zu den häufigsten logopädischen Störungsbildern gehören:</span>
          <ul>
            <li>Stimmstörungen</li>
            <li>Sprach- und Sprechstörungen infolge neurologischer Erkrankungen (z.B. Schlaganfall)</li>
            <li>Redeflussstörungen: Stottern und Poltern</li>
          </ul>
        </div>
        <p>
          Ergänzend hierzu bieten wir Stimmprävention an. Diese ist besonders attraktiv für Personen, welche in einem Sprechberuf wie beispielsweise Lehrer, Erzieher oder Hotline-Mitarbeiter arbeiten, diese eine hohe stimmliche Belastbarkeit mit sich bringen.
        </p>
        <p>
          Ziel hierbei ist es, eine funktionelle oder gar organisch bedingte Stimmstörung vorzubeugen und die Patienten in ihrem Stimmgebrauch zu schulen.
        </p>

      </div>
    </div>
  </div>
</div>

<div class="p-3 p-lg-4">
  <div class="container content">
    <div class="col-12" *ngIf="loaded|async">
      <ngb-accordion [closeOthers]="true" [activeIds]="adultActiveIDs|async" (panelChange)="setAdultPanelStates($event)">
        <ngb-panel *ngFor="let item of adultEntries|async" [id]="item.index">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
              <div class="d-flex pr-2" [innerHTML]="item.entry.question"></div>
              <div *ngIf="adultPanelStates.get(item.index)" class="d-flex" style="font-size: 150%; text-decoration: none"><i class="fas fa-angle-down"></i></div>
              <div *ngIf="!adultPanelStates.get(item.index)" class="d-flex" style="font-size: 150%; text-decoration: none"><i class="fas fa-angle-right"></i></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div [innerHTML]="item.entry.answer"></div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div>
      <p>Bei Fragen zu den jeweiligen Störungsbildern oder deren Behandlungsmethoden- und möglichkeiten, wenden Sie sich gerne an unsere Praxis.<br>
        <phone [phone]="(appService.praxisSettings|async).phone" class="mx-2"></phone>
      </p>
    </div>
  </div>
</div>

<div class=" blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="row">

      <div class="col-12">
        <h2>Logopädie Kinder:</h2>
        <p>
          Bei der Arbeit mit Kindern stehen die enge Zusammenarbeit und Beratung mit den Erziehungsberechtigten an oberster Stelle, um das gemeinsame Ziel schnellstmöglich zu erreichen.
        </p>
        <p>
          Die logopädische Therapie hat im Regelfall eine Dauer von 45-60 Minuten und sollte 1-2 x wöchentlich stattfinden. Das häusliche Üben ist zudem ein großer Bestandteil der Therapie.
        </p>
        <div>
          <span>Zu den häufigsten logopädischen Störungsbilder zählen:</span>
          <ul>
            <li>Aussprachestörungen</li>
            <li>Verzögerung der Sprachentwicklung</li>
            <li>Auditive Wahrnehmungs- und Verarbeitungsstörungen</li>
            <li>Redeflussstörungen: Stottern und Poltern</li>
            <li>Orofaziale Dysfunktion
            </li>
          </ul>
        </div>
        <p>
          Ergänzend hierzu bieten wir <strong>Stimmprävention</strong> an. Diese ist besonders attraktiv für Personen, welche in einem Sprechberuf wie beispielsweise Lehrer, Erzieher oder Hotline-Mitarbeiter arbeiten, diese eine hohe stimmliche Belastbarkeit mit sich bringen.
        </p>
        <p>

        </p>
      </div>
    </div>

  </div>

</div>


<div class="p-3 p-lg-4">
  <div class="container content">
    <div class="col-12" *ngIf="loaded|async">
      <ngb-accordion [closeOthers]="true" [activeIds]="childActiveIDs|async" (panelChange)="setChildPanelStates($event)">
        <ngb-panel *ngFor="let item of childEntries|async" [id]="item.index">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
              <div class="d-flex pr-2" [innerHTML]="item.entry.question"></div>
              <div *ngIf="childPanelStates.get(item.index)" class="d-flex" style="font-size: 150%; text-decoration: none"><i class="fas fa-angle-down"></i></div>
              <div *ngIf="!childPanelStates.get(item.index)" class="d-flex" style="font-size: 150%; text-decoration: none"><i class="fas fa-angle-right"></i></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div [innerHTML]="item.entry.answer"></div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div>
      <p>Bei Fragen zu den jeweiligen Störungsbildern oder deren Behandlungsmethoden- und möglichkeiten, wenden Sie sich gerne an unsere Praxis.<br>
        <phone [phone]="(appService.praxisSettings|async).phone" class="mx-2"></phone>
      </p>
    </div>
  </div>
</div>


<div class=" blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="row">
      <div class="col-12  py-4">
        <img class="card-img-top img-fluid" src="assets/img/thea/logopaedieMund.jpg" alt="Logopädie Beispielbild">
      </div>
    </div>
  </div>
</div>

