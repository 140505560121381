<ng-container *ngIf="appService.praxisSettings|async as praxis">

  <div class="container">
    <hr class="hr-lg mt-0 mb-2 w-10 mx-auto hr-primary"/>
    <h2 class="text-center text-uppercase font-weight-bold my-0">
      Kontakt
    </h2>
    <h5 class="text-center font-weight-light mt-2 mb-4 op-5">
      Bei Fragen nehmen Sie einfach Kontakt zu uns auf!
    </h5>
  </div>

  <div class="row no-gutters mb-5">
    <div class="col-12">
      <div class="google-map">
        <ng-container *ngIf="praxis.type === appService.PraxisSelection.Kurasan1">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d654.313390914772!2d8.393717488158787!3d49.00576079870009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797065b0de193a9%3A0x7407f2708df7c14a!2sKurasan-Karlsruhe%20Benedikt%20Hettich!5e0!3m2!1sen!2sde!4v1609859273914!5m2!1sen!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </ng-container>
        <ng-container *ngIf="praxis.type === appService.PraxisSelection.Kurasan2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163.58115860251664!2d8.394772156843395!3d49.004905109900974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479707bf0aaf82a1%3A0xeea3b4a7b9755e8e!2sKurasan-Karlsruhe%202.0%20Physiotherapie!5e0!3m2!1sen!2sde!4v1609859183122!5m2!1sen!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </ng-container>
        <ng-container *ngIf="praxis.type === appService.PraxisSelection.Waldstadt">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2615.73391490682!2d8.44569601568061!3d49.03466757930515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47970874c2430195%3A0xca8f6462d10dd1d4!2sBeuthener%20Str.%2016%2C%2076139%20Karlsruhe!5e0!3m2!1sen!2sde!4v1625512068240!5m2!1sen!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </ng-container>
        <ng-container *ngIf="praxis.type === appService.PraxisSelection.Knielingen">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d653.987034404105!2d8.339776929257287!3d49.03059339870656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479700d32cc9cf95%3A0x73eff42dab57ba42!2sSaarlandstra%C3%9Fe%2079%2C%2076187%20Karlsruhe!5e0!3m2!1sen!2sde!4v1659690377558!5m2!1sen!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="container mb-3">
    <div class="d-flex justify-content-between flex-wrap">

      <div>
        <div class="row no-gutters" style="width: 400px">
          <h4 class="mb-2 font-weight-bold col-12">
            Öffnungszeiten
          </h4>

          <ng-container *ngIf="praxis.openingTimes.monday">
            <div class="col-4">
              <span class="font-weight-bold text-uppercase">Montag:</span>
            </div>

            <div class="col-6">
              <span class="col-6"> {{praxis.openingTimes.monday}}</span>
            </div>
          </ng-container>


          <ng-container *ngIf="praxis.openingTimes.tuesday">
            <div class="col-4">
              <span class="font-weight-bold text-uppercase">Dienstag:</span>
            </div>

            <div class="col-6">
              <span class="col-6"> {{praxis.openingTimes.tuesday}}</span>
            </div>
          </ng-container>


          <ng-container *ngIf="praxis.openingTimes.wednesday">
            <div class="col-4">
              <span class="font-weight-bold text-uppercase">Mittwoch:</span>
            </div>

            <div class="col-6">
              <span class="col-6"> {{praxis.openingTimes.wednesday}}</span>
            </div>
          </ng-container>


          <ng-container *ngIf="praxis.openingTimes.thursday">
            <div class="col-4">
              <span class="font-weight-bold text-uppercase">Donnerstag:</span>
            </div>

            <div class="col-6">
              <span class="col-6"> {{praxis.openingTimes.thursday}}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="praxis.openingTimes.friday">
            <div class="col-4">
              <span class="font-weight-bold text-uppercase">Freitag:</span>
            </div>

            <div class="col-6">
              <span class="col-6"> {{praxis.openingTimes.friday}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="praxis.openingTimes.saturday">
            <div class="col-4">
              <span class="font-weight-bold text-uppercase">Samstag:</span>
            </div>

            <div class="col-6">
              <span class="col-6">
                {{praxis.openingTimes.saturday}}
              </span>
            </div>

            <ng-container  *ngIf="praxis.type === appService.PraxisSelection.Waldstadt">
              <div class="col-12 d-flex ml-1">
                <span>(Samstags nur Ergotherapie)</span>
              </div>
            </ng-container>


          </ng-container>

          <ng-container *ngIf="praxis.type === appService.PraxisSelection.Kurasan2">
            <br>
            <p>
              <strong>Parkplätze</strong> sind in der Tiefgarage verfügbar. Bitte zum Rolltor vorfahren und bei "Kurasan" klingeln.
            </p>
          </ng-container>
          <ng-container *ngIf="praxis.type === appService.PraxisSelection.Kurasan1">
            <br>
            <p>
              <strong>Parkplätze</strong> sind in der Tiefgarage bei der <a [routerLink]="praxis.urlPrefix + '/kontakt'">Praxis an der Karlstraße 49a</a> verfügbar. Bitte zum Rolltor vorfahren und bei "Kurasan" klingeln.
            </p>
          </ng-container>

        </div>
      </div>

      <div style="max-width: 350px">
        <div id="adresse">
          <h4 class="mb-1 font-weight-bold">
            Kurasan
          </h4>
          <p class="mb-2">
            Am besten erreichen Sie uns telefonisch zwischen 8.30 Uhr und 12.30 Uhr. Während der übrigen Zeiten kann es vorkommen, dass wir Ihren Anruf nicht persönlich entgegennehmen können. Sprechen Sie uns in diesem Fall gerne auf den Anrufbeantworter, wir rufen Sie baldmöglich zurück.
          </p>
          <p class="mb-1">
            <app-address-line [address]="praxis.address"></app-address-line>
          </p>
          <p class="mb-1">
            <email [email]="praxis.email"></email>
          </p>
          <p class="mb-1">
            <phone [phone]="praxis.phone"></phone>
          </p>
          <p class="mb-1">
            <whatsapp [phone]="praxis.whatsapp" [showWhatsapp]="true"></whatsapp>
          </p>
        </div>
      </div>


    </div>
  </div>


  <div class="container mb-3">
    <div class="d-flex flex-column">

      <h4 class="mb-1 font-weight-bold" *ngIf="praxis.type !== appService.PraxisSelection.Waldstadt && praxis.type !== appService.PraxisSelection.Knielingen">
        Wie Sie uns finden
      </h4>

      <ng-container *ngIf="praxis.type === appService.PraxisSelection.Kurasan1">

        <p>
          Unsere Praxis befindet sich im Ärztehaus am Karlstor.<br>
          Wenn Sie mit der Bahn zu uns kommen, dann fahren Sie bis zur Haltestelle Karlstor. Dann laufen Sie Richtung Innenstadt und überqueren die Kriegsstraße. Direkt das erste Haus an der Ecke Kriegsstraße und Karlstraße ist das Ärztehaus am Karlstor, in dem sich unsere Praxisräume befinden.
          Wenn Sie zu Fuß aus der Innenstadt kommen, dann laufen Sie bis fast zur Kriegsstraße. Im letzten Haus vor der Kriegsstraße auf der rechten Seite der Karlstraße liegt unsere Praxis.
        </p>

        <div class="d-flex justify-content-center">
          <div>
            <img class="card-img-top img-fluid" src="assets/img/contact/kk_1_1.png" alt="Card image cap">
          </div>
        </div>

      </ng-container>


      <ng-container *ngIf="praxis.type === appService.PraxisSelection.Kurasan2">

        <p>
          Unsere Praxis befindet sich zwischen dem Küchenstudio Miele und der Gartenschule, direkt gegenüber von der Haltestelle Karlstor.<br><br>
          Der Eingang ist etwas nach hinten versetzt neben einer Tiefgarageneinfahrt.
        </p>

        <div>
          <img class="card-img-top img-fluid" src="assets/img/contact/kk_2_1.png" alt="Card image cap">
        </div>
        <div class="mt-4">
          <img class="card-img-top img-fluid" src="assets/img/contact/kk_2_2.png" alt="Card image cap">
        </div>

      </ng-container>


    </div>
  </div>

</ng-container>
