import {Component, Input, OnInit} from '@angular/core';
import {Phone} from '../../services/app.service';

@Component({
  selector: 'app-fax, fax',
  templateUrl: './fax.component.html',
  styleUrls: ['./fax.component.scss']
})
export class FaxComponent implements OnInit {

  @Input()
  public fax: Phone;

  @Input()
  public showPhoneNumber = true;

  @Input()
  public bigSymbols = false;

  constructor() { }

  ngOnInit(): void {
  }

}
