import { Component, OnInit } from '@angular/core';
import {AppService} from '../../../services/app.service';

@Component({
  selector: 'app-betriebliche-gesundheit',
  templateUrl: './betriebliche-gesundheit.component.html',
  styleUrls: ['./betriebliche-gesundheit.component.scss']
})
export class BetrieblicheGesundheitComponent implements OnInit {

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.appService.scrollUp();
  }

}
