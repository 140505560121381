import {Injectable} from '@angular/core';
import {PraxisSelection, sortByPraxis} from './app.service';

export class Wwo {

  public text: string;
  public href?: string;

  public pageIndex: { sortIndex: number; praxis: PraxisSelection }[];
}

const wwoDB: Wwo[] = [

  {
    text: 'Physiotherapie/ Krankengymnastik',
    href: 'physiotherapie',
    pageIndex: [
      {
        sortIndex: 10,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 10,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 10,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 10,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },
  {
    text: 'Krankengymnastik nach Bobath',
    pageIndex: [
      {
        sortIndex: 15,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Manuelle Therapie',
    href: 'manuelle-therapie',
    pageIndex: [
      {
        sortIndex: 20,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 20,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 20,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 20,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Manuelle Lymphdrainage',
    href: 'manuelle-lymphdrainage',
    pageIndex: [
      {
        sortIndex: 30,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 30,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 30,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 30,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Taping',
    href: 'taping',
    pageIndex: [
      {
        sortIndex: 40,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 40,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 40,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 40,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },


  {
    text: 'Triggerpunktbehandlung',
    pageIndex: [
      {
        sortIndex: 50,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 50,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 50,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 50,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Wärmeanwendungen und Naturfango',
    href: 'physikalische-therapie',
    pageIndex: [
      {
        sortIndex: 60,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 60,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 60,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 60,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Kältetherapie',
    href: 'physikalische-therapie',
    pageIndex: [
      {
        sortIndex: 70,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 70,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 70,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 70,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Elektro- und Ultraschalltherapie',
    href: 'physikalische-therapie',
    pageIndex: [
      {
        sortIndex: 80,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 80,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 80,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 80,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Gerätegestützte Krankengymnastik',
    href: 'kgg',
    pageIndex: [
      {
        sortIndex: 90,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 90,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 90,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 90,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Sportphysiotherapie',
    pageIndex: [
      {
        sortIndex: 100,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 100,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 100,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 100,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },

  {
    text: 'Kiefergelenksbehandlung (CMD)',
    href: 'cmd',
    pageIndex: [
      {
        sortIndex: 110,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 110,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 110,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 110,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },


  {
    text: 'Traktionsbehandlung',
    pageIndex: [
      {
        sortIndex: 120,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 120,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 120,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 120,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },


  {
    text: 'Personal Training',
    pageIndex: [
      {
        sortIndex: 130,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 130,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 130,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 130,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },


  {
    text: 'Gesundheitskurse',
    pageIndex: [
      {
        sortIndex: 140,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 140,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 140,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 140,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },


  {
    text: 'Massage',
    pageIndex: [
      {
        sortIndex: 150,
        praxis: PraxisSelection.Kurasan1
      },
      {
        sortIndex: 150,
        praxis: PraxisSelection.Kurasan2
      },
      {
        sortIndex: 150,
        praxis: PraxisSelection.Waldstadt
      },
      {
        sortIndex: 150,
        praxis: PraxisSelection.Knielingen
      },
    ]
  },




  // {
  //   text: 'Logopädie',
  //   href: 'logopaedie',
  //   pageIndex: [
  //     {
  //       sortIndex: 15,
  //       praxis: PraxisSelection.Waldstadt
  //     },
  //   ]
  // },


  {
    text: 'Ergotherapie',
    href: 'ergotherapie',
    pageIndex: [
      {
        sortIndex: 16,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },

  {
    text: 'Vojta-Therapie',
    pageIndex: [
      {
        sortIndex: 160,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },

  {
    text: 'Kinderphysiotherapie',
    pageIndex: [
      {
        sortIndex: 170,
        praxis: PraxisSelection.Waldstadt
      },
    ]
  },


];

@Injectable({
  providedIn: 'root'
})
export class WhatWeOfferService {

  constructor() {
  }


  public loadNavEntries(selection: PraxisSelection): Wwo[] {
    return wwoDB
      .filter(x => x.pageIndex.find(c => c.praxis === selection) != null)
      .sort((x, y) => {
        return sortByPraxis(x, y, selection);
      });
  }
}
