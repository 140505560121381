import {Component, OnInit} from '@angular/core';
import {AppService} from '../../../services/app.service';

@Component({
  selector: 'app-start',
  templateUrl: './mitgliedschaft.component.html'
})
export class MitgliedschaftComponent implements OnInit {

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.scrollUp();
  }

}
