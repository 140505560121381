<div class="blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">
    <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
      Gesundheitskurse
    </h2>
    <div class="row">
      <div class="col-md-6">
        <p class="text-grey mb-3">Sie möchten Ihrem Körper etwas Gutes tun, indem Sie Ihre Muskulatur stärken, Ihre
          Ausdauer verbessern und
          Ihre Beweglichkeit fördern? Dann beginnen Sie schon heute sich eigenverantwortliche für die Zukunft zu rüsten!
        </p>
      </div>
      <div class="col-md-6 inline left">
        <p class="text-grey mb-3">
          Wir lassen Sie dabei nicht alleine und bieten vielfältige Gesundheits- und Fitnesskurse an.
        </p>
        <p class="text-grey mb-3">
          Über aktuelle Kurse und Termine informieren wir Sie momentan noch gerne vor Ort oder telefonisch!
        </p>
      </div>
    </div>

  </div>
</div>





