import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-instagram, instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {

  @Input()
  public instagram: string;

  @Input()
  public bigSymbols = false;

  constructor() { }

  ngOnInit(): void {
  }

}
