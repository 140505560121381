<div *ngIf="appService.praxisSettings|async as praxis">
  <div class=" blaue_box" *ngIf="false">
    <div class="container pt-4">
      <div class="alert alert-info">
        <h3>
          <span class="fas fa-info px-1"></span>
          <strong>RÜCKENKURS</strong>
        </h3>
        <p>Durch fehlende Bewegung und die damit verbundene Inaktivität der Muskeln können schnell Rückenschmerzen
          entstehen.<br>Viele dieser Bewegungen laufen unbewusst ab und schaden unserer Haltung. Die Konsequenz -
          Rückenschmerzen.</p>
        <p>Um diesen präventiv entgegenzuwirken haben wir uns dazu entschlossen euch einen Rückfit-Kurs anzubieten. Der
          Kurs wird insgesamt zehn Mal stattfinden und von Sandra und Leo geleitet. Er ist für Männer, Frauen, Anfänger
          und Fortgeschrittene gleichermaßen geeignet.</p>
        <div>
          <table class="table table-sm">
            <tbody>
            <tr>
              <td class="border-0">
                <strong>Wann?</strong>
              </td>
              <td class="border-0">
                <span>Ab dem 19.09.2022 immer montags von 16:00 Uhr bis 17:00 Uhr</span>
              </td>
            </tr>
            <tr>
              <td class="border-0"><strong>Wo?</strong></td>
              <td class="border-0">
                <span>Turnhalle der Gartenschule, Gartenstraße 22, 76133 Karlsruhe</span>
              </td>
            </tr>
            <tr>
              <td class="border-0"><strong>Kosten?</strong></td>
              <td class="border-0">
                <span>120 € - in der Regel übernimmt die Krankenkasse 80% dieser Kosten</span>
              </td>
            </tr>
            <tr>
              <td class="border-0"><strong>Ihr braucht?</strong></td>
              <td class="border-0">
                <span>Sportkleidung, Hallenschuhe, großes Handtuch, gerne Gymnastikmatte</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p>Ihr habt Interesse? Meldet euch mit einem Klick <a target="_blank"
                                                              href="https://forms.office.com/r/Qphqcf94zg">hier</a> an
          oder sprecht uns gern direkt an :)</p>
        <div class="d-flex flex-wrap">
          <a href="https://forms.office.com/r/Qphqcf94zg" target="_blank" class="mx-2">
            <span class="fas fa-concierge-bell px-1"></span>
            <span>Hier Anmelden</span>
          </a>
          <phone [phone]="praxis.phone" class="mx-2"></phone>
          <fax [fax]="praxis.fax" class="mx-2"></fax>
          <email [email]="praxis.email" class="mx-2"></email>
          <a href="assets/download/Rückenkurs_052022.pdf" target="_blank" class="mx-2">
            <span class="fas fa-file-pdf px-1"></span>
            <span>Flyer herunterladen</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="startPageheader kurasan1-img" *ngIf="praxis.type === appService.PraxisSelection.Kurasan1">
    <img src="assets/img/praxis-selection/kurasan_1_welcome.jpg">
  </div>

  <div class="startPageheader kurasan2-img" *ngIf="praxis.type === appService.PraxisSelection.Kurasan2">
    <img src="assets/img/praxis-selection/kurasan_2_welcome.jpg">
  </div>

  <div class="startPageheader kurasan2-img" *ngIf="praxis.type === appService.PraxisSelection.Waldstadt">
    <img src="assets/img/praxis-selection/waldstadt_welcome.jpg">
  </div>

  <div class="startPageheader kurasan2-img" *ngIf="praxis.type === appService.PraxisSelection.Knielingen">
    <img src="assets/img/praxis-selection/knielingen_welcome.jpg">
  </div>

  <div class="bg-inverse
   blaue_box
   text-white p-3 p-lg-4 text-center divider-arrow divider-arrow-t divider-inverse"
       id="willkommen">
    <div class="container text_white pos-relative">

      <app-speech-event-bubble class="d-none d-md-block" style="bottom: 10px; right: 10px; "></app-speech-event-bubble>

      <hr class="hr-lg mt-0 mb-2 w-10 mx-auto hr-primary"/>
      <h2 class="text-center text-uppercase font-weight-bold my-0 ueberschrift_weiss">
        Herzlich Willkommen
      </h2>
      <h4 class="ueberschrift_weiss" *ngIf="praxis.type === appService.PraxisSelection.Waldstadt">auf der Homepage der
        Praxis für Physiotherapie und Ergotherapie Kurasan-Waldstadt</h4>
      <p>Wir sind eine Praxis für Physio- und Manuelle Therapie, sowie für Bewegungstherapie und
        Gesundheitsmanagement.<br>
        Unser breitgefächertes Therapiespektrum beinhaltet unter anderem Krankengymnastik, Lymphdrainage und
        medizinische
        Trainingstherapie.
        <br>Außerdem bieten wir Ihnen diverse Gesundheits- und Fitnesskurse auch aus dem Präventionsbereich.<br>
        In Kürze können Sie sich auf dieser Seite ausführlich über unsere Therapiemöglichkeiten und Kurse informieren.
        <ng-container *ngIf="praxis.type === appService.PraxisSelection.Kurasan1">
          <br><br><span>Unsre Praxisräume sind klimatisiert und barrierefrei erreichbar.</span><br>
        </ng-container>
        <br>Das Kurasan-Team freut sich auf Sie!

      <div class="d-flex flex-column">
        <span>Termine nach Vereinbarung:</span>
        <phone [phone]="praxis.phone"></phone>
        <fax [fax]="praxis.fax"></fax>
        <email [email]="praxis.email"></email>
        <whatsapp [phone]="praxis.whatsapp" [showWhatsapp]="true"></whatsapp>
      </div>

      <br><br>
      <a *ngIf="praxis.type === appService.PraxisSelection.Kurasan2" [routerLink]="praxis.urlPrefix + '/kontakt'"
         class="clickable link-effect-underline">
        <strong>Sie finden unsere Praxis inklusive Parkmöglichkeiten hier!</strong>
      </a>


    </div>

    <div class="container text_white pos-relative" *ngIf="praxis.youtubeStart as ylink">

      <app-youtube-wrapper [yLink]="ylink">
        <p>Wir würden Ihnen hier gern unsere Praxis per Videoclip vorstellen. Dieses Video ist allerdings ein externer
          Inhalt auf youtube. Sie entscheiden, ob Sie dieses Video auch sehen wollen.</p>
      </app-youtube-wrapper>

    </div>
  </div>

  <div style="background: white; position: relative">
    <div class="container content" id="bieten">

      <div class="title-divider pt-4">
        <h4 class="display-5 text-slab text-uppercase mt-0 mb-5">
          <span class="font-weight-bold text-muted">Was wir bieten</span>
        </h4>
      </div>

      <div class="row" *ngIf="wwoParts|async as wwoList">
        <div class="col-md-6">
          <ul class="fa-ul list-lg m-0">
            <li class="d-flex align-items-center" *ngFor="let item of wwoList.slice(0, wwoList.length / 2) ">
              <i class="mr-2 fas fa-check text-primary"></i>
              <ng-container *ngIf="item.href">
                <h4 class="m-0 start-letter clickable" style="font-variant: none"
                    [routerLink]="praxis.urlPrefix + '/' + item.href">
                  {{item.text}}
                </h4>
              </ng-container>
              <ng-container *ngIf="!item.href">
                <h4 class="m-0 start-letter clickable" style="font-variant: none">
                  {{item.text}}
                </h4>
              </ng-container>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="fa-ul list-lg m-0">
            <li class="d-flex align-items-center"
                *ngFor="let item of wwoList.slice(wwoList.length / 2, wwoList.length) ">
              <i class="mr-2 fas fa-check text-primary"></i>
              <ng-container *ngIf="item.href">
                <h4 class="m-0 start-letter clickable" style="font-variant: none"
                    [routerLink]="praxis.urlPrefix + '/' + item.href">
                  {{item.text}}
                </h4>
              </ng-container>
              <ng-container *ngIf="!item.href">
                <h4 class="m-0 start-letter clickable" style="font-variant: none">
                  {{item.text}}
                </h4>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>

  <div class="container content mb-5" id="suchen">

    <div class="title-divider mt-4">
      <h4 class="display-5 text-slab text-uppercase mt-0 mb-5">
        <span class="font-weight-bold text-muted">Wen wir suchen</span>
      </h4>
    </div>

    <div>
      Wir sind eine Praxis für Physio- und Manuelle Therapie, sowie für Bewegungstherapie und
      Gesundheitsmanagement. Unsere Praxis in der Waldstadt haben wir dieses Jahr im Juli neu eröffnet.
    </div>
    <ul class="fa-ul list-lg m-0 my-2">
      <li class="d-flex justify-content-between">

        <div class="d-flex align-items-center">
          <i class="mr-2 fas fa-user text-primary"></i>
          <h4 class="p-0 m-0"><strong>
            <a [routerLink]="praxis.urlPrefix + '/stellenausschreibung'">Physiotherapeut:innen für Hausbesuche</a>
          </strong></h4>
        </div>

        <div class="d-flex align-items-center ml-3">
          <a href="assets/download/Stellenanzeige Kurasan Hausbesuche.pdf" target="_blank"><i
            class="mr-2 fas fa-file-pdf text-primary"></i>Flyer herunterladen</a>
        </div>
      </li>
    </ul>

    <p class="">
      Wenn wir Ihr Interesse wecken konnten, oder Sie jemanden kennen,
      melden Sie sich gern per Telefon unter
      <phone [phone]="defaultPraxis.phone"></phone>
      oder per Mail an
      <email [email]="defaultPraxis.email"></email>
      .
    </p>

  </div>

  <ng-container
    *ngIf="praxis.type === appService.PraxisSelection.Kurasan1 || praxis.type === appService.PraxisSelection.Kurasan2">
    <div class="bg-inverse blaue_box">
      <div class="container">
        <div class="row">
          <div class="col-lg-5  divider-diagonal divider-diagonal-r divider-inverse {{getPrinzipielBG()}}"
               style=";background-size: contain; background-repeat: no-repeat"></div>
          <div class="col-lg-4 py-5">
            <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary"/>
            <h2 class="display-4 text-slab text-white text-uppercase mt-0 mb-5">
              Unsere <span class="text-primary font-weight-bold">Prinzipien</span>
            </h2>
            <div class="row mb-3">
              <div class="col-2 col-md-1 text-center"><i class="fas fa-check text-primary icon-2x"></i>
              </div>
              <div class="col-10 col-md-11 text-grey">
                <h4 class="font-weight-bold text-white text-uppercase">
                  Einfühlungsvermögen
                </h4>
                <p>Wir sehen den Menschen als Ganzes. Es ist uns wichtig, Sie als Patient und nicht nur als eine
                  Diagnose zu sehen.
                  Dabei gehen wir auf alle Lebensbereiche ein. Wir geben unser Bestes Ihre Beschwerden zu lindern und
                  auch in Zukunft Ihre Gesundheit zu sichern.
                  .</p>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-2 col-md-1 text-center"><i class="fas fa-check text-primary icon-2x"></i>
              </div>
              <div class="col-10 col-md-11 text-grey">
                <h4 class="font-weight-bold text-white text-uppercase">
                  Teamgefühl
                </h4>
                <p>Wir halten zusammen und tauschen uns immer aus. So sind aus Kollegen auch Freunde geworden und diese
                  Atmosphäre kommt auch bei unseren Patienten an..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
