<div
  class=" blaue_box bg-inverse text-white p-3 p-lg-4 divider-arrow divider-arrow-t divider-inverse blau_box clearfix">
  <div class="container content">
    <div class="row">

      <div class="col-md-12 inline d-flex flex-column justify-content-center">

        <hr class="hr-lg mt-0 mb-2 w-10 ml-0 hr-primary">

        <h4 class="font-weight-bold text-white text-uppercase mb-3">
          Was ist Ergotherapie?
        </h4>
        <p class="text-grey mb-5">

          Die Ergotherapie ist eine Therapieform, die sich mit der Ausführung konkreter Betätigungen und deren Auswirkungen auf den Menschen und dessen Umwelt befasst.<br>
          Ergotherapeuten beraten, behandeln, fördern, unterstützen und begleiten Menschen jeden Alters, die durch eine physische (körperliche) oder psychische Erkrankung, durch eine Behinderung oder durch eine Entwicklungsstörung in ihrer Selbständigkeit, in ihrer Handlungsfähigkeit eingeschränkt, beeinträchtigt oder von Einschränkung bedroht sind.
        </p>

      </div>


      <div class="col-6 inline left pb-4">

        <img class="card-img-top img-fluid" src="assets/img/thea/ergotherapie_1.png" alt="Card image cap">

      </div>
      <div class="col-6 inline left">
        <h4 class="font-weight-bold text-white text-uppercase mb-3">
          Was bewirkt Ergotherapie?
        </h4>
        <p class="text-grey mb-3">

          Ziel der ergotherapeutischen Behandlung ist, den Menschen bei der Durchführung für ihn bedeutungsvoller Betätigungen in den Bereichen Selbstversorgung, Produktivität und Freitzeit in seiner persönlichen Umwelt zu stärken. Durch "Dabei soll die individuelle Handlungsfähigkeit im motorischen, kognitiven, psychischen/emotionalen und sozialen Bereich wiedererlangt werden.<br>
          Nachdem der behandelnde Arzt ein Rezept ausgestellt hat, wird gemeinsam mit dem Patienten ein individueller Behandlungsplan erstellt.<br>
          <br>
          Dieser wird mit Hilfe handwerklicher und spielerischer Tätigkeiten, spezieller motorischer/ kognitiver Übungsprogramme oder dem Training alltagsrelevanter Tätigkeiten umgesetzt.

        </p>
      </div>

      <div class="col-12 col-md-6 inline">

        <div class="pt-2">

          <strong>Ergotherapie für Kinder (Pädiatrie)</strong>

          <ul>
            <li>
              <span>Störungen der Motorik (Fein – und Grobmotorik)</span>
            </li>
            <li>
              <span>Förderung der Lateralitätsentwicklung</span>
            </li>
            <li>
              <span>Entwicklungsrückstände</span>
            </li>
            <li>
              <span>Störungen der sensorischen Integration</span>
            </li>
            <li>
              <span>Störungen der visuellen und auditiven Wahrnehmungsfähigkeiten</span>
            </li>
            <li>
              <span>Verhaltensauffälligkeiten und -störungen</span>
            </li>
            <li>
              <span>ADS/ ADHS</span>
            </li>
            <li>
              <span>Lern- / Konzentrationsschwierigkeiten</span>
            </li>
          </ul>

        </div>
      </div>
      <div class="col-12 col-md-6 inline">

        <div class="pt-2">

          <strong>Ergotherapie für Erwachsene</strong>

          <ul>
            <li>
              <span>in den Bereichen der Orthopädie, Neurologie, Geriatrie, Hirnleistungstraining (HLT)</span>
            </li>
            <li>
              <span>Bei ergotherapeutischem Bedarf im psychiatrischen Bereich wenden Sie sich bitte an unsere <a href="www.praxis-seewald.de" target="_blank">Kooperationpraxis Seewald.</a> Diese befindet sich ebenfalls bei uns im Haus. </span>
            </li>
          </ul>

        </div>

      </div>

    </div>

  </div>
</div>


