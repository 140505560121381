<div class="row welcome-text-row no-gutters ">
  <div class="container">
    <div style="color: white" class="text-center">
      <h1>Ihre Physiotherapie-Praxis in Karlsruhe</h1>
      <p>Engagiert, dynamisch und kompetent – unser Team freut sich, Ihnen helfen zu können!
        Wir bieten ein breites Spektrum an Therapiemöglichkeiten an. Neben Krankengymnastik, Manueller Therapie und Lymphdrainage begleiten wir Sie auch mit Gesundheits- und Fitnesskursen.
      </p>
      <!--      <p class="p-margin">So behandeln wir nicht nur Ihre aktuellen Beschwerden, sondern beugen gesundheitlichen Problemen vor.</p>-->
      <p class="p-margin">Schauen Sie sich auf unserer Seite um und entdecken Sie, wie wir zusammen Ihrem Körper etwas Gutes tun können. </p>
      <p class="p-margin">Das Kurasan-Team freut sich auf Sie!</p>
    </div>
  </div>
</div>
<div class="row justify-content-center card-row no-gutters">
  <ng-container *ngFor="let item of praxisMap|keyvalue|praxisSelection">
    <div class="col-12 col-lg-6 p-0 p-md-4">
      <div class="d-flex flex-column align-items-center card-container">
        <div class="triangle"></div>
        <a [href]="(item.domain == null ? '' : item.domain) + item.urlPrefix + '/start'">
          <div class="card mb-3 cursor-pointer">
            <img class="card-img-top img-fluid" [src]="(item.domain == null ? '' : (item.domain + '/')) + item.imgUrl" alt="Team Bild">
            <div class="card-body">
              <h4 class="card-title text-center">
                {{item.title}}
              </h4>
              <div class="text-center">
                <a [href]="(item.domain == null ? '' : item.domain) + item.urlPrefix + '/kontakt'">
                  <address-line [address]="item.address" ></address-line>
                </a>
              </div>
              <div class="text-center">
                <phone [phone]="item.phone"></phone>
                <br>
                <email [email]="item.email"></email>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </ng-container>
</div>
